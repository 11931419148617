import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import userActions from '../../context/users/actions';
import { ToastContainer, notify } from '../../libraries/notifications';

import { history } from '../../routes';
import config from '../../config';
import LayoutWithSidebar from '../../components/layout/LayoutWithSidebar';
import UserProfileImage from '../../components/commons/UserProfileImage';
import BottomNav from '../../components/commons/BottomNav';
import transactionsAction from '../../context/transactions/actions';
import productsAction from '../../context/products/actions';
import levelsAction from '../../context/levels/actions';
import companiesActions from '../../context/companies/actions';
import Loader from '../../components/commons/Loader';
import medal from '../../assets/img/medal.png';
import { capitalize, capitalizePhrase } from '../../libraries/utils';
import benefitsActions from '../../context/benefits/actions';
import settingsActions from '../../context/settings/actions';
import LayoutResponsive from '../../components/layout/LayoutResponsive';

class UserRanking extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      activities : [],
      userId: this.props.auth.user.id,
      areNewPrizes: false,
      prizes: [],
      showing: {
        show_prizes: false,
        show_actions: false,
        show_offers: false,
        show_benefits: false,
      },
    }
  }

  async componentDidMount() {
    console.log('RANKING PAGE', this.props.auth.user);
    const params = this.props.match.params;
    // if (params.id){
    //   this.getAllData(params.id);
    // } else {
    //   this.getAllData();
    // }
    let userId = this.props.auth.user.id
    let isPublicProfile = false
    if (params.id) {
      userId = params.id
      isPublicProfile = true
    }

    let fromPath = null
    if(this.props.location?.state?.fromPath){
      fromPath = this.props.location.state.fromPath
    }

    this.setState({ userId, isPublicProfile, fromPath }, () => this.getAllData())
    
    if(!this.props.auth.user.ancestors?.company?.name){
      this.getCompany()
    }

    await this.getPrizes()
  }


  getCompany = async () => {
    await this.props.onGetCompanies({owner: this.props.auth.user.unique_id});
    const { companies } = this.props
     if (companies.error) {
      notify(this.t(companies.error))
    } else {
      if(companies.items.length){
        this.setState({company_name: companies.items[0]?.name})
      }
    }
  }

  getAllData = async () => {
    const { userId: id } = this.state
    this.setState({dataLoading: true})
    
    let promises = [];
    console.log('PROFILE PAGE ID', id);
    promises.push(this.getUser(id))
    promises.push(this.getRanking(id))
    //promises.push(this.getLevels())
    //promises.push(this.getRewards(id))
    //promises.push(this.getActivities(id))
    //promises.push(this.getOffers(id))
    promises.push(this.getSettings(id))

    let d = await Promise.all(promises)
    let state = {dataLoading: false}
    d.forEach(x => {
      if(d.error){
        notify(this.t(x.error.message));
      } else {
        state = {...state, ...x}
      }
    })
    if(state.rewards?.total?.points === "0"){
      state.user_ranking = "N/A"
    }
    this.setState(state)
  }

  getOffers = async () => {
    const { userId: id } = this.state

    await this.props.onGetProducts({ owner: id, type: [config.TYPES.OFFERS.PRODUCTS, config.TYPES.OFFERS.SERVICES, config.TYPES.OFFERS.FAVORS]});
    const { products } = this.props;
    if (products.error) {
      notify(this.t(products.error.message));
    } else {
      return {offers: products.items.filter(p => p.status !== "disabled")}
    }
  }

  getUser = async () => {
    const { userId: id } = this.state
    await this.props.onGetUser({id});
    const { user } = this.props
     if (user.error) {
      return {error: this.t(user.error.message)}
    } else {
      const rate_total = user.item.json_data.rate_total
      const rate_count = user.item.json_data.rate_count
      const rating = rate_total && (rate_count !== 0) ? rate_total/rate_count : 0

      return {user: user.item, rating}
    }
  }

  getRanking = async () => {
    const { userId: id } = this.state

    await this.props.onGetUsersRanking({ user_type: config.USER_TYPE.REGULAR, unique_id: this.props.auth.user.unique_id });
    const { ranking } = this.props
    if(ranking?.error){
      return {error: this.t("Error obteniendo el ranking")}
    } else {
      let user_ranking = "N/A"
      console.log("RANKING=============",ranking)
      if(ranking){
        ranking.forEach((r, index) => {
          if(r.id === id){
            user_ranking = index+1;
            ranking[index].name = ranking[index].name + ' (Vos)'
            this.setState({ ranking })
          }
        })
      }
      return {user_ranking}
    }
  }
  
  getRewards = async () => {
    const { userId: id } = this.state

    await this.props.onGetRewards({id});
    const { rewards } = this.props
    if (!rewards.total) {
      return {error: this.t("Error obteniendo las recompensas")};
    } else {
      // let user_ranking = "N/A"
      // if(rewards.total.points === "0"){
      //   user_ranking = "N/A"
      // }
      console.log("REWARDS========", rewards.level)
      return {rewards, userLevel: rewards.level}
    }
  }

  getActivities = async () => {
    // if(!id){
    //   id = this.props.auth.user.id
    // }
    const { userId: id } = this.state

    const ownerParams = {
      type: [
        config.TRANSACTIONS.INITIATIVE.TYPE,
        config.TRANSACTIONS.ACTION.TYPE,
      ],
      status: [
        config.TRANSACTIONS.INITIATIVE.STATUS.ATTENDED,
        config.TRANSACTIONS.ACTION.STATUS.COMPLETED
      ],
      created_by: id
    }

    await this.props.onGetOwnerActivities(ownerParams);
    const { activities } = this.props;
    let ownerActivities = [ ...activities.items ]

    if (activities.error) { 
      return {error: this.t(activities.error.message)}
    } else {
      const sourceParams = {
        type: config.TRANSACTIONS.BORROW.TYPE,
        status: [
          config.TRANSACTIONS.BORROW.STATUS.ACCEPTED,
          config.TRANSACTIONS.BORROW.STATUS.ON_USER,
          config.TRANSACTIONS.BORROW.STATUS.ON_OWNER,
          config.TRANSACTIONS.BORROW.STATUS.REVIEW,
          config.TRANSACTIONS.BORROW.STATUS.COMPLETED,
        ],
        where: { custom: { source: id, comparison: 'OR',  created_by: id } },
      }
      await this.props.onGetSourceActivities(sourceParams);
      const { activities } = this.props;
      if (activities.error) { 
        return {error: this.t(activities.error.message)}
      } else {
        let sourceActivities = [ ...activities.items ]
        let opinionsReceived = sourceActivities.filter(a => a.status.includes([config.TRANSACTIONS.BORROW.STATUS.COMPLETED]) && a.source === id)
        let opinionsGiven = sourceActivities.filter(a => a.status.includes([config.TRANSACTIONS.BORROW.STATUS.COMPLETED]) && a.created_by === id)

        let totalActivities = ownerActivities.concat(sourceActivities.filter(a => a.source === id))
        console.log("TOTAL ACTIVITIES", totalActivities)

        return {activities: totalActivities, opinionsGiven, opinionsReceived}
      }
  }}


  sendMessage = (user) => {
    history.push({
      pathname: config.ROUTES.SEND_MESSAGE,
      state: { fromPath: config.ROUTES.USER.PUBLIC_PROFILE.replace(':id', user.id), user }
    });
  } 

  getPointsLeftForNextLevel = () => {
    if(this.state.userLevel === config.MAX_LEVEL) return null
    console.log(this.state.userLevel);
    return this.state.levels[(this.state.userLevel+1).toString()].min - this.props.auth.user.rewards.total.points
  }
 
  getLevels = async () => {
    await this.props.onGetLevels();
    const { levels } = this.props;
    if (levels.error) {
      notify(this.t(levels.error.message));
    } else {
      console.log("LEVELS", levels.item)
      this.setState({ levels: levels.item })
    }  
  }

  goProfileRating = (opinions, type, isPublicProfile) => {
    const { userId } = this.state
    history.push({ 
      pathname: config.ROUTES.USER.PROFILE_RATINGS,
      state: { opinions, type, isPublicProfile, userId }
    })
  }

  goBack = () => {
    let path = this.state.fromPath ? this.state.fromPath : config.ROUTES.HOME
    history.push(path)
  }


  getPrizes = async () => {
    const params = {enabled: 1, type: ["prize"], owner: this.props.auth.user.unique_id} // Revisar
    await this.props.onGetAllBenefits(params);
    const { benefits } = this.props;
    if (benefits.error) {
      notify(this.t(benefits.error.message));
    } else {
      let prizes = benefits.items
      console.log("prizes_by_pack", )
      this.setState({ prizes })
      this.thereAreNewPrizes()
    }
  }

  thereAreNewPrizes = async () => {
    const {prizes}  = this.state
    const lastSeenPrize = localStorage.getItem("lastSeenPrize")

    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
    const areNewPrize = prizes.some(p => (new Date(p.created_at) > new Date(oneWeekAgo)) && (new Date(p.created_at) > new Date(lastSeenPrize)))
    this.setState({areNewPrizes: areNewPrize})

  }

  getSettings = async () => {
    const owner = this.props.auth.user.unique_id
    await this.props.onGetAllSettings({ owner });
    
    const { settings } = this.props;
    if (settings.error) notify(this.t(settings.error.message));
    else {
      const newShowing = this.state.showing;
      this.setState({ settings: settings.items });
      settings.items.forEach((item) => {
        const value = item.value === true || item.value === "true";
        if (item.type === "sharyco_settings") newShowing[item.code] = value;
      });
      this.setState({ showing: newShowing });
    }
  }

  render() {
    const { user, ranking, areNewPrizes } = this.state;
    // const company_name = this.state.company_name ? this.state.company_name : this.props.auth.user.ancestors?.company?.name

    const top3 = ranking?.slice(0,3);
    const rest_ranking = ranking?.slice(3);

    return (
      <LayoutResponsive
        main={{ className: "pb-[48px]"}}
        header={{ 
          logo: true,
          //content: <NavButtonBar options={buttonBarUpper} active={topNav} onClick={this.onNavBarClick} />
        }}
 
        container={{ className: "px-0"}}
      >
        <ToastContainer/>
        {/* {!user && <div className='h-48 grid place-content-center'><Loader spinnerClassName='h-16 w-16 text-primary' /></div>} */}
        <div className='px-3 border-t border-gray-100 flex mb-5'>
          <div className={"px-4 py-3 text-center border-b-4 border-transparent font-medium flex-1 rounded-none " + (true ? " border-b-secondary font-semibold text-base-content " : " text-neutral ") + ""}
            onClick={() => {}}
          >
            <h5 className='whitespace-nowrap'>{capitalize(this.t("ranking"))}</h5>
          </div>
          { this.state.showing.show_prizes &&
            <div className={"px-4 py-3 text-center border-b-4 border-transparent font-medium flex-1 rounded-none " + (false ? " border-b-secondary font-semibold text-base-content " : " text-neutral ") + ""}
              onClick={() => {history.push(config.ROUTES.MAIN.PRIZES)}}>
              <h5 className='whitespace-nowrap flex justify-center gap-1 '>
                {capitalize(this.t("premios"))}
                {areNewPrizes && <div  className="h-3 w-3 bg-error rounded-full" />}
              </h5>
            </div>
          }
        </div>
        <section className="mb-14 lg:mb-2">
          <div className="bg-gradient-to-b from-[#faedcb] to-white px-4 rounded-lg">
            <div className="py-5 grid grid-cols-3 gap-4 items-end justify-between">
                {top3 ?
                <>
                   {top3[0]&& <div className="card w-full h-full bg-primary-focus text-center z-20 flex gap-3 flex-col items-center relative p-2 cursor-pointer">
                        <img src={medal} className='w-12 -mt-2' />
                        <p className='absolute top-[28px] left-0 right-0 text-2xl text-white font-bold'>1</p>
                        <div className='' onClick={()=>history.push({pathname: config.ROUTES.USER.PUBLIC_PROFILE.replace(':id',top3[0].id), state: { fromPath: config.ROUTES.USER.RANKING}})}>
                            <UserProfileImage picture={{className:'h-12 w-12 !mb-1'}} data={top3[0]} />
                            <p className='text-white leading-[14px]'>{capitalizePhrase(top3[0].name)}</p>
                            <p className='text-yellow-400 font-semibold'>{top3[0].points}pts</p>
                        </div>
                    </div>}
                   {top3[1]&& <div className="card w-full mt-5 bg-[#0c6a73d2] text-center z-20 flex flex-col gap-3 items-center relative p-2 cursor-pointer">
                        <img src={medal} className='w-9 -mt-2' />
                        <p className='absolute  top-[18px] left-0 right-0 text-xl text-white font-bold'>2</p>
                        <div className='' onClick={()=>history.push({pathname: config.ROUTES.USER.PUBLIC_PROFILE.replace(':id',top3[1].id), state: { fromPath: config.ROUTES.USER.RANKING}})}>
                            <UserProfileImage picture={{className:'h-10 w-10 !mb-1'}} data={top3[1]} />
                            <p className='text-white leading-[14px]'>{capitalizePhrase(top3[1].name)}</p>
                            <p className='text-yellow-400 font-semibold'>{top3[1].points}pts</p>
                        </div>
                    </div>}
                    {top3[2]&&<div className="card w-full mt-8 bg-[#0c6a73d2] text-center z-20 flex gap-3 flex-col items-center relative p-2 cursor-pointer">
                        <img src={medal} className='w-6 -mt-2' />
                        <p className='absolute top-[12px] left-0 right-0 text-sm text-white font-bold'>3</p>
                        <div className='' onClick={()=>history.push({pathname: config.ROUTES.USER.PUBLIC_PROFILE.replace(':id',top3[2].id), state: { fromPath: config.ROUTES.USER.RANKING}})}>
                            <UserProfileImage picture={{className:'h-8 w-8 !mb-1'}} data={top3[2]} />
                            <p className='text-white leading-[14px]'>{capitalizePhrase(top3[2].name)}</p>
                            <p className='text-yellow-400 font-semibold'>{top3[2].points}pts</p>
                        </div>
                    </div>}
                </> :
                <div className='col-span-3 items-center'>
                  <Loader spinnerClassName='h-16 w-16 text-primary' />
                </div>
                }
                
                
            </div>
            
            
          </div>
          <div className='flex mt-5 flex-col px-4 gap-2'>
              {rest_ranking &&
                  rest_ranking.map((u,i)=>(
                    <div className={'card py-1 bg-white px-4 flex justify-between flex-row cursor-pointer' + (u.id === user?.id ? ' border-2 border-primary' : ' ')}
                      onClick={()=>history.push({pathname: config.ROUTES.USER.PUBLIC_PROFILE.replace(':id',u.id), state: { fromPath: config.ROUTES.USER.RANKING}})}
                    >
                      <div className='flex items-center gap-3'>
                        <p className='text-gray-300'>{i+4}</p>
                        <UserProfileImage picture={{className:'h-5 w-5 !m-0'}} data={u} />
                        <p className='text-primary'>{capitalizePhrase(u.name)}</p>
                      </div>
                      <div className='text-yellow-400 font-semibold text-sm'>{u.points}pts</div>
                    </div>
                ))
              }  
              {/* {user_ranking > 10 &&
                <div className='card bg-white pt-4 border-t border-t-gray-400 px-4 flex justify-between flex-row'>
                    <div className='flex items-center gap-3'
                      onClick={()=>history.push({pathname: config.ROUTES.USER.PUBLIC_PROFILE.replace(':id',user.id), state: { fromPath: config.ROUTES.USER.RANKING}})}
                    >
                      <p className='text-gray-300'>{user_ranking}</p>
                      <UserProfileImage picture={{className:'h-5 w-5 !m-0'}} data={user} />
                      <p className='text-primary'>{capitalizePhrase(user.name)}(You)</p>
                    </div>
                    <div className='text-yellow-400 font-semibold text-sm'>{user.rewards.total.points}pts</div>
                </div>
              } */}
            </div>
        </section>
        {/* <BottomNav/> */}
      </LayoutResponsive>
    ) 
  }
}

const mapStateToProps = state => {
  return {
    user: state.users.current,
    auth: state.users.auth,
    activities: state.transactions.list,
    rewards: state.users.auth.user.rewards,
    ranking: state.users.list.ranking,
    products: state.products.list,
    levels: state.levels.current,
    companies: state.companies.list,
    settings: state.settings.list,
    benefits: state.benefits.list,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onUpdate: (params) => dispatch(userActions.saveOrUpdate(params)),
    onGetUser: (params) => dispatch(userActions.get(params)),
    onGetUsersRanking: (params) => dispatch(userActions.getRanking(params)),
    onGetRewards: (params) => dispatch(userActions.getRewards(params)),
    onLogout: () => dispatch(userActions.logout()),
    onGetOwnerActivities: (ownerParams) => dispatch(transactionsAction.getAll(ownerParams)),
    onGetSourceActivities: (sourceParams) => dispatch(transactionsAction.getAll(sourceParams)),
    onGetProducts: (params) => dispatch(productsAction.getAll(params)),
    onGetLevels: (params) => dispatch(levelsAction.get(params)),
    onGetCompanies: (params) => dispatch(companiesActions.get(params)),
    onGetAllBenefits: (params) => dispatch(benefitsActions.getAll(params)),
    onGetAllSettings: (params) => dispatch(settingsActions.getAll(params)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(UserRanking));