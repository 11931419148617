import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { ToastContainer, notify } from '../../libraries/notifications';
import { history } from '../../routes';
import config from '../../config';
import { isEmptyObject, getObjectWithJsonDataToFormValues, capitalize, validateIsfilled } from '../../libraries/utils';
import { Form, Field } from 'react-final-form';
import TextareaInput from '../../components/forms/TextareaInput';
// import ImageUploadFileInput from '../../components/forms/ImageUploadFileInput';
import ImageUploadFileInputEdit from '../../components/forms/ImageUploadFileInputEdit';

import LayoutSmall from '../../components/layout/LayoutSmall';
import Button from '../../components/commons/Button';
import IconPoints from '../../components/customs/IconPoints';
import IconCoins from '../../components/customs/IconCoins';
import Avatar from '../../components/commons/Avatar';
import filesActions from '../../context/files/actions';
import actionsAction from '../../context/actions/actions';
import actionsUser from '../../context/users/actions';

import transactionsAction from '../../context/transactions/actions';
import Icon from '../../libraries/icons';
import { RepoructorVideo } from '../onboarding/RepoructorVideo';
import CheckboxInput from '../../components/forms/CheckboxInput';
import LayoutResponsiveWSmall from '../../components/layout/LayoutResponsiveWSmall';

class ShareAction extends React.Component { 
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.id=this.props.match.params.id
    this.idFlow=this.props.match.params.idFlow
    this.stateAction= this.props.location.state
    this.isStep = this.props.match.url.includes('step');
    this.state = {
      action:null,
      files: {},
      submitting: false,
      imageUpdated: false,
      user: this.props.auth.user.id,
      isMobile: false,
      videoEnded: false,
      videoPaused: true,
      loadingVideo: true,
      puedeHacerLaAccion:true
    }
  }


  onEnded = () => this.setState((prevState) => ({ videoEnded: true }));
  onReady = () => this.setState((prevState) => ({ loadingVideo: false }));


 async componentDidMount() {
    await this.userToken()
    const params = this.props.match.params.id? this.props.match.params: this.stateAction;
    console.log('ACTION', params);
      if (params?.id) {
        this.isMobile()
        this.getAction(params);
      } else {
        this.goBack();
      }
      const state = this.props.location.state;
      this.setState({
        route: {
          pathname: !isEmptyObject(state) ? state.from : config.ROUTES.HOME,
          state: { from: config.ROUTES.ACTIONS.SHARE }
        }
      });
  }
 async componentDidUpdate(prevProps, prevState){
 
    if(prevState.action !== this.state.action ){
      this.getActionsTransactions()     
    }
   if (prevState.puedeHacerLaAccion !== this.state.puedeHacerLaAccion&&!this.state.puedeHacerLaAccion&& this.isStep) {
    this.redirigirAlSiguienteFlow()
   }
  }
  
  redirigirAlSiguienteFlow=async()=>{
    await this.onUpdateUserTour()
    .then(async()=>{
    await this.userToken()
    const arraysFlows = config.ACTION_BY_DEFAULT
    const array=this.props.auth.user?.tour?.split(',');
    const index=array?.indexOf('0');
    if(!index ||index < 0) return history.push(config.ROUTES.HOME);
    const idFlow= arraysFlows[index]?.id
     history.push(config.ROUTES.FLOW.STEP1.replace(':id', idFlow)); 
    })
   
  }

  isMobile = () => {
    const userAgent = navigator.userAgent
    const mobilePatterns = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i,
    ]
    console.log("userAgent", userAgent)
    const isMobileDevice = mobilePatterns.some((pattern) => pattern.test(userAgent))
    this.setState({ isMobile: config.DEBUG_MODE || isMobileDevice })
  }
  userToken = async () => {
    await this.props.onGetToken();
}

   getActionByFlow =()=>{
    const userData = getObjectWithJsonDataToFormValues(
      this.props.auth.user,
      ['id', 'accounts', 'username', 'first_name', 'last_name', 'profile_image', 'rating']
    ); 

    if(this.stateAction && Object.keys(this.stateAction ).length > 0){
      console.log('Sacando del state');
      console.log({state:this.stateAction})
      const actionData = getObjectWithJsonDataToFormValues(
        this.stateAction,
        ['id', 'name', 'type', 'details', 'img', 'points', 'coins', 'ods', 'answers', 'question', 'sub_type', 'answerCorrectOption', 'video','number_attempts']
      );
      if(!actionData?.sub_type){
        actionData.sub_type = config.ACTIONS_SUB_TYPES.Normal
      }
      this.setState({action:actionData,user: userData})
    }else{ 
      const flow = config.ACTION_BY_DEFAULT.find((f) => f.id == this.idFlow);
      const actionData = getObjectWithJsonDataToFormValues(
        flow?.action,
        ['id', 'name', 'type', 'details', 'img', 'points', 'coins', 'ods', 'answers', 'question', 'sub_type', 'answerCorrectOption', 'video','number_attempts']
      );
      console.log(flow)
      if (flow) this.setState({ flow: flow, action: actionData,user: userData});
    }
   }

  getAction = async (params) => {
    await this.props.onGet(params);
    console.log('AFTER GET ACTION');
    const { action } = this.props;
    if (action.error) {
      notify(this.t(action.error.message));
    } else {
      const userData = getObjectWithJsonDataToFormValues(
        this.props.auth.user,
        ['id', 'accounts', 'username', 'first_name', 'last_name', 'profile_image', 'rating']
      ); 

      const actionData = getObjectWithJsonDataToFormValues(
        action.item,
        ['id', 'name', 'type', 'details', 'img', 'points', 'coins', 'ods', 'answers', 'question', 'sub_type', 'answerCorrectOption', 'video','number_attempts']
      );

      this.setState({ action: actionData, user: userData,answrerResponse:actionData?.answerCorrectOption?.split(',').map(v=>Number(v)) });
      
    }
  }

  getActionsTransactions = async () => {
    console.log('Trayendo transactions')
    let {action} = this.state
    let params = {target: action.id, type: config.TRANSACTIONS.ACTION.TYPE, sub_type: action.type, status: config.TRANSACTIONS.ACTION.STATUS.COMPLETED, created_by: this.props.auth.user.id}
    await this.props.onGetTransactions(params)
    let {transactions} = this.props
    if (transactions.error){
      notify(this.t(transactions.error.message));
      return false
    } else {
      if((action?.sub_type && 
        action.sub_type !== config.ACTIONS_SUB_TYPES.Normal || this.isStep)){
        let countFail= 0;
        let countAssert= 0;
        transactions.items.forEach(t=>{
          if(t.json_data.coins>0 || t.json_data.points>0){
            countAssert++;
          }else{
            countFail++;
          }
        })
        this.setState({actionAvailable: true, countFail,countAssert,puedeHacerLaAccion:countAssert > 0 || countFail >= Number(!action?.number_attempts ||action?.number_attempts==0 ? 1:action?.number_attempts)? false : true
        })
      }else{
          this.setState({actionAvailable: true})
      }
    }
  }

  goBack = () => {
    const { action } = this.state;
    const fromPath = this.props.location.state?.fromPath
    const resolvedPath = fromPath || this.state.route?.pathname || (action?.id ? config.ROUTES.ACTIONS.DETAIL.replace(':id', action?.id) : config.ROUTES.HOME);
    history.push({ pathname:resolvedPath});
  }

  onClearImageField = ({ name }) => {
    console.log('CLEAR IMAGE FIELD:', name);
    if (name) this.setState({ files: { ...this.state.files, [name]: null } });
  }

  onFileChangeImageInput = async ({ file, data, name }) => {
    this.setState({ submitting: true });
    console.log('ON FILE CHANGE');
    data.append("identifier", `actions_${parseInt(Math.random() * 1000000)}`)
    await this.props.onPictureUpload(data);
    console.log('FILES', this.props.files);
    const error = this.props.files.error;
    console.log('UPLOADED');

    if (error) {
      notify(this.t(error.message));
    } else {
      console.log("UPLOADED", this.props.files)
      this.setState({ imageUpdated: true, files: { ...this.state.files, [name]: this.props.files.files.fileInfo.location } })
    }

    console.log('ON FILE CHANGE FINISH');
    this.setState({ submitting: false });

    return this.props.files.files.fileInfo.location
  }

  onSubmit = async (values) => {
    
    console.log("SUBMITTING")
    const { action, user } = this.state
    const action_img = this.state?.files?.identification_picture || action.img;
    console.log("VALUES", values)
    delete action.details
    let data = {
      target: action.id,
      type: config.TRANSACTIONS.ACTION.TYPE,
      sub_type: action.type,
      source: config.OWNER.SYSTEM,
      status: config.TRANSACTIONS.ACTION.STATUS.COMPLETED,
      json_data: {
        //...values,
        description: values.description ? values.description.replaceAll("\n", " ").replaceAll('"', "") : values.description,
        action_img:this.state?.files?.identification_picture?action_img:action.img,
        action,
        points: action.points,
        coins: action.coins,
        user
      },
      owner: this.props.auth.user.unique_id,
    }
    console.log("DATA", data)
    await this.props.onSaveTransaction(data);
    console.log('AFTER SAVING ACTION');
    const { transaction } = this.props
    if (transaction.error) {
      notify(this.t(transaction.error.message));
    } else {
      if (!this.isStep) {
        history.push({
          pathname: config.ROUTES.ACTIONS.SUCCESS.replace(':id', action.id),
          state: {
            title: this.t("Acción registrada"),
            headline: this.t("Seguí sumando puntos con acciones."),
            button: {
              text: this.t("Continuar"),
              route: config.ROUTES.HOME,
            },
          }
        });
      }else{
        history.push(`/flow/${this.idFlow}/step3`)
      }
    }
  }
  onUpdateUserTour=async()=>{
    console.log('Updated user',this.props.auth.user);
    console.log({user:this.props.auth.user,flows:config.ACTION_BY_DEFAULT,idFlow:this.idFlow})
      const arraysFlows = config.ACTION_BY_DEFAULT
      let stringFlow = ""
      if (!this.props.auth.user.tour) {
       const positionFlow = arraysFlows.findIndex(flow => flow.id === this.idFlow);
   
        arraysFlows.forEach((_, i) => {
          if (i <= positionFlow) {
            stringFlow = stringFlow.concat( (new Date()))
          } else {
            stringFlow = stringFlow.concat(String(0))
          }
          if (arraysFlows.length - 1 > i) stringFlow = stringFlow.concat(',') 
        })
      } else {
        this.props.auth.user.tour.split(',').forEach((_, i) => {
         const positionFlow = arraysFlows.findIndex(flow => flow.id === this.idFlow);
          if (i <= positionFlow) {
            stringFlow = stringFlow.concat(new Date())
          } else {
            stringFlow = stringFlow.concat(String(0))
          }
          if (this.props.auth.user.tour.split(',').length - 1 > i) stringFlow = stringFlow.concat(',')
        })
      }
      const data={
        id:this.props.auth.user.id,
        tour:stringFlow
      }
      console.log(data);
      await this.props.onSaveOrUpdatedUser(data)
      const error= this.props.userUpdate.error
      if(error){
        notify(error)
      }
    }
  render() {
    const { files, action, user, isMobile } = this.state;
    // const puedeHacerLaAccion=(this.state.countAssert > 0 || this.state.countFail >= Number(this.state.action?.number_attempts))&&(action?.number_attempts||1)? false : true

     
    const onSubmitQuestions = async (values) => {
      const answrerResponse = action.answerCorrectOption.split(',').map(v=>Number(v))
      const cantidadRespuestasCorrectas = answrerResponse.filter(num => num == 1).length;
      const cantidadRespuestasCorrectasSeleccionadas = values.response.filter(num => num == 1).length;
      let data = {}
      const action_img = this.state?.files?.identification_picture || action.img
      console.log("VALUES", values)
      delete action.details
      if ((cantidadRespuestasCorrectas !== cantidadRespuestasCorrectasSeleccionadas) || values.response.includes('0')) {
        data = {
          target: action.id,
          type: config.TRANSACTIONS.ACTION.TYPE,
          sub_type: action.type,
          source: config.OWNER.SYSTEM,
          status: config.TRANSACTIONS.ACTION.STATUS.COMPLETED,
          json_data: {
            //...values,
            description: values.description ? values.description.replaceAll("\n", " ") : values.description,
            action_img,
            action,
            points: 0,
            coins: 0,
            user
          },
          owner: this.props.auth.user.unique_id,
        }
        await this.props.onSaveTransaction(data);
        console.log('AFTER SAVING ACTION');
        const { transaction } = this.props
        if (transaction.error) {
          notify(this.t(transaction.error.message));
        }
        // Validar si ya hizo todos los intentos disposbles, Si los hizo modificar el tour para que no pueda hacerlo nuevamente
        const exhausted_attempts= this.state.countFail + 1 >= Number(action?.number_attempts)? true : false
        // solo si uso todos los intentos y si es del flow nomas
        if(this.isStep && exhausted_attempts)await this.onUpdateUserTour();
        return history.push({
          pathname: config.ROUTES.ACTIONS.SUCCESS.replace(':id', action.id),
          state: {
            title: this.t("Lo siento, perdiste."),
            headline: this.t("Seguí participando y sumando puntos con acciones."),
            button: {
              text: this.t("Continuar"),
              route: config.ROUTES.HOME,
            },
          }
        });

      }
 
      data = {
        target: action.id,
        type: config.TRANSACTIONS.ACTION.TYPE,
        sub_type: action.type,
        source: config.OWNER.SYSTEM,
        status: config.TRANSACTIONS.ACTION.STATUS.COMPLETED,
        json_data: {
          //...values,
          description: values.description ? values.description.replaceAll("\n", " ").replaceAll('"', "'")  : values.description,
          action_img:action?.img,
          action,
          points: action.points,
          coins: action.coins,
          user
        },
        owner: this.props.auth.user.unique_id,
      }
      console.log("DATA", data)
      await this.props.onSaveTransaction(data);
      console.log('AFTER SAVING ACTION');
      const { transaction } = this.props
      if (transaction.error) {
        notify(this.t(transaction.error.message));
      } else {

        if (!this.isStep) {
          history.push({
            pathname: config.ROUTES.ACTIONS.SUCCESS.replace(':id', action.id),
            state: {
              title: this.t("Acción registrada"),
              headline: this.t("Seguí sumando puntos con acciones."),
              button: {
                text: this.t("Continuar"),
                route: config.ROUTES.HOME,
              },
            }
          });
        } else {
          history.push(`/flow/${this.idFlow}/step3`)
        }

      }

    }
const dataHeader= !this.isStep?
{
  className: "bg-white text-primary flex justify-start p-3",
  left: { icon: 'arrow_left', action: this.goBack },
  title: capitalize(this.t("confirmar acción"))
}:
{
  className: " hidden",
}
const required = value => (validateIsfilled(value) ? undefined : this.t("This field is required"))

    return (
      <LayoutResponsiveWSmall
        main={{ className: "" }}
        header={{
          className: "text-primary flex justify-start p-3",
          left: { icon: 'arrow_left', action: this.goBack },
          title: capitalize(this.t("confirmar acción"))
        }}
        container={{ className: "px-0" }}
        showGoBackButton={true}
        onGoBackClick={this.goBack}
      >
        <ToastContainer />
        {action && (
          <div className=' flex flex-col h-full mt-1 lg:mb-10'>
           { <section className="bg-success bg-opacity-20 shadow px-3 py-2">
              <div className="flex items-center">
                <Avatar
                  image={action.img}
                  label={user.username}
                  className="rounded w-10 h-10"
                  containerClassName="mr-2"
                />
                <div>
                  <h5>{this.t(action.name)}</h5>
                  <div className="flex gap-2">
                    <IconCoins iconClassName="h-4" color='green' className="" coins={action.coins} />
                    <IconPoints iconClassName="h-4" color='green' points={action.points} />
                  </div>
                </div>
              </div>
            </section>}
              {!this.state.puedeHacerLaAccion&&
              (<div className='bg-red-300 py-2 px-2 text-center '>
               {this.state.countAssert>=1 && <span className='font-bold'>Esta acción ya fue realizada</span>}
                {this.state.countFail>=action.number_attempts && <span className='font-bold'> {`Ya usaste los ${action.number_attempts} intentos disponibles`}</span>}
              </div>)
               }              
            {action?.sub_type === config.ACTIONS_SUB_TYPES.Video && 
            <section className="container-fluid ">
              <div className='h-full lg:w-full flex lg:justify-center lg:mx-auto'>
                <RepoructorVideo video={action.video} onReady={() => this.onReady} videoViewed={this.state.puedeHacerLaAccion?() =>this.onSubmit({}):()=>{}} />
              </div>
            </section>}
            {action?.sub_type !== config.ACTIONS_SUB_TYPES.Question && action?.sub_type !== config.ACTIONS_SUB_TYPES.Video && 
            <section className="container-fluid p-4 flex-1">
              <Form onSubmit={(values) => this.onSubmit(values)}
                validate={(values)=>{
                  console.log(values)
                  const error={}
                  if(!values.checked) error.checked="Este campo es requerido" 
                  return error;
                }}
                >
                {({ handleSubmit, form, submitting, pristine, values }) => (
                  <form onSubmit={handleSubmit} className='h-full flex flex-col justify-between'>
                    <div>
                      <div className="w-full">
                        <h4 className='pl-2 mb-1'>Subí una foto realizando la acción (*)</h4>
                        <Field name="identification_picture"
                          component={ImageUploadFileInputEdit}
                          img={files.identification_picture}
                          inputOnChange={this.onFileChangeImageInput}
                          clickButtonClear={this.onClearImageField}
                          // disabled={!isMobile}
                        />
                        {!files.identification_picture &&
                          <p className="text-left flex leading-4 justify-start">
                            <Icon name='information_solid' className='mr-1' color='primary' size={20} />
                            {true ? "Sacate una foto realizando la acción." : "Solo podes realizar esta acción desde el teléfono"}
                          </p>}
                      </div>
                      <div className="w-full mt-5  md:mb-0">
                        {/* <h4 className='pl-2 mb-1'>{this.t("Escribí un comentario (opcional)")}</h4> */}
                        <Field
                          name="checked"
                          label="Confirmo que la foto es de una acción propia realizada en el último mes"
                          component={CheckboxInput}
                          labelClassName='flex justify-start gap-4 font-bold'
                          inputClassName='resize-none outline-0 h-32 rounded-full'
                          type="checkbox"
                        />
                      </div>
                      <div className="w-full mt-2  mb-3 md:mb-0">
                        <h4 className='pl-2 mb-1'>{this.t("Escribí un comentario (opcional)")}</h4>
                        <Field
                          name="description"
                          component={TextareaInput}
                          labelClassName='font-semibold'
                          inputClassName='resize-none outline-0 h-32'
                          placeholder={capitalize(this.t('comentario'))}
                        // disabled={!files.identification_picture}
                        />
                      </div>
                    </div>
                    <Button
                      className="btn-primary btn-block mt-20 mb-2"
                      title={capitalize(this.t("confirmar"))}
                      onClick={handleSubmit}
                      disabled={submitting || !files.identification_picture || !values.checked}
                      type='submit'
                    />
                  </form>
                )}
              </Form>
            </section>}
            {action?.answers && action?.sub_type === config.ACTIONS_SUB_TYPES.Question && <section className='flex flex-col h-full mx-auto md:min-w-[500px] w-full'>
              {/* Header */}
             
              <div className='basis-2/6 flex flex-col justify-end'>
                <RepoructorVideo video={action.video} onReady={() => this.onReady} videoViewed={() => this.onEnded()} />
              </div>
              {/* body */}
              <div className='basis-4/6 bg-white'>
               {/* {JSON.stringify({puedeHacerLaAccion,o:(puedeHacerLaAccion === true) ? 1 : null})} */}
                <Form
                  onSubmit={onSubmitQuestions}
                  validate={(values) => {
                    console.log(values)
                    const error = {}
                    if (!values.response || values?.response?.length < 1) {
                      error.response = "Debe seleccionar una respuesta"
                    }
                    return error
                  }}
                >
                  {({ handleSubmit, form, submitting, pristine, values }) => (
                    <form onSubmit={handleSubmit} className='h-full'>
                      <div className='basis-5/6  flex flex-col   py-8 items-center pt-4 px-4'>
                        <h1 className='text-primary  font-bold mb-6'>{action.question}</h1>
                        <ul className=' flex flex-col gap-4 w-full'>
                          {action.answers.split('-_-_').map((op, i) =>
                            <li key={op} className='flex w-full py-3 px-2 bg-gray-100 gap-2 rounded-md '>
                              <Field name='response' value={action.answerCorrectOption.split(',')[i]}>
                                {props => (
                                  <div className='flex flex-col'>
                                    <div className='flex gap-2'>
                                      <input
                                        key={`inp-${i}`}
                                        {...props.input}
                                        disabled={!this.state.videoEnded || !this.state.puedeHacerLaAccion}
                                        type="checkbox"
                                        className="checkbox checkbox-white  bg-opacity-25 border rounded-full self-center"
                                        id={`op-check-${i}`}
                                        checked={this.state.puedeHacerLaAccion === false ? this.state?.answrerResponse?.at(i) : props.input.checked}
                                      />
                                      <label className='text-start text-primary font-normal cursor-pointer' htmlFor={`op-check-${i}`} >
                                        {`${op}`}
                                      </label>
                                    </div>
                                    {props.meta.error && props.meta.touched && <span className='text-red-600'>*{props.meta.error}</span>}
                                  </div>
                                )}
                              </Field>
                            </li>
                          )}
                        </ul>
                      </div>
                      <div className='basis-1/6 px-4 flex gap-2 mb-10 lg:mb-0'>
                        {this.state.puedeHacerLaAccion && <button type='submit' className={`btn btn-primary self-center flex-1 mb-5`} onClick={() => { }} disabled={!this.state.videoEnded} >{"Confirmar".toUpperCase()}</button>}
                        {!this.state.puedeHacerLaAccion && <button type='button' className={`btn btn-primary self-center flex-1 mb-5`} onClick={() => history.push(`/`)} >{"Ir a Inicio".toUpperCase()}</button>}
                      </div>
                    </form>
                  )}
                </Form>

              </div>
            </section>}
          </div>
        )}


      </LayoutResponsiveWSmall>
    )
  }
}


const mapStateToProps = state => {
  return {
    auth: state.users.auth,
    files: state.files,
    action: state.actions.current,
    transaction: state.transactions.current,
    transactions: state.transactions.list,
    userUpdate: state.users.current,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onPictureUpload: (params) => dispatch(filesActions.upload(params)),
    onGet: (params) => dispatch(actionsAction.get(params)),
    onSaveTransaction: (params) => dispatch(transactionsAction.saveOrUpdate(params)),
    onGetTransactions : (params) => dispatch(transactionsAction.getAll(params)),
    onSaveOrUpdatedUser:(params)=>dispatch(actionsUser.saveOrUpdate(params)),
    onGetToken: () => dispatch(actionsUser.getFromToken()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(ShareAction));
