import React from 'react';
import { withTranslation } from 'react-i18next';
import { history } from '../../routes';
import config from '../../config';

import Icon from '../../libraries/icons';
import { capitalize } from '../../libraries/utils';
import LayoutResponsiveWSmall from '../../components/layout/LayoutResponsiveWSmall';




class InfoContactUs extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
    }
  }

  componentDidMount() {
    console.log('HOW WORKS PAGE');
  }

  onExit = async values => {
    history.push(config.ROUTES.HOME);
  }

  render() {
    return (
      <LayoutResponsiveWSmall
        header={{
          className: "",
          title: capitalize(this.t("contact us")),
          left: { icon: 'arrow_left', action: () => history.push(config.ROUTES.INFO) }
        }}
        showGoBackButton={true}
        onGoBackClick={() => history.push(config.ROUTES.INFO)}
      // main={{ 
      //   className: "light-primary-bg text-gray-700",
      // }}
      >
        <h3 className="h3 mb-2 mt-10 lg:mx-4"> <strong> Encuentranos en</strong></h3>
        {/* <p className="mt-5"> <strong> Facebook:</strong> <a className="link text-primary"  target="_blank" href="https://www.facebook.com">Sharyco</a></p> */}
        {/* <p> <strong> Instagram: </strong> <a className="link text-primary" target="_blank" href="https://www.instagram.com">sharyco</a></p> */}
        {/* <p> <strong> Mail: </strong> <a className="link text-primary" href="mailto:...">sharyco@gmail.com</a></p> */}
        <div className="grid grid-cols-2 gap-4 mx-4">
          <div className="card white p-3 text-center">
            <p className="mb-2"> <strong ><Icon className="block text-primary w-12 mb-2 mx-auto" name="globe_alt" /> {this.t("Web Page")}: </strong><br></br> <a className="link text-primary" target="_blank" href="https://sharyco.com/"> https://sharyco.com</a></p>
          </div>
          <div className="card white p-3 text-center">
            <p><strong> <Icon className="block text-primary w-12 mb-2 mx-auto" name="whatsapp" />{this.t("Whatsapp")}  </strong>Argentina: <br></br><a className="link text-primary" target="_blank" href="https://api.whatsapp.com/send?phone=+5491135232788&text=Necesito ayuda con...">+5491135232788</a></p>
          </div>
          <div className="card white p-3 text-center">
            <p className="mb-2"> <strong ><Icon className="block text-primary w-12 mb-2 mx-auto" name="support" /> {this.t("Mail")}: </strong><br></br> <a className="link text-primary" target="_blank" href="mailto:info@sharyco.com">info@sharyco.com</a></p>
          </div>
        </div>

      </LayoutResponsiveWSmall>
    )
  }
}

export default withTranslation()(InfoContactUs);
