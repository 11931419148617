import React from 'react';
import Icon from '../../libraries/icons';

export default function ButtonGoBack({ onClick, className, disabled }) {
  return (
    <div>
      {onClick && (
        <button
          type="button"  //bg-white text-gray-600 hover:text-gray-900
          className={"border-none h-8 hidden lg:flex lg:justify-center bg-base-300 top-20 gap-2 text-primary flex-row-reverse justify-center w-24 rounded-md cursor-pointer items-center btnBack " + (className ? className : "")}
          onClick={onClick}
          disabled={disabled}
        >
          Volver
          <Icon className="h-8" name={'cheveron_left'} />
        </button>
  
      )}
    </div>
  )
}