
import React from 'react';
import { connect } from 'react-redux';
import { Form, FormSpy, Field } from "react-final-form";
import setFieldData from "final-form-set-field-data";
import { withTranslation } from 'react-i18next';
import { ToastContainer, notify } from '../../libraries/notifications';
import { history } from '../../routes';
import { isEmptyObject, capitalize, validateIsfilled, getObjectWithJsonDataToFormValues, formatOnlyNumberInput } from '../../libraries/utils';

import config from '../../config';

import TextInput from '../../components/forms/TextInput';
import TextareaInput from '../../components/forms/TextareaInput';
import SelectInput from '../../components/forms/SelectInput';
import Button from '../../components/commons/Button';
// import ImageUploadFileInput from '../../components/forms/ImageUploadFileInput';
import ImageUploadFileInputEdit from '../../components/forms/ImageUploadFileInputEdit';
import Swal from 'sweetalert2';

import locationActions from '../../context/locations/actions';
import filesActions from '../../context/files/actions';
import productsAction from '../../context/products/actions';
import usersAction from '../../context/users/actions';
import categoriesAction from '../../context/categories/actions';
import LayoutResponsiveWSmall from '../../components/layout/LayoutResponsiveWSmall';


const WarningEngine = ({ mutators: { setFieldData }, max_value, min_value }) => {
  return(
    <FormSpy
      subscription={{ values: true }}
      onChange={({ values }) => {
        if (max_value !== null && values.coins > max_value) {
          setFieldData("coins", {
            warning: `Advertencia: los puntos son mayores a los recomendados`,
          });
          return;
        }
        if (min_value !== null && values.coins < min_value) {
          setFieldData("coins", {
            warning: `Advertencia: los puntos son menores a los recomendados`,
          });
          return;
        }
        setFieldData("coins", {
          warning: undefined,
        });
      }}
    />
)}



class Step2 extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      type: null,
      category: null,
      categories: [],
      files: {},
      user: this.props.auth.user.id,
      product: {},
      owner: {},
      imageUpdated: false,
      editing: false
    }
  }
  
  componentDidMount() {
    console.log('STEP 2', this.props.location);
    this.setState({loading: true})
    const { fromPath } = this.props.location.state
    if(fromPath){
      this.setState({fromPath})
    }
    if (!this.props.location?.state?.type) {
      //NO CATEGORY STATE, THEN EDIT OFFER
      if(!isEmptyObject(this.props.match.params) && (this.props.match.params.id)){
        const params = this.props.match.params;
        this.getProduct(params, fromPath);
      } else {
        history.push(config.ROUTES.HOME);
        return
      }

    } else {
      const { type, category, fromRequest, requesterId, requester, categoryFromRequest, search, parent } = this.props.location.state;
      console.log('STATE???', search, parent )
      this.setState({ type, category, search, parent });
      this.getCategories({ id: category.id });
      if(fromRequest){
        console.log("FROM REQUEST", fromRequest, requesterId, categoryFromRequest, fromPath)
        console.log("FROM REQUEST REQUESTER", requester)
        this.setState({ fromRequest, requesterId, requester, categoryFromRequest, search, parent  })
      
      }
    }
    
    if (this.props.location?.state?.productItems) {
      this.setState({product: {
          name: this.props.location?.state?.productItems?.name,
          details: this.props.location?.state?.productItems?.details,
          coins: this.props.location?.state?.productItems?.coins,
          brand: this.props.location?.state?.productItems?.brand,
          new_brand: this.props.location?.state?.productItems?.new_brand,
          state: this.props.location?.state?.productItems?.state,
          points: this.props.location?.state?.productItems?.points
        },
        files: {
          ...this.state.files,
          product_img: this.props.location?.state?.productImg,
        }
      })
    }

    this.setState({loading: false})
  }

  getCategories = async (params) => {
    await this.props.onGetCategoryPath(params);
    const { categories } = this.props;
    if (categories.error) { 
      notify(this.t(categories.error.message));
    } else {
      this.setState({ categories: categories.items})
    }
  }

  getProduct = async (params, fromPath) => {
    await this.props.onGetProduct(params);
    const { product } = this.props;
    if (product.error) {
      notify(this.t(product.error.message));
    } else {
      let productData = getObjectWithJsonDataToFormValues(
        product.item,
        ['id', 'type', 'owner', 'img', 'name', 'status', 'details', 'points', 'coins', 'brand', 'state', 'category']
      );

      if(productData.brand !== "sin marca") {
        productData.new_brand = productData.brand
        productData.brand = "especificar marca"
      }

      await this.props.onGetOwner({ id: productData.owner });
      const owner = this.props.owner;
      if (owner.error) {
        notify(this.t(owner.error.message));
      } else {

        const files = {
          ...this.state.files,
          product_img: productData.img
        }
        const ownerData = getObjectWithJsonDataToFormValues(
          owner.item,
          ['id', 'accounts', 'username', 'first_name', 'last_name', 'profile_picture', 'rating']
        );

        this.setState({ type: productData.type, product: productData, owner: ownerData, files, editing:true, fromPath})

      }
    }    
  }

  onSubmit = (values) => {
    const { type, category, product, files, editing, fromRequest, requesterId, requester, categoryFromRequest } = this.state
    history.push(config.ROUTES.STEP3, {
      productId: product.id,
      productItems: values,
      type,
      category,
      productImg: files.product_img,
      editing: editing,
      fromRequest, 
      requesterId,
      requester,
      categoryFromRequest
    })
  }

  changeProductAvailability = async() => {
    console.log('DISABLE/SHOW PRODUCT');
    const {product, type} = this.state

    const message = [
      product.status === config.STATUS.PRODUCTS.DISABLED
        ? this.t(`Estás publicando nuevamente tu ${type === config.TYPES.OFFERS.PRODUCTS ? 'producto' : 'favor'}`)
        : this.t(`Estás ocultando tu ${type === config.TYPES.OFFERS.PRODUCTS ? 'producto' : 'favor'}`)
    ];

    const result = await Swal.fire({
      title: this.t("¿Are you sure?"),
      text: message.join(''),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.t("Yes"),
      cancelButtonText: this.t("Cancel"),
    })
    .then((result) => {
      return result
    });
    console.log('ANSWER', result);
    if (result && result.isConfirmed) {
      this.updateProductStatus(product);

    // } else if (result && result.isDismissed) {
    //   Swal.fire('', this.t("El producto no fue editado"), 'error');
    }
  }

  updateProductStatus = async(product) => {
    console.log('DISABLE/SHOW PRODUCT - confirmed', product);
    const {type} = this.state;
    await this.props.onChangeProductAvailability({
      id: product.id,
      status: product.status === config.STATUS.PRODUCTS.AVAILABLE ? config.STATUS.PRODUCTS.DISABLED : config.STATUS.PRODUCTS.AVAILABLE
    })
    console.log('AFTER DISABLING/SHOWING PRODUCT', this.props.product);
    if (this.props.error) {
      notify(this.t(this.props.product.error.message));
    }else{
      
        history.push({
          pathname: config.ROUTES.PRODUCTS.SUCCESS.replace(':id', this.props.product.item.id),
          state: {
            title: this.props.product.item.status === config.STATUS.PRODUCTS.DISABLED ? this.t(`Ocultaste tu ${type === config.TYPES.OFFERS.PRODUCTS ? 'producto' : 'favor'}`): this.t(`Publicaste tu ${type === config.TYPES.OFFERS.PRODUCTS ? 'producto' : 'favor'}`),
            headline: "Revisa tus ofertas",
            button:{
              text: "Continuar",
              route: config.ROUTES.USER.INVENTORY,
            },
          }
        });
      


    }
  }

  onFileChangeImageInput = async ({file, data, name}) => {
    this.setState({ submitting: true });
    console.log('ON FILE CHANGE');
    
    data.append("identifier", `products_${ parseInt(Math.random()*1000000)}`)
    await this.props.onPictureUpload(data);
    console.log('FILES', this.props.files);
    const error = this.props.files.error;
    console.log('UPLOADED');

    if (error) {
      notify(this.t(error.message));
    } else {
      console.log("UPLOADED", this.props.files)
      this.setState({ imageUpdated: true, files: { ...this.state.files, [name]:this.props.files.files.fileInfo.location } })
    }

    console.log('ON FILE CHANGE FINISH');
    this.setState({ submitting: false });

    return this.props.files.files.fileInfo.location
  }

  onClearImageField = ({ name }) => {
    console.log('CLEAR IMAGE FIELD:', name);
    if (name) this.setState({ files: {...this.state.files, [name]: null }});
  }

  goBack = (editing) => {
    if(this.state.loading) return
    // if(this.state.fromPath){
    //   history.push(this.state.fromPath)
    // } else {
      // const { type, search, parent } = this.state
      
      history.push({

        // add/offers/products
        pathname:editing ? config.ROUTES.USER.INVENTORY : config.ROUTES.HOME_OPTIONS.OFFERS.replace('offers', this.props.location?.state?.type),
        // pathname: config.ROUTES.CATEGORY.replace(':type', 'offers').replace(':sub_type', this.props.location?.state?.type),
        // pathname: config.ROUTES.HOME_OPTIONS.OFFERS.replace('offers', this.props.location?.state?.type)
        // state: { search, parent }
      })
    // }
  }


  render() {
    const { type, category, categories, product, files, editing, imageUpdated} = this.state 

    const brands = config.OPTIONS.PRODUCTS.BRANDS.map((o) => { return { value: o, label: capitalize(o) }});
    const productState = config.OPTIONS.PRODUCTS.PRODUCT_STATE.map((o) => { return { value: o, label: capitalize(o) }});
    
    const min_value = category && category.json_data ? category.json_data.min_value : null;
    const max_value = category && category.json_data ? category.json_data.max_value : null;

    let initialValues = !isEmptyObject(product) ? product : { points: 3, name: this.state.detailFromRequest }

    const validateForm = values => {
      const errors = {}
      if (type === config.TYPES.OFFERS.PRODUCTS) {
        errors.product_img = (!files.product_img ? this.t("Este campo es obligatorio"): undefined );
        errors.brand = (validateIsfilled(values.brand) ? undefined : this.t("Este campo es obligatorio"));
        errors.state = (validateIsfilled(values.state) ? undefined : this.t("Este campo es obligatorio"));
        errors.name = (validateIsfilled(values.name) ? undefined : this.t("Este campo es obligatorio"));
        errors.details = (validateIsfilled(values.details) ? undefined : this.t("Este campo es obligatorio"));
        errors.coins = (validateIsfilled(values.coins) ? undefined : this.t("Este campo es obligatorio"));
      }
      if (type === config.TYPES.OFFERS.SERVICES) {
        errors.name = (validateIsfilled(values.name) ? undefined : this.t("Este campo es obligatorio"));
        errors.details = (validateIsfilled(values.details) ? undefined : this.t("Este campo es obligatorio"));
        errors.coins = (validateIsfilled(values.coins) ? undefined : this.t("Este campo es obligatorio"));
      }
      if (type === config.TYPES.OFFERS.FAVORS) {
        errors.name = (validateIsfilled(values.name) ? undefined : this.t("Este campo es obligatorio"));
        errors.details = (validateIsfilled(values.details) ? undefined : this.t("Este campo es obligatorio"));
        errors.coins = (validateIsfilled(values.coins) ? undefined : this.t("Este campo es obligatorio"));
      }
      if(values.brand === "especificar marca" && !values.new_brand){
        errors.new_brand = (validateIsfilled(values.new_brand) ? undefined : this.t("Este campo es obligatorio"));
      }
      return errors;
    };
    console.log(editing)
    return (
      <LayoutResponsiveWSmall
        main={{ className: ""}}
        header={{ 
          left: !this.state.loading ? { icon: 'arrow_left', action: () => {editing ? this.goBack(editing) : this.goBack()} } : {},
          className: "lg:hidden",
        }}
        container={{ className: "px-0"}}
        showGoBackButton={true}
        onGoBackClick={() => {editing ? this.goBack(editing) : this.goBack()}}
      >
        <ToastContainer/>
        <section className="bg-primary text-primary-content text-center px-4 pb-4 lg:mt-14">
          {/* <Icon className="h-16 w-16 mx-auto mb-1" name="pin" /> */}
          {type && <h1>{this.t(`${type} information`)}</h1>}
        </section>
        <section className="p-3 mx-auto mb-12 lg:mb-0">
          <Form 
            initialValues={initialValues}
            validate={validateForm}
            onSubmit={this.onSubmit}
            mutators={{ setFieldData }}
          >
            {({ handleSubmit, form, submitting, pristine, values }) => (
              <form onSubmit={handleSubmit}>
                {type === config.TYPES.OFFERS.PRODUCTS && (
                  <div className="w-full mb-2">
                    <Field 
                      name="product_img" 
                      component={ImageUploadFileInputEdit}
                      img={files.product_img} 
                      placeholder={this.t("Subir foto")} 
                      label={this.t("Fotos del producto")}
                      inputOnChange={this.onFileChangeImageInput}
                      clickButtonClear={this.onClearImageField}
                      inputClassName="input-bordered shadow-none"
                      noError={true}
                    />
                    {!files.product_img && <h5 className="text-center ">Adjuntar una foto para poder continuar </h5>}
                  </div>
                )}
                <div className="ml-1 mb-2 max-w-xs text-sm breadcrumbs">
                  <ul>
                    {categories.map((category, index) => (
                      <li key={index}>{category.name}</li> 
                    ))}
                  </ul>
                </div>
                <div className="w-full mb-2 md:mb-0">
                  <Field name="name" component={TextInput} 
                    placeholder= {type === config.TYPES.OFFERS.PRODUCTS ? 
                      this.t("Nombre del producto"):
                      (type === config.TYPES.OFFERS.SERVICES ? this.t("Nombre del servicio"):this.t("Nombre del favor"))}
                    //  defaultValue={product && product.name}
                    // disabled={config.OPTIONS.VEHICLES.VALIDATE_NO_MODEL.includes(values.type)}
                    
                  />
                </div>
                <div className="w-full mb-2 md:mb-0">
                  <Field name="details" component={TextareaInput} placeholder={this.t("Descripción") } 
                    // defaultValue={product && product.details }
                    // disabled={config.OPTIONS.VEHICLES.VALIDATE_NO_MODEL.includes(values.type)}
                  />
                </div>

                
                {type === config.TYPES.OFFERS.PRODUCTS && (
                  <>
                    <div className="flex gap-2">
                      <div className="w-full flex-1 mb-2 md:mb-0 ">
                        <Field
                          label={this.t("Marca del producto")}
                          name="brand"  
                          component={SelectInput} 
                          placeholder={this.t("Marca")}
                          // defaultValue={product && product.brand }
                          options={brands}
                          empty={this.t("Marca del producto")}
                          selectClassName='w-full'
                        />
                      </div>
                      {values.brand === "especificar marca" && (
                        <div className="w-full flex-1 mb-2 md:mb-0">
                          <Field label="&nbsp;" name="new_brand" component={TextInput} placeholder={this.t("Marca del producto")}
                            // defaultValue={product && product.brand }
                          />
                        </div>
                      )}                    

                    </div>
                    <div className="flex gap-2">
                      <div className="w-full flex-1 mb-2 md:mb-0">
                        <Field 
                          label={this.t("Estado del producto")}
                          name="state"  
                          component={SelectInput} 
                          placeholder={this.t("Estado")}
                          // defaultValue={product && product.state }
                        /*  options={this.state.initialState} */
                          options={productState}
                          empty={this.t("Estado del producto")}
                          selectClassName='w-full'
                        />
                      </div>
                    </div>
                  </>
                )}

                <div className="flex mb-2 md:mb-0">
                  <div className="w-2/4 mr-4">
                    <Field name="coins" component={TextInput} label={this.t("Puntos Shary")} 
                      placeholder={this.t("Ingrese puntos Shary")} parse={v => formatOnlyNumberInput(v,5)} 
                    />
                    {(min_value || max_value) && <div className="w-full mb-3 md:mb-0">
                      <h4>{this.t("Puntos Shary sugeridos")}</h4>
                      <div className="flex">
                        {min_value && <h5>{this.t("Min")} <strong>{min_value}</strong></h5>}
                        {max_value && <h5 className="ml-5">{this.t("Max")} <strong>{max_value}</strong></h5>}
                      </div>
                      {/*  <p className="mt-2">{this.t("Por día del producto prestado u hora del servicio o favor brindado.")}</p> */}
                    </div>}
                  </div>
                  {type && ( 
                  <div className="w-2/4">
                    <Field 
                      inputClassName="opacity-60"
                      name="points" 
                      component={TextInput} 
                      label={this.t("Puntos de Impacto")} 
                      placeholder={this.t("Puntos de Impacto")}
                      readOnly={true} 
                      // defaultValue={product && product.coins }
                      // disabled={config.OPTIONS.VEHICLES.VALIDATE_NO_MODEL.includes(values.type)}
                    />
                  </div>)}
                </div>
                   

                {editing && product.status !== config.STATUS.PRODUCTS.BORROWED && (
                  <Button
                    className="btn-secondary btn-block mt-3"
                    title={product.status === config.STATUS.PRODUCTS.AVAILABLE ? this.t(`Deshabilitar el ${type === config.TYPES.OFFERS.PRODUCTS ? 'producto' : 'favor'}`):this.t(`Habilitar el ${type === config.TYPES.OFFERS.PRODUCTS ? 'producto' : 'favor'}`) }
                    onClick={this.changeProductAvailability}
                    disabled={submitting}

                  />
                )}
                <Button
                  className="btn-primary btn-block mt-3"
                  title={this.t("Siguiente")}
                  onClick={handleSubmit}
                  disabled={submitting || ((pristine && !imageUpdated) && !this.props.location?.state?.productItems) || (!this.state.files.product_img && type === config.TYPES.OFFERS.PRODUCTS) || !values.name || !values.details || !values.coins}
                />
                <WarningEngine mutators={form.mutators} max_value={max_value} min_value={min_value}/>
              </form>
            )}
          </Form>
        </section>
      
      </LayoutResponsiveWSmall>      
    ) 
  }
}

const mapStateToProps = state => {
  return {
    auth: state.users.auth,    
    locations: state.locations.list,
    files: state.files,
    product: state.products.current,
    categories : state.categories.list,
    owner: state.users.current,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetAll: (params) => dispatch(locationActions.getAll(params)),
    onRemove: (id) => dispatch(locationActions.del(id)),
    onPictureUpload: (params) => dispatch(filesActions.upload(params)),
    onGetProduct: (params) => dispatch(productsAction.get(params)),
    onGetOwner: (params) => dispatch(usersAction.get(params)),
    onChangeProductAvailability: (params) => dispatch(productsAction.saveOrUpdate(params)),
    onGetCategoryPath: (params) => dispatch(categoriesAction.getPath(params)),
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(Step2));
