import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Form, Field } from "react-final-form";
import { WhenFieldChangesDo } from '../../libraries/forms';
import config from '../../config';
import { capitalize } from '../../libraries/utils';

import { ToastContainer, notify } from '../../libraries/notifications';

import InventoryCardMyOffers from '../../components/customs/InventoryCardMyOffers';
import LayoutWithSidebar from '../../components/layout/LayoutWithSidebar';
import BottomNav from '../../components/commons/BottomNav';


import productsAction from '../../context/products/actions';
import servicesAction from '../../context/services/actions';
import favorsAction from '../../context/favors/actions';
import transactionsAction from '../../context/transactions/actions';

import NewProductModal from '../../components/customs/NewProductModal';
import RequestElement from '../../components/customs/RequestElement';
import Icon from '../../libraries/icons';
import LayoutResponsive from '../../components/layout/LayoutResponsive';

class Inventory extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      selected: 'products',
      products: [],
      services: [],
      favors: [],
      requests: [],
      newProductModalOpen: false,
    }
  }

  async componentDidMount() {
    console.log('Inventory');
    await this.getProducts();
    await this.getFavors();
    await this.getServices();
    await this.getRequests()
  }

  getProducts = async () => {
    await this.props.onGetProducts({ owner: this.props.auth.user.id });
    const { products } = this.props;
    if (products.error) {
      notify(this.t(products.error.message));
    } else {
      this.setState({ offers: products.items, products: products.items });
    }
  }

  getFavors = async () => {
    await this.props.onGetFavors({ owner: this.props.auth.user.id });
    const { favors } = this.props;
    if (favors.error) {
      notify(this.t(favors.error.message));
    } else {
      this.setState({ favors: favors.items });
      //this.setState(prevState => ({
      //  favors: [...prevState.favors, ...favors.items]
      //}));
    }
  }

  getServices = async () => {
    await this.props.onGetServices({ owner: this.props.auth.user.id });
    const { services } = this.props;
    if (services.error) {
      notify(this.t(services.error.message));
    } else {
      this.setState(prevState => ({
        favors: [...prevState.favors, ...services.items.filter(s => s.type === 'services')]
      }));
    }
  }

  getRequests = async () => {
    const params = {
      type: [
        config.TRANSACTIONS.REQUEST.TYPE,
      ],
      owner: this.props.auth.user.unique_id,
      created_by: this.props.auth.user.id,
      order_by: "created_at",
      order_direction: 'DESC'
    }
    await this.props.onGetAllRequests(params);
    const { requests } = this.props;
    if (requests.error) {
      notify(this.t(requests.error.message));
    } else {
      this.setState({ requests: requests.items });
    }
  }

  // changeOrigin = (e) => {
  //   this.setState({ offers: this.state[e.target.value]})
  // }

  onSubmit = (value) => {
    this.setState({ offers: this.state[value], selected: value })
  }

  goToNewProduct = () => {
    this.setState({ newProductModalOpen: true })
    //history.push({
    //  pathname: config.ROUTES.ADD,
    //  state:{
    //    fromPath: config.ROUTES.USER.INVENTORY,
    //  }
    //})
  }


  render() {
    const { offers, selected } = this.state;

    const options = ['products', 'favors'].map((o) => { return { value: o.toLocaleLowerCase(), label: this.t(capitalize(o.toLocaleLowerCase())) } });

    return (
      <LayoutResponsive
        main={{ className: "pb-14" }}
        header={{
          title: this.t("Mi inventario"),
          className: "",
          right: { icon: 'plus', action: this.goToNewProduct, buttonClassName: "bg-primary" },

        }}
        container={{ className: "px-0" }}
      >
        <NewProductModal t={this.t} open={this.state.newProductModalOpen} setOpen={(open) => this.setState({ newProductModalOpen: open })} />
        <div className="w-full mx-auto mb-12 lg:mb-0">
          <section className="">
            <div className='flex justify-between  '>
              {options.map((o, i) => (
                <div
                  key={i}
                  className={"px-4 py-3 text-center border-b-4 border-transparent font-medium flex-1 rounded-none " + (o.value === selected ? " border-b-secondary font-semibold text-base-content " : " text-neutral ") + ""}
                  onClick={() => this.onSubmit(o.value)}
                >
                  <h5 className='whitespace-nowrap'>{this.t(o.label)}</h5>
                </div>
              ))}
            </div>
          </section>
          <section className="p-3">
            <div className="container gap-2">
              {offers && offers.length === 0 && 
                <div className="text-center p-10 text-gray-400">
                  <Icon className="h-14 w-14 mx-auto mb-2" name="loans" />
                  <p className="mb-1">{this.t(`No hay ${selected === "products" ? "productos" : "favores"}`)}</p>
                </div>
              }
              {offers && offers.map((offer, index) => {
                if (offer.type === "request") return (
                  <RequestElement
                    key={'p' + index}
                    route={
                      { pathname: config.ROUTES.REQUESTS.PREVIEW.replace(":id", offer.id) }
                    }
                    request={offer}
                  />
                )
                return (
                  <InventoryCardMyOffers
                    key={index}
                    data={offer}
                    className="mb-3 shadow"
                  />
                )
              })
              }
            </div>
          </section>
        </div>
        {/* <BottomNav /> */}
      </LayoutResponsive>
    )
  }
}


const mapStateToProps = state => {
  return {
    auth: state.users.auth,
    products: state.products.list,
    services: state.services.list,
    favors: state.favors.list,
    requests: state.transactions.list,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetProducts: (params) => dispatch(productsAction.getAll(params)),
    onGetServices: (params) => dispatch(servicesAction.getAll(params)),
    onGetFavors: (params) => dispatch(favorsAction.getAll(params)),
    onGetAllRequests: (params) => dispatch(transactionsAction.getAll(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Inventory));

