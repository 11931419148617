import Button from "../../components/commons/Button";
import imgCelebration from "../../assets/img/img-celebration.png";
import React, { Component } from 'react'
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import actions from "../../context/actions/actions";
import actionsUser from "../../context/users/actions";
import { notify } from "../../libraries/notifications";
import IconCoins from "../../components/customs/IconCoins";
import IconPoints from "../../components/customs/IconPoints";
import Loader from "../../components/commons/Loader";
import { history } from "../../routes";
import actionTransactions from "../../context/transactions/actions";
import benefitsActions from "../../context/benefits/actions";
import settingActions from "../../context/settings/actions";
import internalsActions from "../../context/internals/actions";
import config from "../../config/";
import { CardOnboarding } from "./FlowStep1";
import { getObjectWithJsonDataToFormValues } from "../../libraries/utils";


class FlowStep3 extends Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.id = this.props.match.params.id;
    this.idFlow = this.props.match.params.idFlow;
    this.currentUrl = this.props.location?.pathname;
    this.state = {
      // action: null,
    }
  }
  async getFlow() {
    const flow = await config.ACTION_BY_DEFAULT.find(f => f.id == this.idFlow)
    if (flow) {
      await this.getActionsTransactions(flow?.challenge)
      this.setState({ flow: flow, challenge: flow?.challenge })
      const isPrize = flow.challenge?.json_data?.benefits.quantity == null &&
      flow.challenge?.json_data?.benefits.benefit != 'credits' &&
      flow.challenge?.json_data?.benefits.benefit != 'coins' ? true : false
      isPrize && await this.getSetting()
      await this.getAction({ id: flow?.action.id })
    }
  }

  async getAction(params) {
    // alert(JSON.stringify(params))
    await this.props.onGetAction(params)
    const error = this.props.action.error
    if (error) {
      notify(error)
    } else {
      this.setState({ action: this.props.action.item })
    }
  }

  getActionsTransactions = async (challenge) => {
    console.log('Trayendo transactions')
    let params = { target: this.props.user.id, type: 'challenge',source:this.props.user.unique_id}
    await this.props.onGetTransactions(params)
    let { transactions } = this.props
    if (transactions.error) {
      notify(this.t(transactions.error.message));
      return false
    } else {
      let countAssert = 0;
      let arrAssert=[]
      transactions.items.forEach(t => {
        if (t.json_data.challenge?.id == challenge?.id) {
          countAssert++;
          arrAssert.push(t)
        }
      })
      this.setState({ actionAvailable: countAssert >= 1 ? false : true, countAssert,arrAssert })
    }
  }

  getBenefit = async (id) => {
    await this.props.onGetBenefit(id);
    const { benefit } = this.props;
    if (benefit.error) {
      notify(this.t(benefit.error.message));
    } else {
      this.setState({ benefit: benefit.item, prize: benefit.item.type === config.TYPES.PRIZE });
    }
  }

  saveTransactionPrize = async () => {
    let code = parseInt(Math.random() * 10 ** 9).toString().padStart(9, "0")
    //FIXME: chequear por codigo duplicado (poco probable)

    const userData = getObjectWithJsonDataToFormValues(
      this.props.user,
      ['id', 'username', 'first_name', 'last_name', 'profile_image', 'email']
    );

    let data = {
      target: this.props.benefit.item.id,
      type: config.TRANSACTIONS.BENEFIT.TYPE,
      sub_type: config.TRANSACTIONS.BENEFIT.SUB_TYPE.PRIZE,
      status: config.TRANSACTIONS.BENEFIT.STATUS.RESERVED,//reserved/consumed
      source: this.props.user.unique_id,//business id //si es prize company_id, id del owner del beneficio ese
      json_data: {
        user: userData,
        benefit: this.props.benefit.item,
        //busines,
        code,
        owner: userData,
      },
      owner: this.props.user.unique_id,
    }
    console.log("DATA", data)
    await this.props.onSaveTransaction(data);
    console.log('AFTER SAVING TRANSACTION');
    const { transaction } = this.props
    if (transaction.error) {
      notify(this.t(transaction.error.message));
    } else {
      await this.sendMailUser()
    }
  }


  sendMailUser = async () => {
    // let subject = this.props.benefit.item.type === config.TYPES.PRIZE ? "Descargaste un premio" : "Descargaste un descuento"
    let mailData = {
      template: this.state.benefit?.type === config.TYPES.PRIZE ? "benefit_prize_created" : "benefit_created",
      locale: "es",
      to: this.props.user.email,
      first_name: this.props.user.first_name,
      last_name: this.props.user.last_name,
      params: {
        // subject,
        benefit_name: this.props.benefit.item.name,
        benefit_id: this.props.benefit.item.id,
        user_name: `${this.props.user.first_name} ${this.props.user.last_name}`,
      }
    }
    console.log({ mailData })
    await this.props.onSendMail(mailData);
    const internals = this.props.internals;
    if (internals.error) {
      notify(this.t(internals.error.message));
    }
  }

  async saveChallenge() {
    let transaction_data = {}
    if (this.state.challenge?.json_data.benefits?.benefit == 'credits') {
      transaction_data = {
        type: "credits",
        sub_type: "credits",
        source: this.props.user.unique_id,
        target: this.props.user.id, //winner.user.id,
        owner: this.props.user.unique_id,
        status: "paid",
        untaxed_amount: this.state.challenge?.json_data.benefits?.quantity, //prize.quantity,
        total: this.state.challenge?.json_data.benefits?.quantity, //prize.quantity,
        reference: this.state.challenge?.json_data.benefits?.id,//challenge_obj.id,
        currency: "ARS",
        json_data: {
          reason: `Premio de Desafío: ${this.state.challenge.name}`,
          winner: this.props.user.id,
        }
      }
    } else {
      if (this.state.challenge?.json_data.benefits?.benefit == 'coins') {
        transaction_data = {
          type: 'challenge',
          sub_type: "coins",
          source: this.props.user.unique_id,
          target: this.props.user.id,
          owner: this.props.user.unique_id,
          status: "completed",
          json_data: {
            coins: this.state.challenge.json_data.benefits.quantity,
            challenge: this.state.challenge
            // prize: benefit,
          }
        }
      } else {
        transaction_data = {
          type: "challenge",
          sub_type: "prize",
          source: this.props.user.unique_id,
          target: this.props.user.id, //winner.user.id,
          owner: this.props.user.unique_id,
          status: "completed",
          json_data: {
            coins: 0,
            challenge: this.state.challenge,
            prize:{
              // Kthis.state.challenge.json_data.benefits
              "challenge":this.state.challenge.id,
              "benefit":this.state.idBenefit,
              "order_number": 2,
              "quantity": null
            } 
              
          }
        }
      }
    }
    await this.props.onSaveTransaction(transaction_data)
    const error = this.props.transaction.error
    if (error) {
      notify(error)
    } else {
      const isPrize = this.state.flow.challenge?.json_data?.benefits.quantity == null &&
      this.state.flow.challenge?.json_data?.benefits.benefit != 'credits' &&
      this.state.flow.challenge?.json_data?.benefits.benefit != 'coins' ? true : false
      isPrize&& await this.saveTransactionPrize()
      await this.OnUpdateUserTour()
    }
  }
  getSetting = async () => {
    const params={
      "type": "sharyco_settings",
      "owner": this.props.user.unique_id,
       "code": "prize_by_company"
      }
    await this.props.onGetSetting(params);
    const { settings } = this.props;
    if (settings.error) {
      notify(this.t(settings.error.message));
    } else {
      if(settings?.items?.length>0){
      const idBenefit=settings?.items[0]?.value
      if(idBenefit){
      await  this.getBenefit(idBenefit) 
        this.setState({ idBenefit});
      } 
      }
    }
  }
  // Sirve para controlar cuales flows va realizando el usuario 
  async OnUpdateUserTour() {
    const arraysFlows = config.ACTION_BY_DEFAULT
    let stringFlow = ""
    if (!this.props.user.tour) {
      const positionFlow = arraysFlows.findIndex(flow => flow.id === this.idFlow);

      arraysFlows.forEach((_, i) => {
        if (i <= positionFlow) {
          stringFlow = stringFlow.concat(new Date())
        } else {
          stringFlow = stringFlow.concat(String(0))
        }
        if (arraysFlows.length - 1 > i) stringFlow = stringFlow.concat(',')
      })
    } else {
      this.props.user.tour.split(',').forEach((_, i) => {
        const positionFlow = arraysFlows.findIndex(flow => flow.id === this.idFlow);
        console.log({ positionFlow });
        if (i <= positionFlow) {
          stringFlow = stringFlow.concat(new Date())
        } else {
          stringFlow = stringFlow.concat(String(0))
        }
        if (this.props.user.tour.split(',').length - 1 > i) stringFlow = stringFlow.concat(',')
      })
    }

    const data = {
      id: this.props.user.id,
      tour: stringFlow
    }
    console.log(data);
    await this.props.onSaveOrUpdatedUser(data)
    const error = this.props.userUpdate.error
    if (error) {
      notify(error)
    }
  }

  componentDidMount() {
    this.getFlow();
  }

  componentDidUpdate(prevProps, prevState) {
    if ((prevState.action !== this.state.action) && this.state.actionAvailable) {
      this.saveChallenge()
    }
  }

  render() {
    const stepNumber = this.idFlow;
    const ordinalMap = {
      1: this.t("primera"),
      2: this.t("segunda"),
      3: this.t("tercera"),
      4: this.t("cuarta"),
    };
    const ordinalStep = ordinalMap[stepNumber];

    return (
      <main className="h-full text-center">
      {this.props.action?.loading&&<Loader/>}
      {this.state.action&&
          <CardOnboarding>
            <div className="h-80 md:h-96 flex items-center">
              <img src={imgCelebration} alt="" width={"250px"} />
            </div>
            <div>
              <h1 className="text-primary mb-2 text-2xl">{this.t("Felicidades")}</h1>
              <p className="text-primary mb-10 text-lg">{this.t(`Completaste tu ${ordinalStep} acción`)}</p>
              <h3 className="mb-4 text-lg">¡Ganaste!</h3>
              {this.state.action?.json_data.points && this.state.action?.json_data.coins && <div className="grid grid-cols-2 gap-4 mb-10">
                <IconCoins iconClassName="h-10 text-success" color='' className="bg-success bg-opacity-10 py-4 px-2 flex flex-col items-center justify-center gap-0 rounded" coins={this.state.action?.json_data.coins} leyend={'Pts Shary'} numberClassName={'text-base-content text-2xl font-bold mt-1'} />
                <IconPoints iconClassName="h-8 my-1 text-success" color='' className="bg-success bg-opacity-10 py-4 px-2 flex flex-col items-center justify-center gap-0 rounded" points={this.state.action?.json_data.points} leyend={'Pts Impacto'} numberClassName={'text-base-content text-2xl font-bold mt-1'} />
              </div>}

            </div>
            <Button
              className="btn-block btn-primary md:mt-5 max-w-md"
              title={this.t("Siguiente").toUpperCase()}
              onClick={() => history.push(`/flow/${this.idFlow}/step5`)}
            />
          </CardOnboarding>
        }
      </main>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.users.auth.user,
    userUpdate: state.users.current,
    action: state.actions.current,
    transaction: state.transactions.current,
    transactions: state.transactions.list,
    settings: state.settings.list,
    benefit: state.benefits.current,
    internals: state.internals,

  };
};
const mapDispatchToProps = dispatch => {
  return {
    onGetAction: (params) => dispatch(actions.get(params)),
    onSaveTransaction: (params) => dispatch(actionTransactions.saveOrUpdate(params)),
    onGetTransactions: (params) => dispatch(actionTransactions.getAll(params)),
    onSaveOrUpdatedUser: (params) => dispatch(actionsUser.saveOrUpdate(params)),
    onGetBenefit: (params) => dispatch(benefitsActions.get(params)),
    onSendMail: (params) => dispatch(internalsActions.sendMail(params)),
    onGetSetting:(params)=>dispatch(settingActions.getAll(params))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(FlowStep3));

