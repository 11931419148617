import React, { memo } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import clsx from 'clsx';
import { NavLink, useParams, withRouter } from 'react-router-dom';
import { notify } from '../../libraries/notifications';

import { history } from '../../routes';
import config from '../../config';
import challengesActions from '../../context/challenges/actions'

import LayoutSmall from '../../components/layout/LayoutSmall'

import { ReactComponent as Trofeo } from '../../assets/icons/customs/trofeo.svg';
import CompletedBar from '../../components/customs/CompletedBar';
import { strToDateWithoutTZ } from '../../libraries/utils';
import LayoutResponsive from '../../components/layout/LayoutResponsive';

// TODO: agregar desjoinear challenge

class ChallengesWrapper extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      joined: false,
      challenge: {},
      participants: [],
      loading: true,
      user: {},
      finished: false,
      visibleTop: true,
    };
  }

  async componentDidMount() {
    const { id } = this.props.match.params;
    const { challenge, history } = this.props;

    if (!challenge?.item || challenge?.item?.id !== id) {
      await this.props.onGetChallenge(id);
      await this.props.onGetAllParticipants(id);
      this.setState({
        challenge: this.props.challenge?.item,
        participants: this.props.participants,
      });
    }

    await this.checkIsJoined();

    if (!this.state.joined) {
      history.push(`/challenges/${id}/info`);
    }
    this.checkCompleted();
    this.setState({ loading: false });
    
    document.getElementById("layout-small").addEventListener("scroll", (e) => {
      this.setState({ visibleTop: e.target.scrollTop <= 150 });
    });
  }

  // componentWillUnmount() {    
  //   document
  //     .getElementById("layout-small")
  //     .removeEventListener("scroll", () => {
  //       this.setState({ visibleTop: window.scrollY <= 200 });
  //     });
  // }

  async checkIsJoined() {
    const participants = this.props.participants
    const user = this.props.auth.user

    const participant = participants.find(p => p.participant?.id === user.id)
    this.setState({ joined: (participant ? true : false) })
  }

  checkCompleted = () => {
    const challenge = this.props.challenge.item
    if(challenge.end_date) {
      const today = new Date()
      const endDate =  strToDateWithoutTZ(challenge?.end_date)
      const finished =  (endDate <= today)
      
      this.setState({ finished })
    }
  }


  leaveChallenge = async  () => {
    const { id } = this.props.match.params
    const { user } = this.props.auth
    
    await this.props.leaveChallenge({challenge_id: id, participant: user.id })
    if (this.props.challenge?.error) {
      notify(this.props.challenge?.error.message)
      return
    }
    await this.props.onGetAllParticipants(id)
    this.setState({ joined: false, participants: this.props.participants  })
  }

  goToBack = () => {
    history.push(config.ROUTES.HOME_OPTIONS.CHALLENGES);
  }

  render() {
    const {  joined, finished, loading, visibleTop } = this.state
    const { item: challenge, participants } = this.props.challenge
    
    let itStarted = (strToDateWithoutTZ(challenge?.start_date) <= new Date()) && challenge?.status !== 'not_started'

    return (
      <LayoutResponsive
        main={{ className: "" }}
        // header={{
        //   className: `bg-gradient-to-b from-primary-focus to-transparent bg-transparent fixed text-primary z-10`,
        //   left: { icon: "arrow_left", action: this.goToBack },
        // }}
        container={{ className: "px-0" }}
        // loading={}
      >
        <div className="flex flex-col items-center">
          <div className="relative h-48 w-full bg-white">
            <img
              src={challenge?.image}
              alt=""
              className="w-full h-full object-cover "
            />
            <BubbleAvatar users={participants?.participants} link={`${config.ROUTES.CHALLENGES.INFO.replace(":id",challenge?.id )}`} />
            <div
              className={clsx(
                `absolute top-[0px] w-full h-full  `,
                finished
                  ? "bg-secondary bg-opacity-50"
                  : "bg-gradient-to-b from-success bg-opacity-50"
              )}
            >
              <div className='relative h-full flex justify-center items-center'>
                { finished && <Trofeo fill="white" className='w-auto h-1/2' />}
              </div>
              <CompletedBar start={challenge?.start_date} end={challenge?.end_date} finished={finished} />
            </div>

            
          </div>

          <div className="w-full flex flex-col items-center bg-white">
            <div className="w-full px-4">
              <h3 className="pt-3 pb-2" >{challenge?.name} </h3>
              <Menu userJoined={joined} challengeFinished={finished} />
            </div>
          </div>
          <div className="w-full p-4">{this.props.children}</div>
        </div>
      </LayoutResponsive>
    )
  }
}

const BubbleAvatar = memo(({ users = [], link }) => {

  const hasUsers = users?.length > 0;
  const showCount = hasUsers && users?.length > 3;
  const visibleUsers = users?.length ? users.slice(0, 3) : [];

  return (
    <div className="absolute top-5 right-4 flex items-center gap-4 z-20">
      {hasUsers && users?.length && (
        <>
          {showCount && (
            <span className="text-white">{`${users.length - 3}+`}</span>
          )}
          <div className="avatar-group -space-x-2 " key="avatar-group">
            {visibleUsers.map((user) => (
              <AvatarImage
                key={user?.id}
                profileImage={user.participant?.profile_image}
                alt={`Avatar ${user.participant?.name}`}
                initial={user.participant?.name.at(0)}
              />                
            ))}
          </div>
        </>
      )}
      {/* <Link to={link} target='_blank'>
        <Share className='w-5 h-5' />
      </Link> */}
    </div>
  );
})

function AvatarImage({ profileImage, alt, initial }) {
  const [loaded, setLoaded] = React.useState(false);

  return (
    <div className="avatar border-0" >
      <div className="relative w-6">
        <img
          ref={(r) => {
            if (r) {
              r.onload = () => setLoaded(true);
              r.src = profileImage;
            }
          }}
          alt={alt}
          className={!loaded ? "opacity-0" : ""}
        />
        {!loaded && <p className="absolute top-0 left-0 w-full h-full bg-green-600 text-gray-100 flex justify-center items-center">{initial?.toUpperCase()}</p>}
      </div>
    </div>
  );
}


const Menu = memo(({ userJoined, challengeFinished }) => {
  const { id } = useParams()
  const menu = [
    { name: 'Info', route: `/challenges/${id}/info`, showIfNotJoined: true },
    { name: 'Actividad', route: `/challenges/${id}/activity`  },
    { name: 'Impacto', route: `/challenges/${id}/impact` },
    { name: 'Ranking', route: `/challenges/${id}/leaderboard` },
  ]
  if (userJoined) {
    menu.push(menu.shift())
  }
    
  return (
    <ul className="flex justify-between overflow-x-hidden gap-4 py-3 pb-0 capitalize">
      {menu.map((link, i) => {
        //if(!link.showIfNotJoined && !userJoined) return null
        return (
        <li key={link.name + i} className="flex items-center  max-w-full" >
          <NavLink
            to={link.route}
            className={isActive =>
              clsx({
                "pointer-events-none text-gray-200": !userJoined && !link.showIfNotJoined,
                "pt-3 py-1 px-1 border-b-4 border-transparent": !isActive,
                "pt-3 py-1 px-1 border-b-4 border-secondary": isActive,
              })
            }
          >
            {link.name}
          </NavLink>
        </li>
      )})}
    </ul>
  )
})





const mapStateToProps = state => {
  return {
    auth: state.users.auth,
    challenge: state.challenges.current,
    participants: state.challenges.current.participants?.participants,

    // participant: state.participants.current,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onGetChallenge: (params) => dispatch(challengesActions.get(params)),
    onGetAllParticipants: (params) => dispatch(challengesActions.getParticipants(params)),
    leaveChallenge: (params) => dispatch(challengesActions.leave(params)),
    clearChallenge: () => dispatch(challengesActions.clearCurrent()),

    // onSaveOrUpdateParticipant: (params) => dispatch(actionParticipants.saveOrUpdate(params)),

    // onSaveOrUpdate: (params) => dispatch(challengesActions.saveOrUpdate(params)),

  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ChallengesWrapper)))
