import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { ToastContainer, notify } from '../../libraries/notifications';
import config from '../../config';
import { history } from '../../routes';
import { getOwner } from '../../libraries/utils';

import BenefitListCard from '../../components/customs/BenefitListCard';
import LayoutSmall from '../../components/layout/LayoutSmall'
import BottomNav from '../../components/commons/BottomNav';
import benefitsActions from '../../context/benefits/actions';
import Loader from '../../components/commons/Loader';
import categoriesAction from '../../context/categories/actions';
import businessesActions from '../../context/businesses/actions';
import SearchInput from "../../components/forms/SearchInput";
import { Form, Field } from "react-final-form";
import actions from '../../context/users/actions';
import LayoutResponsive from '../../components/layout/LayoutResponsive';

class Benefits extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      benefits: [],
      loading: true,
    }
  }
  
  componentDidMount() {
    this.setState({
      showCategories: true,
      fromPath: config.ROUTES.MAIN.BENEFITS,
      userLevel: this.props.auth.user.rewards.level
    })

    this.getCreditBalance()
    this.getBenefits()
  }

  getCreditBalance = async (id) => {
    await this.props.onGetBalance({unique_id: this.props.auth.user.unique_id, user_id: this.props.auth.user.id});
    if (this.props.user.error) {
      return notify(this.t(this.props.user.error.message));
    }
    const userBalance = this.props.user.balance?.credits.balance || 0
    this.setState({ creditBalance: userBalance })
  }

  goBack = () => {
    history.push({ pathname: config.ROUTES.HOME, state: { from: this.state.fromPath }});
  }

  getBusinesses = async () => {
    const params = {enabled: 1}
    await this.props.onGetAllBusinesses(params);
    const { businesses } = this.props;
    if (businesses.error) {
      notify(this.t(businesses.error.message));
    } else {
      this.setState({ businesses: businesses.items });
    }
  }

  getBenefits = async (params) => {
    await this.getBusinesses()
    //const params = { page_num };
    const { search } = this.state;
    if (search && search !== '') {
      //params = {...this.state.getBenefitsParams}
      //params.name = search
      //params.where = { json_data: [] };
      //if (search && search !== '') {
      //  params.where = { custom: { name: `%${search.toLowerCase()}` } };
      //}
    }
    await this.props.onGetAllBenefits({...params, enabled: 1 , type: ["maslow"],code:this.props.auth.user?.location ||'argentina'});
    const { benefits } = this.props;
    if (benefits.error) {
      notify(this.t(benefits.error.message));
    } else {
      let benefits_ = benefits.items.filter(b => this.state.filters ? (b.json_data?.category === this.state.filters?.type) : true)
      if (search && search !== '') {
        benefits_ = benefits_.filter(b => b.name.toLowerCase().includes(search.toLowerCase()))
      }

      for(let i = 0; i < benefits_.length; i++){
        let business = this.state.businesses?.find(b => b.id === benefits_[i].json_data?.business?.id)
        if(business){
          benefits_[i].updatedBusiness = {...business}
        }
      }

      this.setState({ benefits: benefits_, loading: false });

    }
  };

  //TODO: implement categories for maslow
  // getCategories = async (next) => {
  //   await this.props.onGetAllCategories({ type: "benefits", order_by: 'order_index', order_direction: 'ASC', type: "maslow" });
  //   const { categories } = this.props;
  //   if (categories.error) {
  //     notify(this.t(categories.error.message));
  //   } else {
  //     this.setState({ categories: categories.items, filters: null });
  //   }
  // }

  // setFilter = (type) => {
  //   console.log("type", type)
  //   if(this.state.filters?.type === type){
  //     this.setState({filters: null}, () => this.getBenefits())
  //   } else {
  //     this.setState({ filters: { type }}, () => this.getBenefits())
  //   }
  // }

  searchClear = (form) => {
    form.change('search', undefined);
    this.searchOnClick({search: ''});
  }

  searchOnClick = async (values) => {
    if (this.state.search !== values.search) this.setState({ search: values.search || '' }, () => this.getBenefits() );
  };


  render() {
    const { benefits, creditBalance, loading }  = this.state
    return (
      <LayoutResponsive
        main={{ className: ""}}
        header={{ 
          title: "Beneficios",
          className: "bg-white text-primary flex justify-between p-3",
          left: { icon: 'arrow_left', action: this.goBack }
        }}
        container={{ className: "px-0"}}
        loading={loading}
      > 
        <ToastContainer/>
        <div className="w-full mx-auto p-3 pb-16">
          {creditBalance >= 0 && 
            <div role="alert" className={`alert ${ creditBalance > 0 ? 'bg-success bg-opacity-60' : 'bg-error' } mb-3`} >
              {creditBalance > 0 && <span className='text-white font-bold'>{`Tienes disponibles ${creditBalance} créditos`}</span>}
              {creditBalance === 0 && <span className='text-white font-bold'>{`No tienes créditos disponibles`}</span>}
            </div>
          }
          <div>
            <Form initialValues={{}} onSubmit={this.searchOnClick}>
              {({ handleSubmit, form, submitting, pristine, values }) => (
                <form onSubmit={handleSubmit} className="w-full mb-4">
                  <Field name="search" component={SearchInput} placeholder={this.t("Search")} onClick={this.searchOnClick} onClearClick={() => this.searchClear(form) }/>
                </form>
              )}
            </Form>
          </div>
          <section className="">
            {/* {this.state.categories && this.state.showCategories && <div className="scrolling-wrapper mb-4">
              {this.state.categories.map((category,index) => {
                return(
                  <div
                    key={index}
                    className={"card white py-2 px-4 mb-4 w-22 mr-4 border-2 border-transparent " + (category.id===this.state.filters?.type ? " border-primary text-primary" : " text-gray-400") + ""}
                    onClick={() => this.setFilter(category.id)}
                  >
                    <p>{this.t(category.name)}</p>
                  </div>
                );
              })}
            </div>} */}

            <div className="grid grid-cols-2 gap-4 mt-2">
              {benefits && benefits.map((benefit, index) => {
                  return (
                    <BenefitListCard 
                      key={index}
                      benefit={benefit}
                      business={benefit.updatedBusiness}
                      userLevel={this.state.userLevel}
                      route={{ pathname: config.ROUTES.BENEFITS.DETAIL.replace(':id', benefit.id), state: { fromPath: config.ROUTES.BENEFITS.LIST }}}
                    />
                  )
                })
              }
            </div>
          </section>
        </div>
        {/* <BottomNav/> */}
      </LayoutResponsive>        
    ) 
  }
}

const mapStateToProps = state => {
  return {
    auth: state.users.auth,
    benefits: state.benefits.list,
    categories: state.categories.list,
    businesses: state.businesses.list,
    credits: state?.users?.current?.balance?.credits?.balance,
    user: state.users.current,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetAllBenefits: params => dispatch(benefitsActions.getAll(params)),
    onGetAllCategories: (params) => dispatch(categoriesAction.getAll(params)),
    onGetAllBusinesses: (params) => dispatch(businessesActions.getAll(params)),
    onGetBalance: (params) => dispatch(actions.getBalance(params)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(Benefits));
