import React from "react";
import { withTranslation } from "react-i18next";
import { history } from "../../routes";
import config from "../../config";

import LayoutSmall from "../../components/layout/LayoutSmall";
import Button from "../../components/commons/Button";
import { capitalize } from "../../libraries/utils";
import LayoutResponsiveWSmall from "../../components/layout/LayoutResponsiveWSmall";

class InfoTerms extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {};
  }

  componentDidMount() {
    console.log("TERMS PAGE");
  }
  toPrivacy = () => {
    history.push({
      pathname: config.ROUTES.PRIVACY,
      state: {
        fromPath: config.ROUTES.TERMS,
      }
    })
  }

  render() {
    return (
      <LayoutResponsiveWSmall
        header={{
          className: "bg-primary",
          title: capitalize(this.t("terms and conditions")),
          left: { icon: "arrow_left", action: () => history.push(config.ROUTES.USER.SETTINGS) },
        }}
        main={{
          className: "light-primary-bg text-gray-700",
        }}
        showGoBackButton={true}
        onGoBackClick={() => history.push(config.ROUTES.USER.SETTINGS)}
      >
        <h2>TERMINOS Y CONDICIONES DE SHARYCO</h2><br />

        <h3>I)  TERMINOS Y CONDICIONES GENERALES</h3><br />

        1)ACEPTACIÓN CONDICIONES<br /><br />
        Las presentes cláusulas (los “Términos y Condiciones”) regulan los términos y condiciones generales de uso de los servicios proporcionados por la aplicación SHARYCO (en adelante, la “Aplicación”), de propiedad de SHARYCO S.A., CUIT 30-71726641-9 (en adelante, la “Empresa”), con domicilio en Avenida Corrientes 351, de la Ciudad Autónoma de Buenos Aires. Cualquier persona (en adelante "Usuario", o en plural "Usuarios") que desee acceder y/o usar la aplicación SHARYCO podrá hacerlo sujetándose a los Términos y Condiciones, junto con todas las demás políticas y principios que rigen su uso y que son incorporados al presente por referencia.<br /><br />
        CUALQUIER PERSONA QUE NO ACEPTE ESTOS TÉRMINOS Y CONDICIONES, LOS CUALES TIENEN UN CARÁCTER OBLIGATORIO Y VINCULANTE, DEBERÁ ABSTENERSE DE UTILIZAR LA APLICACIÓN.<br /><br />
        El Usuario debe leer, entender y aceptar todas las condiciones establecidas en los Términos y Condiciones y en los demás documentos incorporados a los mismos por referencia, previo a su registración como Usuario de la Aplicación, optando de forma expresa por recibir los mismos y toda otra información por medios digitales y/o correo electrónico.
        Mediante la aceptación de los presentes Términos y Condiciones usted entiende y acepta que los mismos son jurídicamente vinculantes, y establecen y enmarcan la relación contractual entre usted y la Empresa. <br /><br />

        2) CAPACIDAD<br />
        Los servicios proporcionados por la Aplicación sólo están disponibles para personas que tengan capacidad legal para contratar. No podrán utilizar los servicios las personas que no tengan esa capacidad, los menores de edad o Usuarios que hayan sido suspendidos temporalmente o inhabilitados definitivamente. Si estás registrando un Usuario como empresa, debes tener capacidad para contratar a nombre de tal entidad y de obligar a la misma en los términos de este Acuerdo.<br /><br />

        3)REGISTRACIÓN<br />
        Es obligatorio completar el formulario de registración en todos sus campos con datos válidos para poder utilizar los servicios que brinda la Aplicación. El futuro Usuario deberá completarlo con su información personal de manera exacta, precisa y verdadera ("Datos Personales") y asume el compromiso de actualizar los Datos Personales conforme resulte necesario. El Usuario presta expresa conformidad con que la Empresa utilice diversos medios para identificar sus datos personales, asumiendo el Usuario la obligación de revisarlos y mantenerlos actualizados. La Empresa NO se responsabiliza por la certeza de los Datos Personales de los Usuarios. Los Usuarios garantizan y responden, en cualquier caso, de la veracidad, exactitud, vigencia y autenticidad de sus Datos Personales.<br />
        El Usuario accederá a su cuenta personal ("Cuenta") mediante el ingreso de su correo electrónico y clave de seguridad personal elegida ("Clave de Seguridad"). El Usuario se obliga a mantener la confidencialidad de su Clave de Seguridad.
        La Cuenta es personal, única e intransferible, y está prohibido que un mismo Usuario registre o posea más de una Cuenta. En caso que la Empresa detecte distintas Cuentas que contengan datos coincidentes o relacionados, podrá cancelar, suspender o inhabilitar todas o alguna de ellas.
        El Usuario será responsable por todas las operaciones efectuadas en su Cuenta, pues el acceso a la misma está restringido al ingreso y uso de su Clave de Seguridad, de conocimiento exclusivo del Usuario. El Usuario se compromete a notificar a la Empresa en forma inmediata y por medio idóneo y fehaciente, cualquier uso no autorizado de su Cuenta, así como el ingreso por terceros no autorizados a la misma. Se aclara que está prohibida la venta, cesión o transferencia de la Cuenta bajo ningún título.<br />
        No podrá utilizarse la Aplicación de manera que: (i) en forma alguna cause, o pueda causar, daño o perjuicio alguno a cualquiera de los Usuarios y contenidos de la Aplicación o la interrupción del acceso a la misma; o (ii) para cualquier fin fraudulento, ni a efectos de la comisión de delito alguno u otra actividad ilícita de ningún otro tipo; o (iii) para generar cualquier tipo de molestia, inconveniente o ansiedad en un tercero.<br />
        La Empresa se reserva el derecho de rechazar cualquier solicitud de registración o de cancelar una registración previamente aceptada, sin que esté obligado a comunicar o exponer las razones de su decisión y sin que ello genere algún derecho a indemnización o resarcimiento.<br /><br />


        4)DEL SERVICIO PRESTADO<br />
        En los términos de este contrato, se considera Usuario a los Usuarios que ofrezcan un producto o un servicio, y a los Usuarios que acepten el producto o servicio. Cada uno de ellos actúa en interés propio y por su propia cuenta y orden, siendo ellos quienes acuerdan la celebración y condiciones del intercambio y tienen a su exclusivo cargo el cumplimiento de las obligaciones asumidas. La Empresa sólo pone a disposición de los Usuarios un espacio virtual que les permite comunicarse mediante Internet, posibilitando el intercambio. La Empresa no es parte de ese contrato, no asume ninguna responsabilidad relacionada con el mismo, ni actúa como representante del Usuario. Los Usuarios son responsables por la oferta de sus productos y servicios, así como de ofrecer asistencia en cuanto a los reclamos de los Usuarios, o con respecto a cualquier asunto relacionado con dicho intercambio.
        Este acuerdo no crea ningún contrato de sociedad, de mandato, de franquicia, o relación laboral entre la Empresa y el Usuario. El Usuario reconoce y acepta que la Empresa no es parte en ninguna operación, ni tiene control alguno sobre la calidad, seguridad o legalidad de los artículos, servicios o actividades anunciadas, la veracidad o exactitud de las publicaciones, la capacidad de los Usuarios para ofrecer sus productos y servicios. La Empresa no puede asegurar que un Usuario completará una operación ni podrá verificar la veracidad de las publicaciones. La Empresa no garantiza la veracidad de la publicidad de terceros que aparezca en el sitio y no será responsable por la correspondencia o contratos que el Usuario celebre con dichos terceros o con otros Usuarios.<br /><br />

        5)PUBLICACIÓN<br />

        A)  PRODUCTOS Y SERVICIOS<br />
        Se considera Producto cualquier bien que ofrezca el Usuario, y Servicio cualquier obligación de hacer. Una vez que el Usuario acepta intercambiar un Producto o Servicio  publicado en la Aplicación, el Usuario  que ofrece el Producto o Servicio se obliga a no modificar la cantidad de puntos Shary que solicita a cambio del Producto o Servicio.<br />
        En caso que la Empresa detecte que por algún medio el Usuario se encuentra violando las obligaciones anteriormente mencionadas, podrá suspender temporal o permanente su Cuenta.<br />

        B)   DESCRIPCIÓN<br />
        La publicación del Producto o Servicio debe contener, como mínimo, la siguiente información:<br />

        i)  TÍTULO O NOMBRE <br />
        Tiene que ser un nombre o título representativo del Producto o Servicio. Un mismo Usuario no puede ofrecer dos productos publicados con idéntico título o nombre.<br />

        ii) PUNTOS SHARY <br />
        El monto solicitado por el Producto o Servicio debe expresarse en Puntos Shary. La Aplicación sugerirá al Usuario un rango recomendable para fijar la cantidad de Puntos Shary pero el Usuario es libre de solicitar la cantidad que desee.
        Una vez aceptado el intercambio, la cantidad de Puntos Shary requeridos no podrá ser modificada. <br />

        iii) DESCRIPCIÓN<br />
        El Producto o Servicio debe detallar con exactitud sus características y detalles relevantes, como ser la cantidad de unidades, características, talle, color, si tiene algún daño y demás información pertinente.<br />
        Las publicaciones podrán incluir textos descriptivos, gráficos, fotografías, videos, audios, documentación, ficha técnica y otros contenidos y condiciones pertinentes siempre que no violen ninguna disposición de estos Términos y Condiciones o demás políticas de la Empresa.
        Se entiende y presume que el Usuario es propietario del Producto ofrecido y que tiene la intención y el derecho de disponer del mismo (o que está facultado para ello por su propietario) y que lo tiene disponible para su despacho inmediato. <br /><br />

        6) SUSPENSIÓN DE LA PUBLICACIÓN DE UN PRODUCTO  <br />

        En caso que un producto, por cualquier razón, no esté en condiciones de ser intercambiado, el Usuario deberá suspender su publicación, hasta tanto reponga el producto. La publicación que fuera suspendida, podrá ser rehabilitada asignando una nueva descripción, fotos, etc. Mientras el producto se encuentre disponible, las publicaciones pueden ser activadas y desactivadas en cualquier momento antes de que el Usuario que ofrece el producto o servicio haya aceptado el intercambio. <br />

        Se deja expresamente aclarado que los intercambios de productos y servicios no son operaciones de compraventa por lo tanto no resultan aplicables las leyes o protecciones relativas al consumidor.  <br /><br />

        7)RESTRICCIÓN PRODUCTOS<br />
        Dentro de la plataforma no se puede ofrecer los siguientes productos:<br />
        Alimentos;<br />
        Cosméticos;<br />
        Vinos y bebidas alcohólicas;<br />
        Tabacos;<br />
        Medicamentos;<br />
        Drogas, narcóticos  o estupefacientes prohibidos;<br />
        Armas de fuego o cualquier clase de dispositivo, agresivos químicos de toda naturaleza y demás materiales que se clasifiquen como armas de guerra, pólvoras, explosivos y afines, y armas, municiones y demás materiales clasificados de uso civil; materias explosivas, inflamables o radiactivas y mercaderías y/o residuos peligrosos.<br />
        trofeos de caza (animal), animales vivos, partes de animal como marfil y aleta de tiburón, restos animales, o subproductos animales;<br />
        Restos humanos o cenizas;<br />
        Mercaderías falsificadas;<br />
        Lingotes (de cualquier metal precioso); <br />
        Dinero en efectivo (moneda legal vigente); <br />
        Piedras preciosas y cualquier producto cuyo transporte haya sido prohibido por algún reglamento, ley o estatuto correspondiente a cualquier gobierno federal estatal o local.<br />
        14) 		Ni cualquier otro producto cuya comercialización estuviera prohibida o restringida de acuerdo con la legislación vigente en la República Argentina.<br /><br />

        8)PRIVACIDAD DE LA INFORMACIÓN<br />
        Para utilizar la Aplicación de la Empresa, los Usuarios deberán facilitar determinados datos de carácter personal. Su información personal se procesa y almacena en servidores o medios magnéticos que mantienen altos estándares de seguridad y protección tanto física como tecnológica. Para mayor información sobre la privacidad de los Datos Personales y casos en los que será revelada la información personal, se pueden consultar nuestras <span onClick={() => this.toPrivacy()} className="hover:cursor-pointer font-extrabold" >Políticas de Privacidad.</span><br /><br />

        9)DERECHOS DE AUTOR, PROPIEDAD INTELECTUAL Y SOBRE BASES DE DATOS. MARCAS REGISTRADAS. PATENTES. ENLACES.<br />
        Los contenidos de las pantallas de la Aplicación como así también los programas, bases de datos, redes, archivos que permiten al Usuario acceder y usar su Cuenta, son de propiedad de la Empresa y están protegidas por las leyes y los tratados internacionales de derecho de autor, marcas, patentes, modelos, diseños industriales y sobre base de datos. El uso indebido y la reproducción total o parcial de dichos contenidos quedan prohibidos, salvo autorización expresa y por escrito de la Empresa.
        Todo contenido alojado o puesto a disposición en la Aplicación, como por ejemplo texto, gráficos, logotipos, iconos de botones, imágenes, clips de audio, descargas digitales, y recopilaciones de datos son propiedad de la Empresa o de sus proveedores de contenido y está protegido por las leyes y los tratados internacionales de derecho de autor, marcas, patentes, modelos, diseños industriales y sobre base de datos. El conjunto de todo el contenido albergado o puesto a disposición a través de la Aplicación es propiedad exclusiva de la Empresa, y está protegido por las leyes y los tratados internacionales de derecho de autor, marcas, patentes, modelos, diseños industriales y sobre base de dato.<br />
        No está permitida la extracción sistemática ni la reutilización de parte alguna del contenido de la Aplicación sin nuestro expreso consentimiento por escrito. En particular, no se permite el uso de herramientas o robots de búsqueda y extracción de datos para la extracción (ya sea en una o varias ocasiones) de partes sustanciales de la Aplicación para su reutilización sin nuestro expreso consentimiento por escrito. Tampoco le está permitido al Usuario crear ni publicar sus propias bases de datos cuando éstas contengan partes sustanciales de cualquiera de los contenidos de la Aplicación (por ejemplo, nuestras listas de productos) sin nuestro expreso consentimiento por escrito.<br />
        Los gráficos, logotipos, encabezados de página, iconos de botón, scripts y nombres de servicio que aparecen incluidos o están disponibles a través de la Aplicación son marcas registradas o representan la imagen comercial de la Empresa. No podrán utilizarse las marcas registradas ni la imagen comercial de la Empresa, ni en ninguna forma que fuera susceptible de causar confusión entre los usuarios o que pueda menospreciar o desacreditar a la Empresa. El resto de marcas registradas que no son propiedad de la Empresa y que aparecen incluidos o están disponibles a través de la Aplicación pertenecen a sus respectivos propietarios.<br />
        Sujeto al cumplimiento por Ud. de estas Condiciones de Uso y las Condiciones Generales, la Empresa o sus proveedores de contenidos le conceden una licencia limitada no exclusiva, no transferible y no sub-licenciable, de acceso y utilización, de la Aplicación para fines personales no comerciales. Dicha licencia no incluye derecho alguno de reventa ni de uso comercial de la Aplicación ni de sus contenidos, ni derecho alguno a compilar ni utilizar lista alguna de productos, descripciones o precios. Tampoco incluye el derecho a realizar ningún uso derivado de la Aplicación ni de sus contenidos, ni a descargar o copiar información de cuenta alguna para el beneficio de otra empresa, ni el uso de herramientas o robots de búsqueda y extracción de datos o similar.<br />
        No está permitida la reproducción, duplicación, copia, venta, reventa o explotación de la Aplicación ni de parte alguna de la misma con fines comerciales, en cada caso sin previo consentimiento por escrito de la Empresa.<br />
        Tampoco está permitido utilizar técnicas de framing para introducir cualquier marca comercial, logotipo u otra información protegida por derechos de autor (incluyendo imágenes, texto, diseños de página o formatos) de la Empresa sin el correspondiente consentimiento previo por escrito. No está permitido el uso de metaetiquetas (meta tags) ni de ningún otro "texto oculto" que utilice los nombres o marcas registradas de la Empresa sin consentimiento previo y por escrito de la Empresa.
        La Aplicación puede contener enlaces a otros sitios web, lo cual no indica que sean propiedad u operados por la Empresa. En virtud que la Empresa no tiene control sobre tales sitios, NO será responsable por los contenidos, materiales, acciones y/o servicios prestados por los mismos, ni por daños o pérdidas ocasionadas por la utilización de los mismos, sean causadas directa o indirectamente. La presencia de enlaces a otros sitios web no implica una sociedad, relación, aprobación, respaldo de la Empresa a dichos sitios y sus contenidos.<br /><br />

        10)VIOLACIONES DEL SISTEMA O BASES DE DATOS<br />
        No está permitida ninguna acción o uso de dispositivo, software, u otro medio tendiente a interferir tanto en las actividades y operatoria de la Empresa como en las ofertas, descripciones, cuentas o bases de datos de la Empresa.  Cualquier intromisión, tentativa o actividad violatoria o contraria a las leyes sobre derecho de propiedad intelectual y/o a las prohibiciones estipuladas en este contrato harán pasible a su responsable de las acciones legales pertinentes, y a las sanciones previstas por este acuerdo, así como lo hará responsable de indemnizar los daños ocasionados.<br /><br />

        11) FALLAS EN EL SISTEMA<br />
        La Empresa no se responsabiliza por cualquier daño, perjuicio o pérdida al Usuario causados por fallas en el sistema, en el servidor o en Internet. La Empresa tampoco será responsable por cualquier virus que pudiera infectar el equipo del Usuario como consecuencia del acceso, uso o examen de su sitio web o a raíz de cualquier transferencia de datos, archivos, imágenes, textos, o audio contenidos en el mismo. Los Usuarios NO podrán imputarle responsabilidad alguna ni exigir pago por lucro cesante, en virtud de perjuicios resultantes de dificultades técnicas o fallas en los sistemas o en Internet. La Empresa no garantiza el acceso y uso continuado o ininterrumpido de su Aplicación. El sistema puede eventualmente no estar disponible debido a dificultades técnicas o fallas de Internet, o por cualquier otra circunstancia ajena a La Empresa; en tales casos se procurará restablecerlo con la mayor celeridad posible sin que por ello pueda imputársele algún tipo de responsabilidad. La Empresa no será responsable por ningún error u omisión contenidos en su sitio web.<br /><br />

        12)INDEMNIDAD<br />
        El Usuario mantendrá indemne a la Empresa, así como a sus filiales, empresas controladas y/o controlantes, funcionarios, directivos, sucesores, administradores, representantes y empleados, por cualquier reclamo iniciado por otros Usuarios, terceros o por cualquier Organismo, relacionado con sus actividades en la Aplicación, el cumplimiento y/o el incumplimiento de los Términos y Condiciones o demás Políticas, así como respecto de cualquier violación de leyes o derechos de terceros.<br />
        A tal fin, el Usuario faculta a la Empresa a intervenir y representarlo en dichos reclamos, pudiendo celebrar los acuerdos transaccionales que considere oportunos y que tiendan a evitar mayores costos y/o evitar eventuales contingencias sin limitación, en su nombre y representación.<br /><br />

        13)PROCEDIMIENTO Y AVISO DE VULNERACIÓN DE DERECHOS<br />
        Si el Usuario considera que cualquiera de sus derechos ha sido vulnerado, el mismo podrá comunicar dicha situación mediante correo electrónico a <a href="mailto:info@sharyco.com">info@sharyco.com</a>  a efectos de informar cualquier inquietud que pudieran tener en relación con cualquier supuesta infracción de sus derechos.
        Una vez recibido el correo electrónico, la Empresa podrá iniciar diversas actuaciones, que podrán incluir la supresión de la información, producto o servicio en cuestión, sin que ello implique en ningún caso admisión alguna de responsabilidad y sin perjuicio de cualquier derecho, acción o defensa que pudieran corresponder a la Empresa, todos los cuales quedan expresamente reservados. <br /><br />


        14)CONDICIONES DE USO ADICIONALES DEL SOFTWARE DE LA EMPRESA<br />
        a)	Uso del software de la Empresa. Los Usuarios podrán utilizar el Software de la Empresa única y exclusivamente para utilizar y disfrutar de la Aplicación, según permiten estos Términos y Condiciones. Los Usuarios no podrán separar ninguno de los componentes individuales del Software de la Empresa para usarlos en sus propios programas o compilar cualquier parte de ello junto con sus programas, ni transferirlo para su uso junto con otro servicio, ni podrá vender, alquilar, arrendar, prestar, distribuir ni otorgar sub-licencias ni ceder de cualquier otro modo ningún derecho del Software de la Empresa, ya sea en parte o en su totalidad. No puede utilizar el Software de la Empresa para un uso ilegal. La Empresa podrá anular la prestación del Software de la Empresa y denegarle al Usuario el derecho de uso del Software de la Empresa en cualquier momento. En caso de incumplimiento de estos Términos y Condiciones, los derechos de uso del Software la Empresa cesarán sin notificación previa. Existen condiciones adicionales contenidas o distribuidas junto con el Software de la Empresa, según se especifican en la documentación relacionada que son aplicables al Software de la Empresa (o software incorporado al Software de la Empresa) y que prevalecerán en el uso de dicho software en caso de conflicto con los presentes Términos y Condiciones. Todo el software es propiedad de la Empresa o sus proveedores de contenido software y está protegido por las leyes  internacionales sobre derechos de propiedad intelectual e industrial.<br />
        b)	Utilización de servicios de terceros. Cuando los Usuarios utilicen el Software de la Empresa, pueden también estar utilizando uno o varios servicios de terceros, como pueden ser los servicios de datos de un soporte o proveedor de plataformas móviles por utilización de las redes inalámbricas. El uso de dichos servicios prestados por terceros puede estar sujeto a políticas adicionales, condiciones de uso y tasas aplicables.<br />
        c)	Prohibición de realizar actos de ingeniería inversa. No está permitido realizar, ni tampoco incentivar, ayudar o autorizar a cualquier otra persona a, copiar, realizar actos de ingeniería in-versa, descompilar ni desensamblar, o de cualquier otra forma manipular, el Software de La Empresa, ya sea en parte o en su totalidad, ni crear obras derivadas desde o sobre el Software de La Empresa.<br />
        d)	Actualizaciones automáticas. A fin de mantener el Software de la Empresa actualizado, la Empresa podrá ofrecer, sin previa notificación al efecto, actualizaciones ocasionales automáticas o manuales.<br /><br />

        15)MODIFICACIÓN DEL ACUERDO<br />
        La Empresa podrá modificar los Términos y Condiciones en cualquier momento haciendo públicos en el Sitio los términos modificados. Todos los términos modificados entrarán en vigor a los 10 (diez) días de su publicación. Dichas modificaciones serán comunicadas por la Empresa a los Usuarios que en la Configuración de su Cuenta la Empresa hayan indicado que desean recibir notificaciones de los cambios en estos Términos y Condiciones. Todo Usuario que no esté de acuerdo con las modificaciones efectuadas por la Empresa podrá solicitar la baja de la cuenta.<br />
        El uso del sitio y/o sus servicios implica la aceptación de estos Términos y Condiciones de la Empresa.<br /><br />

        16)JURISDICCIÓN Y LEY APLICABLE<br />
        Este acuerdo estará regido en todos sus puntos por las leyes vigentes en la República Argentina.<br />
        Cualquier controversia derivada del presente acuerdo, su existencia, validez, interpretación, alcance o cumplimiento, será sometida al arbitraje del Centro Empresarial de Mediación y Arbitraje, de acuerdo con la reglamentación vigente de dicho tribunal. La sede de dicho arbitraje será la Ciudad Autónoma de Buenos Aires y los procedimientos se llevarán a cabo en idioma castellano.<br /><br />

        17)DOMICILIO<br />
        Se fija como domicilio de la Empresa la Av. Corrientes 531 piso 4, Capital Federal, código postal 1043, República Argentina.<br /><br />

        <h3>II) CONDICIONES DE USO</h3><br />

        1)PROHIBICIONES<br />
        Los Usuarios no podrán: (a) cambiar, una vez aceptada una interacción, la cantidad de puntos Shary que se solicitan para realizar el intercambio; (b) publicar o prestar artículos o servicios prohibidos por los Términos y Condiciones, demás políticas de la Empresa o leyes vigentes; (c) insultar o agredir a otros Usuarios; (d) utilizar su reputación, calificaciones o comentarios recibidos en el sitio de la Empresa en cualquier ámbito fuera de la Aplicación.<br />
        Este tipo de actividades será investigado por la Empresa y el infractor podrá ser sancionado con la suspensión o cancelación de las ofertas que haga e incluso de su inscripción como Usuario de la Aplicación  y/o de cualquier otra forma que estime pertinente, sin perjuicio de las acciones legales a que pueda dar lugar por la configuración de delitos o contravenciones o los perjuicios civiles que pueda causar a los restantes Usuarios.<br /><br />

        2) INHABILITACION O SUSPENSION DE USUARIO. EFECTOS.<br />

        2.1)	 Son causales para la inhabilitación o suspensión del usuario:<br />
        a)	CUENTAS DUPLICADAS<br />
        En la Aplicación cada Usuario es identificado por su correo electrónico. Y su trayectoria en la comunidad se refleja en su reputación.
        En este sentido, si un Usuario se inscribe bajo diferentes identidades podrá ser inhabilitado permanentemente para operar en el sitio.
        Si el Usuario ha sido suspendido por este motivo, para que la Empresa pueda verificar la identidad del mismo es necesario que el Usuario envíe una copia del documento con el que se ha inscripto en la Aplicación mediante e-mail haciendo clic en el botón Contactar a Atención al Cliente.<br />
        b)	INCUMPLIMIENTO DEL COMPROMISO<br />
        Todas las ofertas en la Aplicación son en firme, por lo tanto cada vez que un Usuario acepta realizar un intercambio en la Aplicación adquiere un compromiso ineludible.
        Si un Usuario es responsabilizado en 3 o más ocasiones de no cumplir con la misma la Empresa lo podrá suspender para continuar operando en el sitio.<br />
        c)	LENGUAJE INAPROPIADO<br />
        El objetivo de la Empresa es mantener una comunidad segura y cordial. El respeto entre sus miembros es la condición necesaria para su desarrollo. Por esta razón no está permitido el uso de un lenguaje soez, ni insultante, ni inadecuado para el ámbito en el que se expresa. Cualquier Usuario que usara un lenguaje inadecuado tanto en su página de artículo, en las preguntas y respuestas de otros Usuarios, como en los foros, etc., podrá ser suspendido temporalmente, o incluso inhabilitado definitivamente de operar en el sitio.  Adicionalmente, La Empresa podrá eliminar la publicación inadecuada.<br />
        d)	USO REITERADO DE PRÁCTICAS PROHIBIDAS<br />
        En el supuesto que  un Usuario utilice en reiteradas ocasiones técnicas de publicación prohibidas, la Empresa podrá suspenderlo o incluso inhabilitarlo permanentemente para operar en el sitio.<br />
        e) MENOR DE EDAD<br />
        Conforme surge del Punto 2) de los Términos y Condiciones, es requisito ineludible para poder registrarse como Usuario ser mayor de edad, motivo por el cual, si la Empresa detecta que una cuenta se encontrara inscripta a nombre de un menor de edad, será inhabilitada para operar dentro del sitio.<br />
        f)	ERROR EN LA CUENTA DE MAIL<br />
        Cuando la dirección de e-mail de un Usuario tiene problemas para recibir los mensajes que le son enviados desde La Empresa, la cuenta del mismo podrá ser inhabilitada temporalmente hasta tanto informe una nueva dirección de e-mail.<br /><br />

        2.2)	EFECTOS<br />

        a)	SUSPENSIÓN<br />
        Al ser suspendido, el Usuario recibirá una comunicación al e-mail denunciado comunicando la causa y extensión de la suspensión.
        Cuando un Usuario es suspendido no puede publicar, hacer intercambios, participar en foros ni hacer preguntas a los Usuarios por todo el plazo de duración de la suspensión.
        Al mismo tiempo, todas sus ofertas son dadas de baja del sitio. Los mismos podrán ser republicados al fin de la suspensión ingresando desde el menú de navegación en TU CUENTA.<br />
        b)	INHABILITACIÓN<br />
        Al ser inhabilitado, el Usuario recibirá una comunicación al e-mail denunciado comunicando la causa.
        Cuando un Usuario es inhabilitado no puede realizar ninguna actividad dentro de la plataforma. Al mismo tiempo, todas sus publicaciones de ofertas de bienes o servicios podrán ser dadas de baja.<br /><br />

        3.	OPINIONES, COMENTARIOS, COMUNICACIONES Y OTROS CONTENIDOS<br />
        Los Usuarios podrán publicar sus opiniones y comentarios así como publicar otros contenidos, enviar comunicaciones, sugerencias, ideas, comentarios, preguntas u otra información, siempre que el contenido de las mismas no resulte ilícito, obsceno, abusivo, constituya un supuesto de amenaza o difamación, o invada la privacidad de terceros, infrinja derechos de propiedad intelectual o de cualquier otra forma resulte ofensivo para terceros o censurable, ni consista en o contenga virus informáticos, propaganda política o contenido publicitario, correos en cadena, envío masivo de correos o constituya cualquier otro tipo de "spam". No está permitido el uso de direcciones de correo falsas, ni suplantar la identidad de otra persona o entidad, ni falsear de cualquier otro modo el origen de cualquier otro contenido. Nos reservamos el derecho  a eliminar o modificar cualquier contenido que incumpla con las pautas establecidas. Si cualquier Usuario considera que cualquier contenido de la Aplicación o cualquier producto o servicio publicitada en la misma incluye cualquier afirmación difamatoria, o si entendiera que sus derechos de propiedad intelectual están siendo infringidos por cualquier elemento o información disponible en la Aplicación, el mismo podrá comunicar dicha circunstancia a la Empresa a través del envío de un correo electrónico a <a href="mailto:info@sharyco.com">info@sharyco.com</a><br />
        Al publicar cualquier contenido o presentar cualquier material para su publicación, y salvo que se indique lo contrario, el Usuario otorga: (a) a favor de la Empresa el derecho no exclusivo, gratuito y susceptible de plena cesión y transmisión a favor de un tercero, a utilizar, reproducir, modificar, adaptar, publicar, traducir, crear y explotar cualesquiera obras derivadas y distribuir y exhibir su contenido en todo el mundo y a través de cualquier medio; y (b) a favor de la Empresa, sus sub-licenciatarios y quienes derivaran su título de la Empresa, el derecho a utilizar el nombre facilitado por usted en relación con dicho contenido, si así lo decidiera. <br />
        Al aceptar estas Condiciones de Uso, el Usuario acepta el carácter irrevocable de los derechos así conferidos durante la totalidad del plazo de protección reconocido a sus derechos de propiedad intelectual sobre el contenido y material identificado anteriormente. Asimismo se compromete a llevar a cabo cualquier actuación adicional que fuera necesario a efectos de formalizar la cesión de los derechos que confiere a favor de la Empresa, incluyendo la suscripción de cualesquiera instrumentos y documentación, a solicitud de la Empresa.<br />
        Los Usuarios declaran y garantizan ser titulares de la totalidad de los derechos sobre el contenido que pudieran publicar, manifestando y garantizado asimismo que a la fecha de la remisión de dicho contenido: (i) dicho contenido y material es exacto; y que (ii) la utilización de dicho contenido o material no supondrá incumplimiento alguno de las políticas o directrices aplicables de la Empresa, ni supondrá perjuicio alguno a ninguna persona o entidad (garantizando asimismo que el contenido o material en cuestión no es de carácter difamatorio). Los Usuarios se comprometen a liberar a la Empresa de cualquier reclamo presentado por terceros contra la Empresa derivado de o en relación con dicho contenido y materiales, salvo en la medida en que dicha responsabilidad derive de la falta de supresión adecuada por parte de la Empresa de dicho contenido o material tras haber sido notificados (a través del correspondiente correo enviado a (<a href="mailto:info@sharyco.com">info@sharyco.com</a>) del carácter ilícito de dicho contenido o material.<br /><br />

        <h3>III) CONDICIONES DEL INTERCAMBIO</h3><br />

        1)OBLIGACIONES DE LOS USUARIOS.<br />
        El Usuario que quiera canjear sus puntos Shary por algún producto o servicio debe apretar en la tarjeta del producto o servicio que le interesa. Debe leer la descripción para informarse de las características específicas del producto o servicio  seleccionado. Si desea continuar con el intercambio debe apretar el botón “Pedir”. Le llegará un mail automático confirmando que solicitó el producto o servicio. El Usuario que publicó el producto o servicio  puede aceptar o rechazar el intercambio.  En caso de rechazarlo, debe dar fundamentos. El Usuario recibirá un mail con la aceptación o rechazo del intercambio propuesto. Si el Usuario acepta el intercambio acepta quedar obligado por las condiciones incluidas en la descripción de la misma, y le aparecerá la publicación en la sección de “Lo que prestaste” en el sidebar de la Aplicación. Los Usuarios que realizan el intercambio deben comunicarse por el servicio de mensajería interna de la Aplicación para realizar consultas, coordinar el modo, los costos, la fecha y la hora de entrega. La Empresa no asume responsabilidad alguna por la selección y contratación de servicios de envío que puedan acordar los Usuarios. <br />
        En el caso de intercambio de productos, una vez que el Usuario recibe el producto  deberá: 1) marcar el botón “recibí”, 2)  subir una foto de cómo recibió el producto, 3) responder sí o no a la pregunta de si recibió el producto en el mismo estado que dice la publicación y  4) responder sí o no a la pregunta de si le hicieron entrega del producto a tiempo. Para devolver el producto deberá ir a la sección de “Lo que pediste” y apretar el botón “devolví” y calificar  al Usuario y al producto. El Usuario que recupera el producto que intercambió debe confirmar la devolución desde la sección de “Lo que prestaste” en el sidebar de la Aplicación. Para confirmar la devolución debe entrar a la tarjeta del producto solicitado y debe apretar el botón de “confirmar devolución”. También deberá 1) subir una foto de cómo le devolvieron el producto, 2) responder sí o no a la pregunta de si recibió el producto en el mismo estado que dice la publicación y  3) responder sí o no a la pregunta de si le hicieron entrega del producto a tiempo y 4) calificar al Usuario con un puntaje. <br />
        En el caso de servicios, una vez que el Usuario recibe el mismo deberá: 1) marcar el botón “Ya recibí servicio” o “ya recibí Favor”, 2) responder sí o no a la pregunta de si el Usuario realizó el favor correctamente y  3) responder sí o no a la pregunta de si el servicio o favor se prestó a tiempo y 4) calificar  al Usuario y al servicio o favor. El Usuario que realizó el servicio o favor debe calificar al Usuario que lo recibió con un puntaje. <br />
        Los Usuarios podrán cancelar el intercambio en cualquier momento antes de que se haya entregado el producto o prestado el servicio.  Dado que la Empresa sólo pone a disposición de los Usuarios un espacio virtual que les permite comunicarse mediante Internet para encontrar una forma de intercambiar productos y servicios, la Empresa no tiene participación alguna en el proceso de negociación y perfeccionamiento del contrato definitivo entre los Usuarios. Por eso, la Empresa no es responsable por el efectivo cumplimiento de las obligaciones establecidas por la ley vigente.<br /><br />


        2) INFORMACIÓN DEL PRODUCTO<br />
        Si bien la Empresa aplica sus mejores esfuerzos para que la información que aparece en la Aplicación sea correcta, en ocasiones el embalaje y los materiales de los productos pueden contener información adicional o distinta de la que aparece en la Aplicación. Los componentes también pueden cambiar. Consecuentemente, se recomienda al Usuario que no se detenga a leer solamente la información disponible en el sitio web si no que, antes de su utilización, lea también detenidamente el etiquetado, las advertencias e instrucciones que acompañen al producto. <br /><br />


        3) NUESTRA RESPONSABILIDAD<br />
        La Empresa no realiza ninguna declaración ni garantía respecto de los productos o servicios que se intercambian a través de la Aplicación. A mayor abundamiento, la Empresa no hace ninguna declaración ni garantía respecto a la integridad, fiabilidad, puntualidad, calidad, idoneidad o disponibilidad de los productos o servicios intercambiados o de los servicios de logística contratados por los Usuarios.<br /><br />


        4) MODIFICACIÓN DE LAS CONDICIONES DEL INTERCAMBIO<br />
        La Empresa se reserva el derecho a realizar cambios al sitio web, a las políticas y a los términos y condiciones, incluyendo a las presentes Condiciones del Intercambio, en cualquier momento. Los Usuarios quedarán sujetos a los términos y condiciones, políticas y Condiciones del Intercambio en vigor en el momento en que realicen su pedido, a menos que, por ley o por requerimiento de las autoridades públicas, se deba efectuar un cambio a dichos términos y condiciones, políticas o a las presentes Condiciones del Intercambio (en cuyo caso, tales cambios podrían resultar aplicables a cualesquiera pedidos que hubiera realizado anteriormente). Si alguna de las presentes condiciones fuera declarada inválida, nula o por cualquier causa ineficaz, dicha condición se entenderá excluida sin que dicha declaración pueda afectar a la validez ni a la exigibilidad del resto de condiciones.

      </LayoutResponsiveWSmall>
    );
  }
}

export default withTranslation()(InfoTerms);
