import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form';
import { ToastContainer, notify } from '../../libraries/notifications';
import { history } from '../../routes';
import config from '../../config';

import SearchInput from "../../components/forms/SearchInput";
import OfferOptions from '../../components/customs/OfferOptions';
import Loader from '../../components/commons/Loader';
import LayoutSmall from '../../components/layout/LayoutSmall';
import Icon from '../../libraries/icons';

import categoriesAction from '../../context/categories/actions';
import LayoutResponsiveWSmall from '../../components/layout/LayoutResponsiveWSmall';

class Step1 extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      category: null,
      categories: [],
      categories_selected: [],
      search : '',
      sub_type: null,
      parent: 'root',
      duplicates:[],

    }
  }
  
  componentDidMount() {
    console.log("STEP 1");
    const {type, sub_type} = {...this.props.match.params};
    const {search, parent} = {...this.props.location.state}
    if (sub_type && type){
      const { fromPath } = this.props.location?.state
      this.setState({
        fromPath,
        sub_type,
        type,
        parent: parent || 'root',
        search: search || '',
      }, () => this.getCategories());

    } else {
      this.goBack();
    }    
  }


  getCategories = async (next) => {
    const { type, sub_type, parent, prev_parent, search, prev_search, category, categories_selected } = {...this.state}
    const params = { type: sub_type, parent }
    if (search && search !== ""){
      // params.where = { json_data: { name: search }}
      params.name = `${search.toLowerCase()}`;
      params.parent = null
    }

    console.log('LOOKING', sub_type, parent, search);
    await this.props.onGetAllCategories(params);
    const { categories } = this.props;
    if (categories.error) { 
      notify(this.t(categories.error.message));
    } else {
      // Find parents ids
      const findDuplicates = async (array) => {
        const parents = []
        const duplicates = []
        for (let i = 0; i < array.length; i++) {
          const previous = array[i - 1]
          const current = array[i]
          const next = array[i + 1]
          if (current?.name === next?.name || current?.name === previous?.name){
            parents.push(current.parent)
            duplicates.push(current.id)
          }
        }
        if(parents.length === 0) return 
        // Search for parents
        await this.props.onGetAllCategories({id: parents})
        const { categories } = this.props;
        if (categories.error) { 
          notify(this.t(categories.error.message));
        } else {
          // Success
          // Hashmap (we access the exact object when rendering the category using the property parent, category.parent === parent.id)
          this.setState({parents: categories.items.reduce((a,b) =>  ({...a, [b.id]: b}),{}), duplicates})
        }
      }
      this.setState({ categories: categories.items });
      findDuplicates(categories.items)
      
      if (next && categories.items.length === 0) {
        console.log('GO NEXT', category);
        console.log(config.ROUTES[type.toUpperCase()])
        history.push({
          pathname: config.ROUTES[type.toUpperCase()].DETAILS.replace(':type', sub_type),
          state: {
            fromPath: this.state.fromPath,
            type: sub_type,
            category,
            categories_selected,
            search: prev_search,
            parent: prev_parent
          }
        })
      }
    }    
  }
  

  onCategorySelect = async (category) => {
    console.log('SELECTED CATEGORY', category);
    const { categories_selected, search } = this.state;
    this.setState({
      search: '',
      prev_search: search.length> 0 ? search : '',
      category,
      categories_selected: [...categories_selected, category],
      prev_parent: category.parent,
      parent: category.id 
    }, () => this.getCategories(true));
  }


  searchClear = (form) => {
    form.change('search', undefined);
    this.searchOnClick({search: ''});
  }

  searchOnClick = async (values) => {
    console.log("Search on Click", values.search);
    if (this.state.search !== values.search) this.setState({ search: values.search || '' }, () => this.getCategories() );
  };


  goBack = async () => {
    if(this.state.parent === "root") {
      history.push(this.state.fromPath ? this.state.fromPath : config.ROUTES.HOME)
    }
    this.setState(() => {
      if(this.state?.parent === this.state?.prev_parent) return { parent: "root" }
      return {
        parent: this.state.category?.parent
      }
    }, () => this.getCategories())
  }


  render() {
    const { categories, sub_type, type, parents, duplicates} = this.state;

    const titles = {
      products: {
        offers: this.t("¿Qué producto querés prestar?"),
        requests: this.t("¿Qué producto necesitás?")
      },
      services: {
        offers: this.t("¿Qué servicios querés brindar?"),
        requests:this.t("¿Qué servicios necesitás?")
      },
      favors: {
        offers: this.t("¿Qué favores querés ofrecer?"),
        requests: this.t("¿Qué favores necesitás?")
      },
    }

    return (
      <LayoutResponsiveWSmall
        main={{ className: ""}}
        header={{ 
          className: "lg:hidden",
          left: { icon: 'arrow_left', action: this.goBack },
        }}
        container={{ className: "px-0"}}
        loading={this.props.categories.loading}
        showGoBackButton={true}
        onGoBackClick={this.goBack}
      >
        <ToastContainer/>
        <section className="bg-primary text-primary-content text-center px-3 pb-4 lg:mt-12 lg:rounded-t-lg">
          {sub_type === config.TYPES.OFFERS.PRODUCTS && <Icon className="h-16 w-16 mx-auto mb-1" name="tag" />}
          {sub_type === config.TYPES.OFFERS.FAVORS && <Icon className="h-16 w-16 mx-auto mb-1" name="favor" />}
          <h1>{sub_type && type && titles[sub_type][type]}</h1>
        </section>
        <section className="bg-primary-focus px-3 pt-3 lg:rounded-b-lg">
          <Form initialValues={{}} onSubmit={this.searchOnClick}>
            {({ handleSubmit, form, submitting, pristine, values }) => (
              <form onSubmit={handleSubmit} className="w-full max-w-lg mx-auto pb-4 ">
                <Field name="search" component={SearchInput} placeholder={this.t("Search")} onClick={this.searchOnClick} onClearClick={() => this.searchClear(form) }/>
              </form>
            )}
          </Form>
        </section>
        <section className="overflow-y-auto w-full categories-vertical-wrapper pt-5 rounded-lg">
        {/* <section className="w-full max-w-lg mx-auto"> */}
          { this.props.categories.loading && (<Loader/>) }
          {!this.props.categories.loading && categories.length === 0 && (
            <div className="text-center p-10">
              <Icon className="h-24 w-24 mx-auto mb-3" name="bell" />
              <h4 className="mb-1">{this.t("There is no categories")}</h4>
            </div>
          )}
          <div className="card white rounded-lg">
            <div className="overflow-x-auto">
              { categories && categories.map((category,index) => (
                <OfferOptions 
                  key={index}
                  data={category}
                  parent={duplicates.includes(category.id) ? parents[category.parent] : null}
                  onClick={this.onCategorySelect}
                />
              ))}
            </div>
          </div>
        </section>
      </LayoutResponsiveWSmall>      
    ) 
  }
}

const mapStateToProps = state => {
  return {
    auth: state.users.auth,
    categories : state.categories.list
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetAllCategories: (params) => dispatch(categoriesAction.getAll(params)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(Step1));
