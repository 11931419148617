import React from 'react';
import { connect } from 'react-redux';
import { Form, Field } from "react-final-form";
import { withTranslation } from 'react-i18next';
import { history } from '../../routes';
import { ToastContainer, notify } from '../../libraries/notifications';
import { getObjectWithJsonDataToFormValues, capitalize, uuidv4 } from '../../libraries/utils';

import config from '../../config';
import productsAction from '../../context/products/actions';
import transactionsAction from '../../context/transactions/actions';
import usersAction from '../../context/users/actions';
import messagesActions from '../../context/messages/actions';

import Button from '../../components/commons/Button';
import IconCoins from '../../components/customs/IconCoins';
import IconPoints from '../../components/customs/IconPoints';
import UserOwnerElement from '../../components/customs/UserOwnerElement';
import RatingProfileCard from '../../components/customs/RatingProfileCard';
import CommentElement from '../../components/customs/CommentElement'
import Loader from '../../components/commons/Loader';

import TextareaInput from '../../components/forms/TextareaInput';
import internalsActions from '../../context/internals/actions';
import SmallRating from '../../components/commons/SmallRating'
import LayoutResponsiveWSmall from '../../components/layout/LayoutResponsiveWSmall';

class Product extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      submitting : false,
      product: {},
      owner: {},
      fromPath: null
      // uploadLoading: false,
    }
  }

  componentDidMount() {
    const params = this.props.match.params;
    console.log('PRODUCT_PAGE', params);
    if (params.id){
      this.getProduct(params);
    } else {
      this.goBack();
    }

    const fromPath  = this.props.location.state?.fromPath || config.ROUTES.HOME_OPTIONS.PRODUCTS;
    this.setState({ fromPath });   
    console.log('FROMPATH', fromPath);
   
  }


  getProduct = async (params)=> {
    await this.props.onGetProduct(params);
    const { product } = this.props;
    if (product.error) { 
      notify(this.t(product.error.message));
    } else {
      await this.props.onGetOwner({ id: product.item.owner });
      const owner = this.props.owner;
      if (owner.error) { 
        notify(this.t(owner.error.message));
      } else {

        const productData = getObjectWithJsonDataToFormValues(
          product.item,
          ['id', 'owner', 'img', 'name', 'status', 'details', 'points', 'coins','type', 'enabled', 'rate_total', 'rate_count']
        );
        const ownerData = getObjectWithJsonDataToFormValues(
          owner.item,
          ['id', 'accounts', 'username', 'first_name', 'last_name', 'profile_image', 'rating', 'email', 'rate_total', 'rate_count']
          );
        const userData = getObjectWithJsonDataToFormValues(
          this.props.auth.user,
          ['id', 'accounts', 'username', 'first_name', 'last_name', 'profile_image', 'rating','rewards','email']
        );
        
        // let comments = await this.getComments(productData.id)
        // let opinions = await this.getOpinions(productData.id)
        // this.setState({ product: productData, owner: ownerData, user: userData, productComments: comments, opinions})

        this.getComments(productData.id)
        this.getOpinions(productData.id)
        this.setState({ product: productData, owner: ownerData, user: userData})
      }    
    }
  }
  
  getComments = async (productId) => {
    await this.props.onGetComments({ type:config.MESSAGES.TYPE.QUESTION, subject:config.TYPES.OFFERS.PRODUCTS, related_to:productId, order_direction: 'DESC'});
    const { comments } = this.props;
    if (comments.error) { 
      notify(this.t(comments.error.message));
      this.setState({productComments: []});
    } else {
      this.setState({productComments: comments.items});
    }
  }

  getOpinions = async (productId) => {
    await this.props.onGetTransactions({target: productId});
    const { transactions } = this.props;
    if (transactions.error) {
      notify(this.t(transactions.error.message));
      this.setState({opinions: []});
    } else {
      let opinions = transactions.items.filter(t => t.json_data.product_rating)
      this.setState({opinions});
    }
  }

  getMessage = async (source, target, related_to) => {
    let params = {source, target, related_to, parent: 'root'}
    await this.props.onGetAllMessages(params);
    const messages = this.props.messages
    if (messages.error) { 
      notify(this.t(messages.error.message));
      return null
    } else {
      let messages_ = [...messages.items] 
      //if(!related_to){
      //  messages_ = messages.items.filter(m => m.related_to === "" || m.related_to === null) 
      //}
      //messages_ = messages.items.filter(m => m.parent === "root") 
      if(messages_.length){
        return messages_[0]
      }
      
      return null
    }
  }


  saveBorrow = async () => {
    this.setState({ submitting: true })

    const { product, owner, user } = this.state;
    const params = { id: product.id, status: config.STATUS.PRODUCTS.BORROWED }

    await this.props.onUpdateProduct(params);
    console.log('AFTER CHANGE PRODUCT STATUS');
    if (this.props.product.error) { 
      notify(this.t(this.props.product.error.message));
      this.setState({ submitting: false })
    } else {

      let data = {
        json_data: {
          product,
          owner, 
          borrower: user,
          coins: product.coins,
          points: product.points          
        },
        target: product.id, 
        source: product.owner,
        type: config.TRANSACTIONS.BORROW.TYPE,
        sub_type: config.TRANSACTIONS.BORROW.SUB_TYPE.PRODUCT,
        status: config.TRANSACTIONS.BORROW.STATUS.NEW,
        owner: this.props.auth.user.unique_id,
      }
      console.log('TRANSACTION', data);
      await this.props.onSaveTransaction(data);
      console.log('AFTER SAVE TRANSACTION');
      const transaction = this.props.transaction;
      if (transaction.error) { 
        notify(this.t(transaction.error.message));
        this.setState({ submitting:false })
      } else {
        
        let notificationData = {
          type:config.MESSAGES.TYPE.NOTIFICATION,
          source: user.id,
          target: owner.id,
          related_to: product.id,
          parent: null , // por que " " ???
         /*  readed:false, */
          subject: product.type,
          body: " te pidió prestado un/una " + product.name, // The body is used to redirect at /notifications. Change it there too.
          json_data: {
            product,         
            redirect_notification_route: config.ROUTES.LOANS,
          },
        }
        await this.props.onSaveNotification(notificationData);
        console.log('AFTER SAVE NOTIFICATION');
        const notification = this.props.message;
        if (notification.error) { 
          notify(this.t(notification.error.message));
          this.setState({ submitting:false })
        } else {
          history.push({
            pathname: config.ROUTES.PRODUCTS.SUCCESS.replace(':id', product.id),
            state: {
              title: "Reserva enviada",
              // headline: "El dueño del producto tiene 24 horas para responder tu petición.",
              button:{
                text: "Continuar",
                route: config.ROUTES.USER.RETURNS,
              },
            }
          })
        }
      }
    }
  }

  sendMessage = (product, owner) => {
    console.log('MESSAGE', product);
    history.push({
      pathname: config.ROUTES.SEND_MESSAGE,
      state: {
        fromPath: config.ROUTES.PRODUCTS.DETAIL.replace(':id', product.id),
        product,
        owner
        }
      }
    );
  }

  commentProduct = async (values, owner, product) => {
    console.log("OWNER",owner.id)
    const user = this.props.auth.user.id;
    const comments = this.state.productComments.filter((comment) => comment.source.id === user)
    if(comments.length > 0 && comments[0].source.id === user && !comments[0].json_data.answered){
      console.log("same last message")
      this.setState({errorMessage: "Debes esperar a que te respondan el mensaje anterior para mandar otro comentario en el mismo producto"})
      return
    }

    // if(this.state.productComments){
    //   if(this.state.productComments.length === 1){
    //     if(this.state.productComments[0].source.id === this.props.auth.user.id &&
    //       !this.state.productComments[0].json_data.answered){
    //       console.log("same last message")
    //       this.setState({errorMessage: "Debes esperar a que te respondan el mensaje anterior para mandar otro comentario en el mismo producto"})
    //       return
    //     }
    //   }
    //   if(this.state.productComments.length > 1){
    //     if(this.state.productComments[1].source.id === this.props.auth.user.id &&
    //       !this.state.productComments[1].json_data.answered){
    //       console.log("same last message")
    //       this.setState({errorMessage: "Debes esperar a que te respondan el mensaje anterior para mandar otro comentario en el mismo producto"})
    //       return
    //     }
    //   }
    // }


    let parent_ = config.TYPES.ROOT
    let same_conversation_message = await this.getMessage(user,  owner.id, product.id)

    if(same_conversation_message){
      parent_ = same_conversation_message.id
    }


    let data = {
      type: config.MESSAGES.TYPE.QUESTION,
      source: user,
      target: owner.id,
      related_to: product.id,
      parent: parent_,
     /*  readed:false, */
      subject: product.type,
      body:values.message.replaceAll('\n', ' '),
      json_data:{
        _id: uuidv4(),
      }
    }
    if(!same_conversation_message){
      data.json_data = {
        answered: 0,
        product_name: product.name,
        product_img: product.img,
        unread_messages_for_source:0,
        unread_messages_for_target:1,
        last_message_from: 'source',
        last_message_portion:values.message.replaceAll('\n', ' ').slice(0,30),
        last_message_at: new Date().toISOString()
      }
    }
    else{
      const obj = {
        id: same_conversation_message.id,
        json_data: {
          answered: 0,
          product_name: product.name,
          product_img: product.img,
          unread_messages_for_source:0,
          unread_messages_for_target:1,
          last_message_from: 'source',
          last_message_portion:values.message.replaceAll('\n', ' ').slice(0,30),
          last_message_at: new Date().toISOString()
        }
      }
      await this.props.onSaveMessage(obj);
    }
    console.log('MESSAGE', data);
    await this.props.onSaveMessage(data);
    console.log('AFTER SAVE MESSAGE');
    const sendedMessage = this.props.message
    if (sendedMessage.error) { 
      notify(this.t(sendedMessage.error.message));
    } else {
      console.log(sendedMessage)
      let mailData = {
        template: "message_owner",
        locale: "es",
        to: this.state.owner.email,
        first_name: "",
        last_name:  "",
        params: {
            // subject: "Comentaron uno de tus productos",
            owner: `${this.state.owner.first_name} ${this.state.owner.last_name}`,
            user: `${this.props.auth.user.first_name} ${this.props.auth.user.last_name}`,
            product: `${product.name}.`,
            message: `${values.message}`,
            publication_name: product.name,
            //product_route: config.ROUTES.PRODUCTS.DETAIL.replace(':id', product.id),
            product_route: config.ROUTES.QUESTION.replace(':root_id', same_conversation_message?.id || sendedMessage?.item?.id),
        }
      }
      await this.props.onSendMail(mailData);
      const internals = this.props.internals;
      if (internals.error) {
        notify(this.t(internals.error.message));
      }

      history.push({
        pathname: config.ROUTES.MESSAGE_SUCCESS.replace(':id', sendedMessage.item.id),
        state: {
          title: "Comentaste el producto",
          headline: "Podés verlo en detalle en tu casilla de mensajes enviados",
          button:{
            text: "Continuar",
            route: config.ROUTES.PRODUCTS.DETAIL.replace(':id', product.id),
          },
        }
      })

    }}



  goBack = () => {
    history.push({
      pathname: this.state.fromPath || config.ROUTES.HOME,
      state: { from: config.ROUTES.MAIN.OFFER }
    });
  }


  render() {
    const { product, owner, productComments, submitting, user, opinions} = this.state;
    const couldBorrow = (user && user.rewards && user.rewards.total && user.rewards.total.coins && parseInt(product.coins) <= parseInt(user.rewards.total.coins) && product.status === config.STATUS.PRODUCTS.AVAILABLE);
    const is_mine = product && owner && (product.owner === this.props.auth.user.id)
    const rating = product?.rate_total && (product?.rate_count !== 0) ? product?.rate_total/product?.rate_count : 0
    return (
      <LayoutResponsiveWSmall
        main={{ className: ""}}
        header={{ 
          className: " bg-gradient-to-b from-primary-focus fixed md:static to-transparent bg-transparent text-primary lg:hidden",
          left: !this.props.product.loading ? { icon: 'arrow_left', action: this.goBack } : {},
          // right: { icon: 'heart'}
        }}
        loading={this.props.product.loading || this.props.owner.loading || this.props.comments.loading}        
        container={{ className: "px-0"}}
        showGoBackButton={true}
        onGoBackClick={this.goBack}
      >
        <ToastContainer />
        {/* {submitting && (<Loader/>) } */}
        {product && owner && !submitting && (
          <section className=" mx-auto lg:mt-4"> 
            <div className="bg-white shadow lg:rounded-lg">
              <div className="flex justify-center items-center">
                <img src={product.img} alt="" className='mx-auto w-full md:w-1/2 aspect-square object-contain' />
              </div>
            </div>
            <section className="container-fluid p-3 bg-white lg:bg-transparent">
              <div className="flex items-center justify-between">
                <h1 className="">{product.name}</h1>
                {/* <small className="text-gray-500">3km</small> */}
              </div>
              <UserOwnerElement className="flex items-center" starClassName="ml-2" owner={owner} route={{pathname: config.ROUTES.USER.PUBLIC_PROFILE.replace(':id', owner.id ), state: {fromPath: config.ROUTES.PRODUCTS.DETAIL.replace(':id', product.id)}}}/>
              <div className="flex justify-between items-center">
                <div className="flex items-center gap-2 my-4">
                  <IconCoins color='red' negative={true} coins={product.coins}/>
                  <IconPoints className='bg-success bg-opacity-10 flex gap-2 rounded' points={product.points}/>
                </div>
                {/* <small>{this.t("por día")}</small> */}
                {/* <small className="text-gray-500">{product.distance} km</small> */}
              </div>
              {/* <h4 className="mb-4">{product.status}</h4> */}
              {/* <Button className="btn btn-xs btn-ghost text-primary underline" title={this.t("Consultar disponibilidad")}
                onClick={this.getAvailability}
              /> */}
              <div className="flex items-center gap-3 border-b border-gray-200 pb-4 mt-4">
                {/* <ButtonIcon buttonClassName="btn-ghost bg-base-100" className="w-6 h-6 text-neutral" icon="chat_alt"
                  onClick={() => this.sendMessage(product,owner)}
                /> */}
                {!is_mine && (
                  <Button className="btn btn-primary flex-1" title={this.t("Pedir prestado")}
                    disabled={!couldBorrow || product.status !== config.STATUS.PRODUCTS.AVAILABLE || product.owner === this.props.auth.user.id || submitting}
                    onClick={this.saveBorrow}
                  />
                )}
               
              </div>
              {!is_mine && !couldBorrow && product.status === config.STATUS.PRODUCTS.AVAILABLE && <h5 className="text-warning py-3">{this.t("Sus Puntos Shary no son suficientes para pedir prestado el producto.")}</h5>}
              {!is_mine && !couldBorrow && product.status !== config.STATUS.PRODUCTS.AVAILABLE && <h5 className="text-warning py-3">{this.t("El producto fue prestado o no esta disponible.")}</h5>}
            </section>
            <section className="container-fluid p-3" >
              <h3 className="mb-2">{this.t("Descripción")}</h3>
              <p className="text-neutral mt-2 mb-1">{product.details}</p>
            </section>
            {this.state.errorMessage && <p className="p-3">{this.state.errorMessage}</p>}
            <section className="container-fluid p-3">
              <h4 className="mb-2">{capitalize(this.t("comentarios del producto"))}</h4>
              {!is_mine && (
                <div className="pb-6">
                  <Form onSubmit={(values) => this.commentProduct(values, owner, product)}>
                    {({ handleSubmit, submitting, pristine }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="w-full mt-3 mb-1 md:mb-0">
                          <Field name="message" component={TextareaInput} inputClassName='resize-none h-28' placeholder={this.t("Escriba un comentario")} />
                        </div>
                        <Button
                          className="btn-primary btn-block"
                          title={this.t("Send")}
                          onClick={handleSubmit}
                          disabled={ submitting || pristine || this.props.product.loading}
                        />
                      </form>
                    )}
                  </Form>
                </div>
              )}
              {productComments && productComments.length === 0 && <p className ="p-4">{this.t('No hay comentarios')}</p>}
              {productComments && productComments.map((comment, index) => (<CommentElement key={index} data={comment}/>))}
                            
            </section>
            <section className="container-fluid p-3 my-6" >
              <h4 className="mb-4">{this.t('Opiniones de usuarios')}</h4>
              {opinions && opinions.length === 0 && <p className='px-4'>{this.t('No hay opiniones')}</p>}
              <div className='flex flex-col gap-3'>
                {opinions && opinions.slice(0,4).map((opinion,index)=> (
                  <RatingProfileCard key={index} data={opinion} type="product"/>
                ))}
              </div>
            </section>
         
          </section>
        )}

      </LayoutResponsiveWSmall>
    ) 
  }
}

const mapStateToProps = state => {
  return {
    auth: state.users.auth,
    product: state.products.current,
    owner: state.users.current,
    transaction: state.transactions.current,
    transactions: state.transactions.list,
    message: state.messages.current,
    messages: state.messages.list,
    comments: state.messages.list,
    internals: state.internals,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetProduct: (params) => dispatch(productsAction.get(params)),
    onGetOwner: (params) => dispatch(usersAction.get(params)),
    onUpdateProduct: (params) => dispatch(productsAction.saveOrUpdate(params)),
    onSaveTransaction: (params) => dispatch(transactionsAction.saveOrUpdate(params)),
    onGetTransactions: (params) => dispatch(transactionsAction.getAll(params)),
    onGetMessage: (params) => dispatch(messagesActions.get(params)),
    onSaveMessage: (params) => dispatch(messagesActions.saveOrUpdate(params)),
    onGetAllMessages: (params) => dispatch(messagesActions.getAll(params)),
    onGetComments: (params) => dispatch(messagesActions.getAll(params)),
    onSaveNotification: (params) => dispatch(messagesActions.saveOrUpdate(params)),
    onSendMail: (params) => dispatch(internalsActions.sendMail(params)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(Product));



