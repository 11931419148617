import React from 'react';
import { useTranslation } from 'react-i18next';
import IconPoints from './IconPoints';
import IconCoins from './IconCoins';
import { getObjectWithJsonDataToFormValues, isFunction } from '../../libraries/utils';
import config from '../../config';
import logo from '../../assets/img/logo.png';
import Button from '../commons/Button';

//////////////////////////////////////////////////////////////////////////////////////////
//////////FIXME: ESTO NO DEBERÍA SER UN SOLO COMPONENTE GENÉRICO, ES UN QUILOMBO//////////
//////////////////////////////////////////////////////////////////////////////////////////
export default function ActivityCard({ statuses, types, sub_types, activity, user, ...rest }) {
  const { t } = useTranslation();

  const { coins, points, borrower, owner, status, type, sub_type, initiative, product, created_at, action, description, created_by, action_img, offer, canceled_by } = getObjectWithJsonDataToFormValues(
    activity,
    ['id', 'coins', 'points', 'borrower', 'owner', 'status', 'type', 'sub_type', 'initiative', 'product', 'created_at', 'action', 'description', 'created_by', 'action_img', 'offer', 'canceled_by']
  );


  const fullname = (user) => {
    return user ? `${user.first_name} ${user.last_name}` : '';
  }

  // const dateAndTime = new Date();
  // const activityDate = created_at;

  // const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // const utcDate1 = zonedTimeToUtc(dateAndTime, timeZone);
  // const utcDate2 = zonedTimeToUtc(activityDate, timeZone);


  const timeRemains = t("timeRemains", { date: new Date(created_at) })

  const getSubTypeString = (subtype) => subtype === config.TRANSACTIONS.BORROW.SUB_TYPE.FAVOR ? "favor" : "servicio"
  const serviceCompleted = (status, subtype) => (status === config.TRANSACTIONS.BORROW.STATUS.ON_OWNER) && (subtype === config.TRANSACTIONS.BORROW.SUB_TYPE.FAVOR || subtype === config.TRANSACTIONS.BORROW.SUB_TYPE.SERVICE)

  return (
    <div className="flex p-4 border-b border-base-200 gap-4" onClick={rest.onClick}>
      {(product?.img || initiative?.img || action_img) && 
      <div className="max-w-[4rem] w-full overflow-hidden flex justify-center items-center">
        {type === config.TRANSACTIONS.BORROW.TYPE ?
          sub_type === config.TRANSACTIONS.BORROW.SUB_TYPE.PRODUCT ?
            <img alt="" src={product?.img} className='object-contain'/> :
            <></>
          : type === config.TRANSACTIONS.INITIATIVE.TYPE ?
            <img alt="" src={initiative.img} className='object-contain' />
            :
            <img alt="" src={action_img} className='object-contain'/>
        }

      </div>}

      {(!product?.img && !initiative?.img && !action_img)
        &&
        <div className="max-w-[4rem] w-full overflow-hidden flex justify-center items-center">
          <img alt="" src={logo} className='object-contain'/>
        </div>}
      <div className='flex-1'>
        <div className="">
          {type === config.TRANSACTIONS.BORROW.TYPE ?
            sub_type === config.TRANSACTIONS.BORROW.SUB_TYPE.PRODUCT ?
              owner.id === user ?
                <h5 className="">{t("Le prestaste a " + fullname(borrower) + " " + product?.name.toLowerCase())} </h5>
                : <h5 className="">{t("Pediste a " + fullname(owner) + " " + product?.name.toLowerCase())}</h5>
              : owner.id === user ?
                <h5 className="">{t(`Le brindaste el ${getSubTypeString(sub_type)} ` + offer?.name.toLowerCase() + ' a ' + fullname(borrower))}</h5>
                : <h5 className="">{t(fullname(owner) + ` te brindó el ${getSubTypeString(sub_type)} ` + offer?.name.toLowerCase())}</h5>
            : type === config.TRANSACTIONS.INITIATIVE.TYPE ?
              <h5 className="">{t((status === 'postulated' ? "Te postulaste a " : "Asististe a ") + initiative?.name)} </h5>
              :
              <div>
                <h5 className="">{t("Realizaste " + action.name)} </h5>
                <p className="mt-2 italic">{t(description)} </p>
              </div>
          }

        </div>
        <div className="flex justify-between mt-2" >

          {type === config.TRANSACTIONS.BORROW.TYPE ?
            sub_type === config.TRANSACTIONS.BORROW.SUB_TYPE.PRODUCT ?
              <div className="flex items-center gap-2">
                <IconCoins negative={owner.id !== user} coins={product?.coins} />
                <IconPoints points={product?.points} />
              </div> :
              <div className="flex items-center gap-2">
                <IconCoins negative={owner.id !== user} coins={offer?.coins} />
                <IconPoints points={offer?.points} />
              </div>
            :
            <div className="flex items-center gap-2">
              <IconCoins negative={owner.id !== user} coins={coins} />
              <IconPoints points={points} />
            </div>
          }

        </div>
        <div className="flex gap-3 justify-between items-center mt-2">
          <h6 className=" text-gray-500">{timeRemains}</h6>
          {serviceCompleted(status, sub_type) && (
            <small
              className={"badge badge-sm badge-success " +
                (statuses[status]?.className ? statuses[status]?.className : "text-white ")}
            >
              {"Completo"}
            </small>
          )}
          {statuses && !serviceCompleted(status, sub_type) && (
            <small
              className={"badge badge-sm badge-warning " +
                (statuses[status]?.className ? statuses[status]?.className : "text-white ")}
            >
              {statuses[status]?.nombre}
            </small>
          )
          }
        </div>
        {status === "canceled" ? <small className="mt-2 block text-warning">Cancelado por: {(canceled_by === "owner") ? fullname(owner) : fullname(borrower)}</small> : null}

        {rest.actions && rest.actions.map((action, index) =>
          <div className="flex justify-between items-center mt-2">
            <Button
              key={index} disabled={action.disabled} className={"btn-xs " + (action.className ? action.className : "")}
              title={action.title} onClick={() => isFunction(action.onClick) ? action.onClick() : null}
            />
          </div>
        )}
      </div>

    </div>
  )
}