
import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { history } from '../../routes';
import { ToastContainer, notify } from '../../libraries/notifications';
import { getOwner, uuidv4 } from '../../libraries/utils';
import Avatar from '../../components/commons/Avatar';
import { Form, Field } from "react-final-form";
import TextareaInput from '../../components/forms/TextareaInput';
import config from '../../config';
import Button from '../../components/commons/Button';
import MessagesActions from '../../context/messages/actions';
import productsAction from '../../context/products/actions';
import usersActions from '../../context/users/actions';
import volunteeringAction from "../../context/volunteering/actions";
import filesActions from '../../context/files/actions';
import recyclingAction from "../../context/recycling/actions";
import donationsAction from "../../context/donations/actions";
import { formatFullname, capitalizePhrase } from '../../libraries/utils';
import internalsActions from '../../context/internals/actions';
import actions from '../../context/settings/actions';
import ImageUploadFileInputEdit from '../../components/forms/ImageUploadFileInputEdit';
import LayoutResponsiveWSmall from '../../components/layout/LayoutResponsiveWSmall';

class SendMessage extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.isReclamo = this.props.location.pathname.includes('send_message');
    this.state = {
      files: {},
      owner: getOwner(this.props.auth.user)
    }
  }

  componentDidMount() {
  /*   const params = this.props.match.params; */
    const state = this.props.location.state;
    const {loan, message, product, volunteering, recycling, donation, user, from, owner, reclamo} = {...this.props.location.state}
    if(this.props.location.state){
      this.setState({fromPath:this.props.location.state.fromPath})
    }
    this.getSetting()
    console.log("STATE",state)
    if (product){
      this.setState({ product: product, productOwner:owner, messageCore:product})
    } else if (message){
      this.setState({ message: message, messageCore:message })
    } else if (volunteering){
      this.setState({ volunteering: volunteering, messageCore:volunteering})
    } else if (recycling){
      this.setState({ recycling: recycling, messageCore:recycling})
    } else if (donation){
      this.setState({ donation: donation, messageCore:donation})
    } else if (user){
      this.setState({ user_profile: user, messageCore:{type:"no_core"}})
    } else if (loan){
      this.setState({ loan, messageCore: loan})
    } else if(reclamo){
      this.getAdminUser()
      this.setState({ reclamoRelatedTo: reclamo.related_to, messageCore: reclamo})      
    }
    else {
      this.goBack();
    }
  
    this.setState({ route: {
        pathname: from || config.ROUTES.HOME,
        state: { from: config.ROUTES.MAIN.MESSAGES }
      }
    });
  }
  getSetting= async()=>{
    await this.props.onGetSetting({"code": "email_admin_sharyco","type": "sharyco_admin_settings"});
    const error= this.props.setting.error
    if(error){
     notify(this.t(error.message));
    }else{
     this.setState({setting:this.props.setting.items})
    }
   }
  getAdminUser = async () => {
    let params = {email: config.ADMIN_MAIL}
    await this.props.onGetUser(params);
    let {users} = this.props
    if (users.error) {
      notify(this.t(users.error.message));
    } else {
      if(users.items.length){
        this.setState({adminUser: users.items[0]})
      }
    }
  }
  

  goBack = () => {
    history.push(this.state.fromPath || config.ROUTES.HOME);
  }

  getMessage = async (source, target, related_to) => {
    let params = {source, target, related_to, parent:'root'}
    await this.props.onGetAllMessages(params);
    const messages = this.props.messages
    if (messages.error) { 
      notify(this.t(messages.error.message));
      return null
    } else {
      let messages_ = [...messages.items] 
      if(!related_to){
        messages_ = messages.items.filter(m => m.related_to === "" || m.related_to === null) 
      }
      if(messages.items.length){
        return messages_[0]
      }
      
      return null
    }
  } 



  sendMessage = async (values, target, related_to, parent, messageCore, email) => {
    values.message = values.message.trim().replace(/[\r\n]+$/, '');
    console.log("VALUES", values)
    const user = this.props.auth.user.id;
    console.log("target", target)
    const received_image = this.state.files.identification_picture || null

    let parent_ = 'root'
    console.log(user, target, related_to)
    let same_conversation_message = await this.getMessage(user, target, related_to)

    if(same_conversation_message){
      parent_ = same_conversation_message.id
    }
    console.log("same_conversation_message", same_conversation_message)
    //return   

    let data = {
      type: messageCore.type === config.MESSAGES.TYPE.COMMENTS ? config.MESSAGES.TYPE.COMMENTS: config.MESSAGES.TYPE.MESSAGE ,
      source: user,
      target: target,
      related_to: related_to,
      parent: parent_,
    /*  readed:false, */
    //si respondo comentario- core type: comment / subject: products/donations/recycling/volunteering
    //si mando primer mensaje - core type: products/donations/recycling/volunteering - subject no existe ; grabar el subject como el type
    // si mando segundo mensaje - core type: message / subject: products/donations/recycling/volunteering
      subject: messageCore.type === (config.MESSAGES.TYPE.COMMENTS || config.MESSAGES.TYPE.MESSAGE) ? messageCore.subject :  messageCore.type,
      body:values.message,
      json_data: {
        deleted_chat_for_source: 0,
        deleted_for_source: 0,
        deleted_chat_for_target: 0,
        unread_messages_for_target: 1,
        received_image,
        unread_messages_for_source: 0,
        last_message_from: 'source',
        last_message_portion:values.message.slice(0,30),
        last_message_at: new Date().toISOString()
      }
    }

    if(same_conversation_message){  // si ya existe una conversacion el json_data se debe actualizar en el root
      data.json_data = {
        "_id": uuidv4(),
        received_image
      }
    }

    console.log('MESSAGE', data);
    //return
    await this.props.onSaveMessage(data);
    console.log('AFTER SAVE MESSAGE');
    const sendedMessage = this.props.message
    if (sendedMessage.error) { 
      notify(this.t(sendedMessage.error.message));
    } else {
      console.log(sendedMessage)
      if(sendedMessage.item.parent !== "root"){ // si es un mensaje de respuesta se debe actualizar el root
        const obj = {
          id: same_conversation_message.id,
          json_data: {
            last_message_at: new Date().toISOString(),
            last_message_from: same_conversation_message.source?.id === this.props.auth.user.id ? 'source' : 'target',
            last_message_portion: values.message.slice(0,30),
            [`unread_messages_for_${!same_conversation_message.source?.id === this.props.auth.user.id ? 'source' : 'target'}`]: 1,
            answered: 1,
          }
        }
        await this.props.onSaveMessage(obj);
      }

      let mailData = {
        template: "message_notification",
        locale: "es",
        to:email,
        first_name: "",
        last_name:  "",
        params: {
            // subject: "Te enviaron un mensaje",
            user: capitalizePhrase(this.state.messageCore?.target?.username)||`${this.props.auth.user.first_name} ${this.props.auth.user.last_name}`,
            message:values?.message,
            // target:`${this.props.auth.user.first_name} ${this.props.auth.user.last_name}`,
            message_route: config.ROUTES.CHAT.replace(':root_id', same_conversation_message ? same_conversation_message.id : sendedMessage.item.id),
        }
      }


      await this.props.onSendMail(mailData);
      const internals = this.props.internals;
      if (internals.error) {
        notify(this.t(internals.error.message));
      }
        
      history.push({
        pathname: config.ROUTES.MESSAGE_SUCCESS.replace(':id', sendedMessage.item.id),
        state: {
          title: "Mensaje enviado",
          headline: "Ya podés chequear en tu casilla de mensajes enviados",
          button:{
            text: "Continuar",
            route: config.ROUTES.MESSAGES,
          },
        }
      })
  }}


  onClearImageField = ({ name }) => {
    console.log('CLEAR IMAGE FIELD:', name);
    if (name) this.setState({ files: {...this.state.files, [name]: null }});
  }


  onFileChangeImageInput = async ({file, data, name}) => {
    this.setState({ submitting: true });
    console.log('ON FILE CHANGE');
    
    data.append("identifier", `confirmloan_${ parseInt(Math.random()*1000000)}`)
    await this.props.onPictureUpload(data);
    console.log('FILES', this.props.files);
    const error = this.props.files.error;
    console.log('UPLOADED');

    if (error) {
      notify(this.t(error.message));
    } else {
      console.log("UPLOADED", this.props.files)
      this.setState({imageUpdated: true});
      this.setState({files: {...this.state.files, [name]:this.props.files.files.fileInfo.location}})
    }

    console.log('ON FILE CHANGE FINISH');
    this.setState({ submitting: false });

    return this.props.files.files.fileInfo.location
  }


  render() {

    const {messageCore, productOwner, user_profile, owner, adminUser, reclamoRelatedTo} = this.state;
    console.log("MESSAGE_CORE", messageCore, productOwner, user_profile)
    const user = this.props.auth.user.id;

      let userName= "";
      let userImg = "";
      let fullName ="";
      let title="";
      let sub_title="";
      let description = "";
      let target = " ";
      let email = null;
      let related_to = " ";
      let parent =" ";
    

      switch (messageCore && messageCore.type){
        case "reclamo":
          target = adminUser?.id//"938431e7-6c0b-43f6-a7b2-ddcec8a905ef"
          email = this.state.setting ? this.state.setting[0].value : adminUser?.email
          related_to = reclamoRelatedTo
          parent = "root"
          fullName= "Sharyco"
          userImg= adminUser?.profile_image
          userName = "Sharyco"
          title = "Escribir reclamo"
          sub_title= "Enviar reclamo"
          description = "Reclamo"
          break; 
         
        case config.MESSAGES.TYPE.MESSAGE :
        case config.MESSAGES.TYPE.COMMENTS :
          // yo soy el que mande el source del mensaje anterior
           if (messageCore.source.id === user){
            target = messageCore.target.id
            email = messageCore.target.email
            fullName= messageCore.target.json_data ? formatFullname(JSON.parse(messageCore.target.json_data)): "FALTA NOMBRE" 
            userImg= messageCore.target.profile_image
            userName = messageCore.target.userName
            title = "Ultimo mensaje enviado: "
            sub_title= "Enviar nuevo mensaje"
           }else{
            target = messageCore.source.id
            email = messageCore.source.email
            fullName= messageCore.source.json_data ? formatFullname(JSON.parse(messageCore.source.json_data)) : "FALTA NOMBRE"
            userImg= messageCore.source.profile_image
            userName = messageCore.source.userName
            title = "Mensaje recibido: "
            sub_title= "Responder mensaje"
           }
           description = messageCore.body
           related_to = messageCore.related_to
           parent = messageCore.id
          break;
    
        
        case config.TYPES.OFFERS.PRODUCTS :
          target = productOwner.id
          email = productOwner.email
          related_to = messageCore.id
          parent = " "
          fullName= productOwner.first_name + " " + productOwner.last_name
          userImg= productOwner.profile_img
          userName = productOwner.username
          title = "Consultar por el producto"
          sub_title= "Enviar mensaje al dueño"
          description = messageCore.details
          break; 
        case 'borrow' :
          target = messageCore.json_data.owner.id !== owner ? messageCore.json_data.owner.id : messageCore.json_data.borrower.id
          email = messageCore.json_data.owner.id !== owner ? messageCore.json_data.owner.email : messageCore.json_data.borrower.email
          related_to = messageCore.id
          parent = "root"
          fullName= messageCore.json_data?.product ? messageCore.json_data.product.name : messageCore.json_data.offer.name
          userImg=  messageCore.json_data?.product ? messageCore.json_data.product.img : messageCore.json_data.offer.img
          userName = messageCore.json_data.owner.id === owner ? messageCore.json_data.borrower.username : messageCore.json_data.owner.username
          title = "Consultar por el prestamo"
          sub_title= "Inicia una conversación"
          description = messageCore.json_data?.product ? messageCore.json_data.product.details : messageCore.json_data.offer.details
          break; 
          
        case config.TYPES.INITIATIVES.VOLUNTEERING :
          target = messageCore.owner.id
          related_to = messageCore.id
          parent = " "
          fullName= messageCore.owner
          userImg= messageCore.img
          userName = "x"
          description = messageCore.details
          title = "Consultar por el voluntariado"
          sub_title= "Enviar mensaje a la ONG"
          break; 

          case config.TYPES.INITIATIVES.RECYCLING:
          target = messageCore.owner.id
          related_to = messageCore.id
          parent = " "
          fullName= messageCore.owner
          userImg= messageCore.img
          userName = "x"
          description = messageCore.details
          title = "Consultar por el reciclaje"
          sub_title= "Enviar mensaje a la ONG"
          break; 

          case config.TYPES.INITIATIVES.DONATIONS:
          target = messageCore.owner.id
          related_to = messageCore.id
          parent = " "
          fullName= messageCore.owner
          userImg= messageCore.img
          userName = "x"
          description = messageCore.details
          title = "Consultar por las donaciones"
          sub_title= "Enviar mensaje a la ONG"
          break; 

          case "no_core":
          target = user_profile.id
          email = user_profile.email
          related_to = null
          parent = null
          fullName = user_profile.json_data ? user_profile.json_data.first_name + " " + user_profile.json_data.last_name : user_profile.first_name + " " + user_profile.last_name
          userImg = user_profile.profile_image
          userName = user_profile.username
          description = null
          title = "Mensaje privado"
          sub_title= "Consulta sobre lo que te interesa al usuario"
          break; 
    
        default: 
      }
    
  
    return (
      <LayoutResponsiveWSmall
        main={{ className: ""}}
        header={{ 
          className: "bg-transparent fixed text-primary",
          left: { icon: 'arrow_left', action: this.goBack },
          /* right: { icon: 'heart'} */
        }}
        loading={this.props.message.loading || this.props.message.loading}        
        container={{ className: "px-0"}}
        showGoBackButton={true}
        onGoBackClick={this.goBack}
      >
        <ToastContainer />
        <section>
          <div className="bg-light shadow">
            <div className="container p-4 mt-10 bg-white">
            {messageCore && (
              <div className="p-4 overflow-hidden border-b border-gray-100 ">
                <div className="flex  mb-5">
                  <Avatar
                    image= {userImg}
                    label={userName}
                    className="rounded-full w-10 h-10"
                    containerClassName="mr-2"
                  />
                  <div className="w-full">
                    <div className="w-full mb-3 flex justify-between">
                      <div>
                        <h4>{fullName}</h4> 
                      </div>
                    </div>
                  </div>
                </div>
                
                <div>
                  <h4 className="pb-2">{title}</h4>
                  <p>{description}</p>
                </div>
                {console.log(messageCore)}
                <Form  onSubmit={(values) => this.sendMessage(values,target, related_to, parent, messageCore, email)}>
                  {({ handleSubmit, form, submitting, pristine, values }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="w-full mt-3  mb-3 md:mb-0">
                      <h4 className="mb-2">{sub_title}</h4>
                      <Field name="message" component={TextareaInput} placeholder={this.t("Escriba su mensaje")} />
                    </div>
                    {messageCore && messageCore.type === 'reclamo' && (this.props.location.state.fromPath === "/user/returns" ||  this.props.location.state.fromPath === "/user/loans") &&
                        <Field 
                          name="identification_picture" 
                          component={ImageUploadFileInputEdit}
                          img={this.state.files.identification_picture} 
                          placeholder={this.t("Fotos del estado del producto")} 
                          label={this.t("Imagen del estado del producto.(Opcional)")}
                          inputOnChange={this.onFileChangeImageInput}
                          clickButtonClear={this.onClearImageField}
                          inputClassName="input-bordered shadow-none"
                      />
                    }
                    <Button
                      className="btn-primary btn-block"
                      title={this.t("Send")}
                      onClick={handleSubmit}
                      disabled={this.state.submitting || pristine}
                    />
                    
                  </form>
                  )}
                </Form>
             
              </div>
            )}
            </div>
          </div>
          </section>
      </LayoutResponsiveWSmall>
    ) 
  }
}

const mapStateToProps = state => {
  return {
    auth: state.users.auth,
    message: state.messages.current,
    messages: state.messages.list,
    files: state.files,
    product: state.products.current,
    owner: state.users.current,
    volunteering: state.volunteering.current,
    recycling:state.recycling.current,
    donation: state.donations.current,
    internals: state.internals,
    users: state.users.list,
    setting: state.settings.list,

  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetMessage: (params) => dispatch(MessagesActions.get(params)),
    onGetAllMessages: (params) => dispatch(MessagesActions.getAll(params)),
    onSaveMessage: (params) => dispatch(MessagesActions.saveOrUpdate(params)),
    onGetProduct: (params) => dispatch(productsAction.get(params)),
    onGetOwner: (params) => dispatch(usersActions.get(params)),
    onGetVolunteering : (params) => dispatch(volunteeringAction.get(params)),
    onGetRecycling: (params) => dispatch(recyclingAction.get(params)),
    onGetDonation : (params) => dispatch(donationsAction.get(params)),
    onSendMail: (params) => dispatch(internalsActions.sendMail(params)),
    onPictureUpload: (params) => dispatch(filesActions.upload(params)),
    onGetUser: (params) => dispatch(usersActions.getAll(params)),
    onGetSetting: (params) => dispatch(actions.getAll(params)),

  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(SendMessage));
   

  
