import React, { useState, useReducer, useEffect } from 'react';
import useUpdateEffect from '../../libraries/hooks/useUpdateEffect'
import { Form, Field } from 'react-final-form';
import FormLayout from '../../components/forms/FormLayout';
import TextWithIconButtonInput from '../../components/forms/TextWithIconButtonInput';
import { capitalize, capitalizePhrase, convertUTCDateToLocalDate, uuidv4 } from '../../libraries/utils';
import { getOwner } from '../../libraries/utils';
import Loader from '../../components/commons/Loader';
import Swal from 'sweetalert2';
import Icon from '../../libraries/icons';
import messageActions from '../../context/messages/actions';
import internalsActions from '../../context/internals/actions';
import transactionsAction from '../../context/transactions/actions';
import internalActions from '../../context/settings/actions';
import { useDispatch, useSelector } from 'react-redux';

import { ToastContainer, notify } from '../../libraries/notifications';
//import Picker from 'emoji-picker-react';
import { useTranslation } from 'react-i18next';
import config from '../../config';
import { useHistory, useParams } from 'react-router-dom';
import LayoutResponsiveWSmall from '../../components/layout/LayoutResponsiveWSmall';

const customSwal = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary mx-1',
    cancelButton: 'btn btn-outline btn-primary mx-1',
    denyButton: 'btn btn-primary mx-1',
    title: 'swal2-title',
    htmlContainer: 'swal-text',
  },
  buttonsStyling: false,
  background: '#fff',
});

const alignment = (message, owner) => {
 return message.source.id === owner || message.source === owner ? 'chat-end' : 'chat-start'
}
const style = (message, owner, chat, i) => {
  const prevMessage = chat[i - 1]
  const isPrevMessageDefined = prevMessage !== undefined
  return (message.source.id === owner || message.source === owner ? `text-black bg-gray-300 ml-2 md:ml-8 ${(!isPrevMessageDefined || prevMessage.source.id !== message.source.id) && 'rounded-tr-none mt-2'}` : `text-white bg-primary mr-2 md:mr-8 ${(!isPrevMessageDefined || prevMessage.source.id !== message.source.id) && 'rounded-tl-none mt-2'}`) + `${message.json_data?.deleted_for_all ? ' italic' : ' '}`
}
const name = (message, chat, i, name)=> {
  const prevMessage = chat[i - 1]
  return prevMessage?.source.id !== message.source.id ? <span className='text-xs px-1 py-1 text-gray-400'>{capitalizePhrase(message.source.name || name)}</span> : null
}

const seen = (seenIndex, chat, owner, i) => {
  return seenIndex === i && (i === chat.length - 1 || chat[i + 1].source.id === owner || chat[i + 1].source === owner)
}
const showDate = (current, previous, t) => {
  const currentDate = convertUTCDateToLocalDate(current?.created_at).getDate(),
    previousDate = convertUTCDateToLocalDate(previous?.created_at).getDate(),
    offset = currentDate - previousDate,
    today = new Date().getDate(),
    wasToday = today === currentDate && (currentDate !== previousDate),
    wasYesterday = today === currentDate + 1 && (currentDate !== previousDate),
    wasAnotherDate = offset > 1 || isNaN(previousDate)
  if(wasToday) return {show: true, text: capitalize(t('today'))}
  else if (wasYesterday) return {show: true, text: capitalize(t('yesterday'))}
  else if (wasAnotherDate) return { show: true, text: current.created_at.slice(0, 10) }
  else return { show: false, text: null }
}
const findLastSeen = (chat, owner) => {
  chat = chat.map(message => message.target.id !== owner ? message.readed : 0)
  console.log('OWNER', owner)
  console.log('CHAT READED', chat)
  return chat.lastIndexOf(1)
}
const focusInput = () => {
  Array.from(document.getElementsByName('body'))[0].focus()
}

const initialState = { seenIndex: null, chat: [], root: null, chatWith: null, loading: true, preview: null, image_loading: true }

const Chat = (props) => {
  // ** Redux state
  const reduxDispatch = useDispatch()
  const { t } = useTranslation();
  const saveOrUpdate = params => reduxDispatch(messageActions.saveOrUpdate(params))
  const onSendMail = params => reduxDispatch(internalsActions.sendMail(params))
  const getMessages = params => reduxDispatch(messageActions.getAll(params))
  const getLoan = params => reduxDispatch(transactionsAction.get(params))
  const onGetSetting = params => reduxDispatch(internalActions.getAll(params));
  const user = useSelector(state => state.users.auth.user)
  const owner = getOwner(user)
  const message = useSelector(state => state.messages.current)
  const messages = useSelector(state => state.messages.list)
  const transaction = useSelector(state => state.transactions.current)
  const settings = useSelector(state => state.settings.list)
  const { items } = messages
  const history = useHistory()
  const { root_id } = useParams()

  const getChat = (params = {}) => {
    params.where = {
      custom: {
        id: root_id,
        comparison: 'OR',
        parent: root_id
      }
    }
    getMessages(params)
  }
  const scrollToBottom = () => {
    const chatHTML = document.getElementById('chat')
    chatHTML.scrollTo(0, chatHTML.scrollHeight)
  }
  const isOwner = (source) => {
    return source?.id === owner
  }

  // ** useReducer for handle the local state with actions
  const reducer = (state, action) => {
    let newState = { ...state }
    const setState = (data) => {
      newState = { ...newState, ...data }
    }
    switch (action.type) {
      case 'getData':
        getChat()
        break;
      case 'setItems':
        const _items = action.data ?? items
        const chat = _items.filter(e => e.source.id === owner || e.source === owner ? !e.json_data['deleted_for_source'] : !e.json_data['deleted_for_target'])
        setState({ chat })
        console.log("__messages, setItems", chat)
        break;
      case 'addItem':
        console.log("____MYPROPS this",this)
        setState({ chat: [...state.chat, action.data] })
        scrollToBottom()
        break;
      case 'setRoot':
        const data = action.data ? { ...action.data, source: { id: action.data.source }, target: { id: action.data.target } } : state.chat.find(e => e.parent === 'root')
        setState({ root: data })
        break;
      case 'setChatWith':
        const chatWith_ = action.data ? action.data : (isOwner(state.root?.source) ? state.root.target : state.root.source);
        if (state.root) {
          setState({ chatWith: chatWith_, related_to: state.root.related_to})
        }
        break;
      case 'seen':
        setState({ seenIndex: findLastSeen(state.chat, owner) })
        console.log('STATE CHAT', state.chat)
        break;
      case 'loadingChange':
        setState({ loading: action.data })
        // document.querySelector('.emoji-scroll-wrapper').className += ' scrollbar'
        focusInput()
        break;
      case 'replaceItemFromApi':
        const item = action.data || message.item
        setState({
          chat: newState.chat
            .map(e => (e?.json_data?._id === item?.json_data?._id || e.id === item.id) ? { ...item, source: { id: item.source }, target: { id: item.target }, json_data: { ...item.json_data, picture: e?.json_data?.picture } } : e)
            .filter(e => e.source.id === owner || e.source === owner ? !e.json_data.deleted_for_source : !e.json_data.deleted_for_target)
        })
        break;
      case 'replaceItem':
        setState({ chat: state.chat.map(e => e.id === action.data.id ? action.data : e) })
        break;
      case 'previewImage':
        setState({ preview: action.data })
        break;
      case 'cleanPreview':
        setState({ preview: null })
        break;
      case 'imageLoadingReady':
        setState({ image_loading: false })
        break;
      case 'setLoan':
        setState({ loan: transaction.item })
        // console.log(transaction.item)
        break;
      default:
        throw new Error();
    }
    return newState;
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  const { seenIndex, chat, root, loading, image_loading, preview, chatWith, loan, related_to } = state

  useEffect(() => {
    const getSetting = async () => {
      await onGetSetting({ "code": "email_admin_sharyco", "type": "sharyco_admin_settings" });
    }

    if(chatWith && chatWith.email === "admin@sharyco.com"){
      getSetting();
      let { items } = settings;
      if(items[0]){
        let chatWith_ = {
          ...chatWith,
          email: items[0].value
        };
        dispatch({ type: 'setChatWith', data: chatWith_ })
      }
    }
  }, [chatWith])

  // ** Local states for boolean variables
  const [deleteMode, setDeleteMode] = useState(false)
  const [rootSeted, setRootSeted] = useState(false)
  const [pickerOpen, setPickerOpen] = useState(false)


  const sendMail = (message, target) => {
      let mailData = {
      template: "message_notification",
      locale: "es",
      to: target.email,
      first_name: "",
      last_name:  "",
      params: {
          // subject: "Te enviaron un mensaje",
          user: `${capitalize(user?.first_name)} ${capitalize(user?.last_name)}`,
          target: capitalizePhrase(target.name),
          message: message,
          message_route: config.ROUTES.CHAT.replace(':root_id', root_id),
      }
    }
    console.log("____MYPROPS mail sended", target.email)

    onSendMail(mailData);
    //const internals = this.props.internals;
    //if (internals.error) {
    //  notify(this.t(internals.error.message));
    //}
  }

  // **  This function handles the submit event of our form
  const onSubmit = async (values, form) => {
    if (validateForm(values)) {
      // ** Undeletes chat for the other part in case it has been deleted
      handleUndeleteChat()
      // create an object
      const message = {
        ...values,
        parent: root.id,
        subject: root.subject,
        source: owner,
        target: chatWith.id,
        type: 'message',
        json_data: {
          _id: uuidv4(),
        }
      }
      dispatch({ type: 'addItem', data: { ...message, source: { id: message.source }, target: { id: message.target }, loading: true } })
      // send message
      await saveOrUpdate(message)
      // push the message to the array, while sending it, it will have a loading animation
      
      //FIXME de donde saco al otro??
      sendMail(values.body, state.chatWith)
      console.log("____MYPROPS", props, state)
      //if(state.chat.length){
      //  let chat = state.chat[state.chat.length-1]
      //  let mailData = {source: chat.source, target: chat.target}
      //  console.log("____MYPROPS", mailData)
      //  
      //}
      
      form.reset()

    }
  }

  // ** Delete Message function
  const deleteMessage = async (index, message) => {
    const { id, source } = message
    const isOwnerMessage = source.id === owner || source === owner
    customSwal
      .fire({
        title: t('Delete message?'),
        icon: 'warning',
        showDenyButton: isOwnerMessage && !message?.json_data?.deleted_for_all,
        showCancelButton: true,
        confirmButtonText: t('For me'),
        denyButtonText: t('For everyone'),
        cancelButtonText: t('Cancel'),
      })
      .then(({ isConfirmed, isDenied }) => {
        // Depending on the option the user choose. The message with be deleted for the user, or for everyone
        if (isConfirmed || isDenied) {
          const obj = isDenied ? { id, json_data: { deleted_for_all: 1 }, body: 'Mensaje eliminado' } : { id, json_data: isOwnerMessage ? { deleted_for_source: 1 } : { deleted_for_target: 1 } }
          delete message.json_data.picture
          dispatch({ type: 'replaceItem', data: { ...message, loading: true, body: 'Eliminando' } })
          // ** Its a path request. We create an object here and manipulate the json server. Then we save the modified object
          saveOrUpdate(obj)
        }
      })
  }

  // ** This function undeletes the chat for the other part in case it was deleted. It means that if the other part deletes a chat, and recieve a message on the same chat. It will apear again
  const handleUndeleteChat = () => {
    const isOwnerChat = root.source.id === owner
    if (root.json_data[`deleted_chat_for_${isOwnerChat ? 'target' : 'source'}`]) {
      const obj = { id: root.id, json_data: { [`deleted_chat_for_${isOwnerChat ? 'target' : 'source'}`]: 0 } }
      saveOrUpdate(obj)
    }
  }

  // ** This is for pausing the conversation. This can only be ejected by the professional
  const handlePauseChat = () => {
    const obj = { id: root.id, json_data: { paused: root.json_data.paused ? 0 : 1 } }
    if (obj.json_data.paused) {
      setPickerOpen(false)
    } else {
      focusInput()
    }
    saveOrUpdate(obj)
  }

  const validateForm = (values) => {
    return values.body && true
  };

  // ** This effect loads all the data into our local state. Sets the root element, the chat state and handles seen behavior of messages
  useUpdateEffect(() => {

    const handleSeen = (array) => {
      console.log("HANDLE SEEN", array)
      array.forEach((item) => {
        if (item.target?.id === owner && !item.readed) {
          saveOrUpdate({ id: item.id, readed: 1 })
        }
      })
    }
    const handleGetLoan = () => {
      if (root?.related_to) {
        getLoan({id: root?.related_to})
      }
    }
    if (!messages.loading) {
      if (messages.error) {
        notify(t(messages.error.message));
      } else {
        const { items } = messages
        dispatch({ type: 'setItems' })
        dispatch({ type: 'setRoot' })
        handleGetLoan()
        dispatch({ type: 'setChatWith' })
        dispatch({ type: 'seen' })
        handleSeen(items)
        console.log('REDUX MESSAGES', messages)
      }
    }
  }, [messages])

  // ** This modified the root element to change the unread messages to 0 when entering a chat
  useUpdateEffect(() => {
    const handleNotifyReadedContent = () => {
      if (!rootSeted) {
        if (root?.source.id) {
          const isOwnerChat = root.source.id === owner
          if (root.json_data[`unread_messages_for_${isOwnerChat ? 'source' : 'target'}`] > 0) {
            const obj = { id: root.id, json_data: { [`unread_messages_for_${isOwnerChat ? 'source' : 'target'}`]: 0 } }
            saveOrUpdate(obj)
          }
        }
        setRootSeted(true)
      }
    }
    handleNotifyReadedContent()
  }, [root])
  useUpdateEffect(() => {
    if (!transaction.loading) {
      if (transaction.error) {
        notify(t(transaction.error.message));
      } else {
       dispatch({type: 'setLoan'})
      }
    }
  }, [transaction])

  // Life cycle
  useEffect(() => {
    dispatch({ type: 'getData' })
  }, [])


  // ** This effect handles scroll behavior when entering the chat. It scroll at the bottom, but if there is messages we haven't read, scrolls to the first unseen message
  useUpdateEffect(() => {
    const chatHTML = document.getElementById('chat')
    const images = Array.prototype.slice.call(chatHTML.querySelectorAll('img'))
    Promise.all(
      images.filter(img => !img.complete).map(img => new Promise(
        resolve => { img.onload = img.onerror = resolve }
      ))).then(() => {
        const scrollHandler = (chat) => {
          if (!loading) return
          chat = chat.map(message => message.source.id === owner ? 1 : message.readed)
          const element = document.getElementById(`message${chat.indexOf(0)}`)
          if (element) {
            element.scrollIntoView()
          } else {
            chatHTML.scrollTo(0, chatHTML.scrollHeight)
          }
        }
        scrollHandler(chat)
        dispatch({ type: 'imageLoadingReady' })
      })
  }, [chat.length])

  // ** This effect catches when a message has been saved. And replace the pseudo message that has been displaying on the chat while the real message was saving
  useUpdateEffect(() => {
    const handleNotifyNewContent = () => {
      if (root.source.id) {
        const isOwnerChat = root.source.id === owner
        const unreadMessages = chat.map(message => message.target.id !== owner ? message.readed : 1).filter(e => !e).length
        if (unreadMessages > 0) {
          const obj = {
            id: root.id,
            json_data:
            {
              [`unread_messages_for_${isOwnerChat ? 'target' : 'source'}`]: unreadMessages,
              last_message_portion: message.item.body.slice(0, 30),
              last_message_from: isOwnerChat ? 'source' : 'target',
              last_message_at: new Date().toISOString()
            }
          }
          console.log('NOTIFY NEW CONTENT', obj.json_data)
          saveOrUpdate(obj)
        }
      }
    }
    if (!message.loading) {
      if (message.error) {
        notify(t(message.error.message));
      } else {
        if (message.item) {
          console.log('MESSAGE REPLACE')
          if (message.item.parent === 'root') {
            dispatch({ type: 'setRoot', data: message.item })
          } else {
            dispatch({ type: 'replaceItemFromApi' })
            // ** It also modify the root element of the chat, to notify a new message on the inbox/sent tray of the other part
            handleNotifyNewContent()
          }
        }
      }
      dispatch({ type: 'seen' })
    }
  }, [message])
 const goBack= ()=>{
  history.push({
    pathname: config.ROUTES.MESSAGES,
    state: { fromPath: config.ROUTES.CHAT.replace(':root_id', root_id) }
  })
}

  // console.log("__messages", chat)
  return (
    <LayoutResponsiveWSmall
      main={{ className: "" }}
      header={{        
        className: "w-auto  flex justify-between p-3 lg:rounded-lg",
        left: { icon: 'arrow_left', action: () => { history.push(config.ROUTES.MESSAGES) } },
        title:  (related_to && (loan?.json_data?.product || loan?.json_data?.offer)) ? (loan && `${capitalize(t('Prestamo'))} ${capitalizePhrase(loan.json_data.product ? loan.json_data.product.name : loan.json_data.offer.name)} - ${t(capitalizePhrase(chatWith?.name))}`) : t(capitalizePhrase(chatWith?.name)),
        right: { 
          icon: 'user', 
          action: () => { 
            goBack()
          }, 
          className: "h-4 w-4" 
        }
      }}
      container={{ className: "px-0 relative" }}
      showGoBackButton={true}
      onGoBackClick={()=> goBack()}
    >
      {/* LOADER */}
      {(related_to ?  (loan && true) : !messages.loading && !image_loading) || <div className='flex w-full bg-base-200 z-10'><Loader /></div>}
      {/* CHAT */}
      <div className='flex flex-col bg-base-200 p-2 h-full w-full'>
        <ToastContainer />

        {<div className='flex justify-between px-5 bg-base-200 w-full py-2'>
          {/* <button className='text-primary border border-primary px-3 py-1 rounded-full font-semibold text-xs' onClick={() => handlePauseChat()}>{root?.json_data?.paused || loan?.status === 'completed' ? 'Unpause' : 'Pause'}</button> */}
          <button type='button' onClick={scrollToBottom} className='text-primary border border-primary px-3 py-1 rounded-full font-semibold text-xs'>{t('Go to bottom')}</button>
        </div>}

        {/* PREVIEW */}
        {preview &&
          <div className='flex pt-10 bg-white justify-center overflow-hidden items-center w-full h-full'>
            <p className='absolute top-3 left-10'>Preview</p>
            <button onClick={() => dispatch({ type: 'cleanPreview' })}><Icon className="w-5 h-5 absolute top-3 left-3" name='close' /></button>
            <div className='w-36'><img src={preview.src} alt="" /></div>
          </div>}


        {/* MESSAGES CONTAINER */}
        <div id='chat' className='flex-grow overflow-y-scroll scrollbar'>
          {!preview && chat.map((message, i, array) => {
            const date = convertUTCDateToLocalDate(message['created_at'])
            // const month = capitalize(date.toLocaleDateString(undefined, { month: 'long' }).substring(0, 3))
            // const day = date.toLocaleDateString(undefined, { day: 'numeric' })
            const time = date.toLocaleTimeString(undefined, { hour: 'numeric', minute: 'numeric' })
            const { show, text } = showDate(message, array[i - 1], t)
            return (
              // <div key={message.id || 'load' + i}>
              //   {show && <div className='flex justify-center'><p className='px-3 py-1 rounded-full  text-xs bg-gray-200 text-gray-500 w-max my-4'>{text}</p></div>}
              //   <div id={`message${i}`} className={`flex flex-col mb-1 ${alignment(message, owner)}`}>
              //     {/* {name(message, array, i, user.name)} */}
              //     <span className={`px-4 break-all py-1 items-end flex justify-end rounded-xl text-sm ${style(message, owner, array, i)}`}>
              //       <span className='flex flex-col'>
              //         {message?.json_data?.picture && !message?.json_data?.deleted_for_all && <img className='image-message mb-2' src={message.json_data.picture} alt="" />}
              //         {message?.json_data?.received_image && !message?.json_data?.deleted_for_all && <img className='image-message mb-2 max-h-60' src={message.json_data.received_image} alt="" />}
              //         {message.body}
              //       </span>

              //       <span className='text-xs ml-4 break-normal'>
              //         {message.loading ?
              //           <Loader containerClassName="px-1 py-1 ml-2 relative" spinnerClassName="text-primary h-3 w-3" />
              //           :
              //           (deleteMode ? <button onClick={() => deleteMessage(i, message)}><Icon className="h-3 w-3" name="trash" /></button> : time)}
              //       </span>
              //     </span>
              //     {seen(seenIndex, array, owner, i) && <span className='text-xs px-4 py-1 text-gray-400'>{capitalize(t('seen'))}</span>}
              //   </div>
              // </div>
              <div key={message.id || 'load' + i} id={`message${i}`} className={`flex flex-col mb-1`}>
              {show && <div className='flex justify-center'><p className='px-3 py-1 rounded-full  text-xs bg-gray-200 text-gray-500 w-max my-4'>{text}</p></div>}
              <div className={`chat ${alignment(message, owner)}`}>
                <div class="chat-header opacity-50">
                  {message.loading ?
                    <Loader containerClassName="p-0 pb-1 ml-2 relative" spinnerClassName="text-secondary h-3 w-3" />
                    :
                    (deleteMode ? <button onClick={() => deleteMessage(i, message)}><Icon className="h-3 w-3" name="trash" /></button> : time)
                  }
                </div>
                <div class={`flex flex-col chat-bubble ${alignment(message, owner) === "chat-start" ? 'chat-bubble-primary' : 'bg-gray-300 text-black'}`}>
                  {message?.json_data?.picture && !message?.json_data?.deleted_for_all && <img className='image-message mb-2 max-h-60' src={message.json_data.picture} alt="" />}
                  {message?.json_data?.received_image && !message?.json_data?.deleted_for_all && <img className='image-message mb-2 max-h-60' src={message.json_data.received_image} alt="" />}

                  {message.body}
                </div>
                <div className='chat-footer'>
                  {seen(seenIndex, array, owner, i) && <span className='text-xs px-4 py-1 text-gray-400'>{capitalize(t('seen'))}</span>}
                </div>
              </div>
            </div>
            )
          })}
          {root?.json_data?.paused || (root?.subject !== 'reclamo' && loan?.status === 'completed') ? <p className='text-center p-3 text-gray-400'>{t(related_to ? 'Conversación finalizada' : 'Conversación pausada')}</p> : null}
        </div>

        {/* MESSAGE INPUT */}
        <Form
          initialValues={{}}
          onSubmit={onSubmit}
          mutators={{
            insertEmoji: (args, state, utils) => {
              utils.changeValue(state, 'body', () => (args[1] || '') + args[0].emoji)
            },
          }}
        >
          {({ handleSubmit, form, values }) => {
            return (
              <FormLayout form={form} onSubmit={handleSubmit}>
                {<div className='w-full mb-10'>
                  <div className='input flex items-center m-1'>
                    {/*<div className='border-r pr-2'>
                      <button type='button' className={`${messages.loading || root?.json_data?.paused || loan?.status === 'completed' ? 'disabled' : null} flex`}><Icon onClick={() => {
                        if (root?.json_data?.paused || loan?.status === 'completed') return
                        setPickerOpen(!pickerOpen)
                        focusInput()
                      }} className="h-4 w-4" name={pickerOpen ? 'close' : "emoji_face"} />
                      </button>
                    </div>*/}
                    <Field
                      name="body"
                      component={TextWithIconButtonInput}
                      placeholder={capitalize(t('send a message'))}
                      readOnly={messages.loading || root?.json_data?.paused || (root?.subject !== 'reclamo' && loan?.status === 'completed')}
                      icon={'paper_airplane'}
                      inputClassName={'pl-2 ml-2'}
                    />
                  </div>
                  {/*<div className={!pickerOpen ? 'hidden' : null}>
                    <Picker
                      className='w-full'
                      searchPlaceholder='Search emojis'
                      pickerStyle={{ width: '100%', height: '40vh' }}
                      onEmojiClick={(e, emoji) => {
                        form.mutators.insertEmoji(emoji, values.body)
                        focusInput()
                      }}
                      disableAutoFocus
                    />
                  </div>*/}
                </div>}
              </FormLayout>
            );
          }}
        </Form>
      </div>
    </LayoutResponsiveWSmall>
  );
};

export default Chat;