import Button from "../../components/commons/Button";
import imgGift from "../../assets/img/img-gift.png";
import React, { Component } from 'react'
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import actions from "../../context/actions/actions";
import actionBenefits from "../../context/benefits/actions";
import settingActions from "../../context/settings/actions";
import { notify } from "../../libraries/notifications";
import IconCoins from "../../components/customs/IconCoins";
import Loader from "../../components/commons/Loader";
import { history } from "../../routes";
import config from "../../config/";
import IconCredits from "../../components/customs/IconCredits";
 import { CardOnboarding } from "./FlowStep1";
import transactionsAction from "../../context/transactions/actions";
import { getObjectWithJsonDataToFormValues } from "../../libraries/utils";

 

class FlowStep6 extends Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.id = this.props.match.params.id;
    this.idFlow = this.props.match.params.idFlow;
    this.currentUrl = this.props.location?.pathname;
    this.state = {
      action: null,
      benefit: null
    }
  }

  async getFlow() {
    const flow = config.ACTION_BY_DEFAULT.find(f => f.id == this.idFlow)
    if (flow) {
      if (flow.challenge?.json_data.benefits?.quantity == null) {
        // await this.props.onGetBenefit(flow?.challenge?.json_data.benefits?.benefit)
        // const error = this.props.benefit.error
        // if (error) {
        //   notify(error)
        // } else {
        //   this.setState({ flow: flow, challenge: flow?.challenge, benefit: this.props.benefit.item })
        // }
        await this.getSetting()
      }
      this.setState({ flow: flow, challenge: flow?.challenge, action: flow?.action })
    }
  }
  getSetting = async () => {
    const params = {
      "type": "sharyco_settings",
      "owner": this.props.user.unique_id,
      "code": "prize_by_company"
    }
    await this.props.onGetSetting(params);
    const { settings } = this.props;
    if (settings.error) {
      notify(this.t(settings.error.message));
    } else {
      if (settings?.items?.length > 0) {
        const idBenefit = settings?.items[0]?.value
        if (idBenefit) {
          await this.getBenefit(idBenefit)
          this.setState({ idBenefit });
        }
      }
    }
  }
  async getBenefit(id) {
    await this.props.onGetBenefit(id)
    const error = this.props.benefit.error
    if (error) {
      notify(error)
    } else {
      this.setState({ benefit: this.props.benefit.item })
    }
  }

  componentDidMount() {
    this.getFlow();
  }

  render() {
    const ganoUnPremio = this.state.challenge?.json_data.benefits?.quantity === null && this.state.benefit ? true : false;
    const isVoucher = this.state.benefit?.json_data?.is_voucher;
    return (
      <main className="h-full text-center ">
        {this.props.benefit?.loading && <Loader />}
        {this.state.challenge &&
          <CardOnboarding>
            <div className="h-80 md:h-72 flex items-center">
              <img src={imgGift} alt="" width={"250px"} />
            </div>
            <div className="">
              <h3 className="text-primary mb-5 text-xl">¡Ganaste!</h3>
              <p className="text-primary text-lg">{this.t("por cumplir el desafío")}</p>
              <p className="text-primary text-lg mb-10 font-bold">{this.t(`"${this.state.challenge?.name}"`)}</p>
              {this.state.challenge?.json_data.benefits?.benefit && <div className="flex flex-row  justify-center mb-10">
                {this.state.challenge?.json_data.benefits?.benefit == 'coins' && this.state.challenge?.json_data.benefits?.quantity !== null && 
                <IconCoins iconClassName="h-10" color='green' className="bg-success bg-opacity-10 py-4 px-4 flex flex-col items-center justify-center gap-0 rounded" coins={this.state.challenge?.json_data.benefits?.quantity} leyend={'Pts Shary'} numberClassName={'text-base-content text-2xl font-bold mt-1'} />}
                {this.state.challenge?.json_data.benefits?.benefit == 'credits' && this.state.challenge?.json_data.benefits?.quantity !== null && <IconCredits iconClassName="h-10" color='green' className="bg-success bg-opacity-10 py-4 px-4 flex flex-col items-center justify-center gap-1 rounded" credits={this.state.challenge?.json_data.benefits?.quantity} leyend={'Créditos'} numberClassName={'text-base-content text-2xl font-bold mt-1'} legendClassName="block"  />}
                {ganoUnPremio && this.state.benefit && 
                <CardBenefit benefit={this.state.benefit} />}
                {ganoUnPremio && this.props.benefit.error && <p>Ocurrio un error al cargar el beneficio</p>}
              </div>}

            </div>
            {
              ganoUnPremio?
             <div className="flex justify-between w-full gap-4">
               <Button
               className="flex-1 btn btn-outline md:mt-5"
               title={this.t("Ahora no").toUpperCase()}
               onClick={() => history.push(`/`)}
               />
              {isVoucher ? (
                <Button
                  className="flex-1 btn btn-outline md:mt-5"
                  title={this.t("Canjear").toUpperCase()}
                  onClick={() => history.push(config.ROUTES.PRIZES.DETAIL.replace(':id', this.state.benefit?.id))}
                />
              ) : (
                <Button
                  className="flex-1 btn btn-primary md:mt-5"
                  title={this.t("Ver Producto").toUpperCase()}
                  onClick={() => history.push(config.ROUTES.PRIZES.DETAIL.replace(':id', this.state.benefit?.id))}
                />
              )}
             </div>
            :
              <Button
              className="btn-block btn-primary md:mt-5"
              title={this.t("Siguiente").toUpperCase()}
              onClick={() => history.push(config.ROUTES.FLOW.STEP7.replace(':idFlow',this.idFlow))}
              />
            }
          </CardOnboarding>
        }
      </main>
    )
  }
}

const mapStateToProps = state => {
  return {
    action: state.actions.current,
    benefit: state.benefits.current,
    settings: state.settings.list,
    user: state.users.auth.user,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onGetAction: (params) => dispatch(actions.get(params)),
    onGetBenefit: (params) => dispatch(actionBenefits.get(params)),
    onGetSetting:(params)=>dispatch(settingActions.getAll(params))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(FlowStep6));

const CardBenefit = ({ benefit }) => {
  return (
    <div className="shadow-lg  flex flex-col  w-80 rounded-lg overflow-hidden bg-white">
      <div className=" flex px-5 overflow-hidden bg-gray-50 min-h-16">
        <img className="max-h-44 mx-auto" alt="benefit" src={benefit?.json_data?.picture} />
      </div>
      <div className="border-t-[1px] py-2 px-3">
        <h3 className="text-start text-base">{benefit?.name}</h3>
      </div>
    </div>
  )

}