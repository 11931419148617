import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form'

import userActions from '../../context/users/actions';
import { ToastContainer, notify } from '../../libraries/notifications';
import { composeValidators, validateEmail, validateIsfilled, getTimeZone, capitalize, optionSelectGenerator, extrapolateProp, formatOnlyNumberInput } from '../../libraries/utils';
import { history } from '../../routes';
import config from '../../config';

import LayoutSmall from '../../components/layout/LayoutSmall';
import Button from '../../components/commons/Button';
import TextInput from '../../components/forms/TextInput';
import Terms from '../../components/customs/Terms';
import CheckboxInput from '../../components/forms/CheckboxInput';
import SelectInput from '../../components/forms/SelectInput';
// import DatepickerInput from '../../components/forms/DatepickerInput';
import { dateComponents } from '../../libraries/utils';
import companiesActions from '../../context/companies/actions';
import BirthDateInput from '../../components/forms/BirthDateInput';
import { zonedTimeToUtc } from 'date-fns-tz';
import logo from '../../assets/img/logo.png';


class Signup extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      submitting: false,
      signupData: {},
      firstClickTerms: true,
      showTerms: false,
      loading: true,
      company_name: '',
    }
  }
  
  async componentDidMount() {
    console.log('SIGNUP PAGE');
    const params = this.props.match.params;
    if (params.hash) {
      await this.checkHash(params);
      this.getCompany();
    }
  }
 
  checkHash = async (params) => {
    await this.props.onGetFromHash(params);
    const user = this.props.user;
    if (user.error) {
      history.push(config.ROUTES.LOGIN);
    } else {
      //if (user.item.status === "active") {
      //  setTimeout(()=>history.push(config.ROUTES.LOGIN), 5000)
      //  notify(capitalize(this.t('Este usuario ya está activo, inicia sesión.')))
      //} else {
        this.setState({ user: extrapolateProp(user.item, "json_data"), preRegistered: true });
      //}
    }
  };

  getCompany = async () => {
    await this.props.onGetCompany(this.props.user.item.unique_id);
    const { company } = this.props
    if (company.error) {
      notify(this.t(company.error))
    } else {
      if(company.item){
        this.setState({ company_name: company.item?.name })
      }
    }
    this.setState({ loading: false })
  }

  checkUserMail = async (email) => {
    await this.props.onGetUsers({email});
    const users = this.props.users;
    if (users.error) {
      //history.push(config.ROUTES.LOGIN);
    } else {
      if(users.items.length){
        this.setState({ user: extrapolateProp(users.items[0], "json_data"), preRegistered: true });
        console.log("CHECKHASH", extrapolateProp(users.items[0], "json_data"))
      }
    }
  };

  finalizeSignUp = async (data) => {
    data.id = this.state.user.id 

    await this.props.saveOrUpdate({
      id: this.state.user.id,
      phone: data.phone,
      birth_date: data.birth_date,
      json_data: {
        first_name: data.first_name,
        last_name: data.last_name,
        gender: data.gender,
        labor_identification_code: data.labor_identification_code,
      }
    });
    const user = this.props.user;
    this.setState({ submitting: false });
    if (user.error) {
      notify(this.t(user.error.message));
    } else {
      history.push({
        pathname: config.ROUTES.VERIFY.replace(':hash', this.props.user.item.hash),
        state: {
          preSignup: true
        }
      });
    }
  }

  onSubmit = async (values) => {
    this.setState({submitting: true, signupData: {...values}});
    
    const data = {
      ...values,
      time_zone: getTimeZone(),
      user_type: config.USER_TYPE.REGULAR,
      // birth_date: values.birth_date.toISOString()
    }

    data.birth_date = new Date (dateComponents(values.birth_date)[3].dateOnly);
    // const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    // const utcDate = zonedTimeToUtc(data.birth_date, timeZone)
    // data.birth_date = utcDate

    if(!data.terms) return
    delete data.terms

    //data.birth_date = dateComponents(values.birth_date)[3].dateOnly;
    if(!this.state.user){
      await this.props.onRegister(data);
      const user = this.props.user;
      if (user.error) {
        if(user.error.code){
          if(user.error.code === 'REGISTER_ERROR_NOT_ACTIVE'){
            await this.checkUserMail(values.email);
            this.finalizeSignUp(data);
            return;
          }
        }
        this.setState({ submitting: false });
        notify(this.t(user.error.message));
      } else {
        //history.push(config.ROUTES.PASSWORD.replace(':hash', this.props.user.item.hash));
        history.push(
          config.ROUTES.VERIFY.replace(':hash', this.props.user.item.hash)
        );
      }
    } else {
      this.finalizeSignUp(data)
    }
  }

  onTerms = () => {
    history.push(config.ROUTES.TERMS);
  }

  onLogin = () => {
    history.push(config.ROUTES.LOGIN);
  }

  render() {
    const { showTerms, firstClickTerms, company_name, loading } = this.state;
    const required = value => (validateIsfilled(value) ? undefined : this.t("This field is required"))
    const email = value => (validateEmail(value) ? undefined : this.t("Not a valid email"))
    const today = new Date(2010, 9, 10);
    const genders = [
      { value: "man", label: capitalize(this.t("man")) },
      { value: "woman", label: capitalize(this.t("woman")) },
      { value: "other", label: capitalize(this.t("Other"))},
      { value: "not_say", label: capitalize(this.t("Prefiero no decir"))},
    ];

    const customHeaderStyle = {
      backgroundColor: 'transparent', // Cambia el color de fondo
      // Otros estilos personalizados
  };

    return (
      <>
        <img src={logo} className='h-12 mx-auto mt-2'/>
        <div className={'absolute top-0 left-0 flex items-end w-full h-full bg-[#00000066] z-20' + (showTerms ? '' : ' hidden')}>
          <div className='h-3/4 bg-base-300 w-full rounded-t-3xl p-8'>
            <h2 className='text-center font-medium'>{capitalize(this.t('Términos y condiciones'))}</h2>
            <div className='mt-4 h-[90%] overflow-y-scroll flex flex-col'>
            <Terms className={"flex-1 text-justify"}/>
              <div className='flex gap-3'>
                <Button title={capitalize(this.t('acepto'))} className='mt-3 btn-secondary btn-sm'
                  onClick={()=>this.setState({ showTerms: false }, this.form.mutators.setTerms)}
                />
                <Button title={capitalize(this.t('no acepto'))} className='mt-3 btn-primary btn-sm'
                  onClick={()=>this.setState({ showTerms: false })}
                />
              </div>
            </div>
          </div>
        </div>
        <ToastContainer/>
        <h2 className="text-primary text-center mb-5 mt-5">{this.t("Register")}</h2>
        <div className='card bg-primary-focus p-3 mx-6 mb-4 text-center lg:max-w-lg lg:mx-auto'>
          {company_name &&
            <>
              <p className='text-white text-base'>Registrate en el grupo de</p>
              <p className='text-yellow-400 font-bold text-lg'>{company_name}</p>
            </>
          }
        </div>
        <Form
          initialValues={this.state.user || {}}
          onSubmit={this.onSubmit}
          mutators={{setTerms: (args, state, utils) => {
            utils.changeValue(state, 'terms', () => true)
          }}}
        >
          {({ handleSubmit, form, submitting, pristine, values, invalid }) => {
            this.form = form;
          return <>
            <form onSubmit={handleSubmit} className="w-full max-w-lg mx-auto px-4">
              <div className="w-full mb-3 md:mb-0">
                <Field name="first_name" component={TextInput} placeholder={this.t("First name")} label={this.t("First name")} validate={required}
                  inputClassName='rounded-xl outline outline-1 outline-[#2A818933]' readOnly/>
              </div>
              <div className="w-full mb-3 md:mb-0">
                <Field name="last_name" component={TextInput} placeholder={this.t("Last name")} label={this.t("Last name")} validate={required}
                  inputClassName='rounded-xl outline outline-1 outline-[#2A818933]' readOnly/>
              </div>
              <div className="w-full mb-3 md:mb-0">
                <Field readOnly name="email" component={TextInput} placeholder={this.t("Email")} label={this.t("Email")} validate={composeValidators(required, email)} parse={ v => v.trim() }
                  inputClassName='rounded-xl outline outline-1 outline-[#2A818933]'/>
              </div>
              <div className="w-full mb-3 md:mb-0">
                <Field name="identification" component={TextInput} placeholder={this.t("Número de Identificación Personal (DNI)")} label={this.t("Número de Identificación Personal (DNI)")} validate={required}
                  inputClassName='rounded-xl outline outline-1 outline-[#2A818933]' readOnly={!!this.state.user?.identification} parse={v => formatOnlyNumberInput(v,10)}/> 
              </div>
              <div className="w-full mb-3 md:mb-0">
                <Field name="labor_identification_code" component={TextInput} placeholder={this.t("CUIL")} label={this.t("CUIL")}
                  inputClassName='rounded-xl outline outline-1 outline-[#2A818933]'/> 
              </div>

              {/*fixme: ¿pidieron fecha de nacimiento?*/}
              {/*fixme: traer el componente de birthdate input de innos (hecho con selects)*/}
              <div className="w-full flex gap-4 items-center mb-3 md:mb-0">
                {/* <Field name="birth_date" component={DatepickerInput} placeholder="01/01/1999" validate={required} showYear 
                  inputClassName='rounded-xl outline outline-1 outline-[#2A818933]' /> */}
                <Field 
                  name="birth_date" 
                  component={BirthDateInput}
                  label={this.t("Fecha de nacimiento")}
                  className='w-full'
                  // initialValue={today}
                />
              </div>

              <div className="w-full mb-3 md:mb-0">
                <Field name="phone" component={TextInput} placeholder={this.t("Phone number")} label={this.t("Phone number")} validate={required}
                  inputClassName='rounded-xl outline outline-1 outline-[#2A818933]'/>
              </div>

              <div className="w-full mb-3 md:mb-0">
               <Field name="gender" component={SelectInput} placeholder={this.t("Género")} label={this.t("Género")} empty={capitalize(this.t("Seleccione su género"))} validate={required}
                  options={genders} inputClassName='rounded-xl outline outline-1 outline-[#2A818933]' selectClassName="w-full"/>
              </div>


              <div className="w-full mb-3 md:mb-0" onClick={()=>{
                  if(firstClickTerms) {
                    this.setState({ firstClickTerms: false, showTerms: true })
                  }    
                }}
              >
                <Field name="terms" component={CheckboxInput} label={this.t("I accept terms and conditions")}
                  validate={required}
                  className={firstClickTerms ? 'pointer-events-none' : ''}
                  labelClassName='justify-start items-center gap-2'
                  checkboxClassName="checkbox-secondary rounded-full border-none bg-white"
                  type="checkbox"
                />
              </div>
              <div className="w-full">
                <Button
                  className="btn-secondary btn-block"
                  title={this.t("Next")}
                  onClick={handleSubmit}
                  disabled={invalid || this.state.submitting || pristine}
                />
              </div>
            </form>
          </>
          }}
        </Form>
        <div className="w-full px-3 mt-5">
          <Button
            className="btn-link btn-block underline text-primary"
            title={this.t("Términos y condiciones")}
            onClick={()=>this.setState({ showTerms: true })}
          />
        </div>
        <div className="mt-2 p-2 flex justify-center items-center">
          <p className="label-text text-center mr-1">{this.t("¿Ya tienes cuenta??")}</p>
          <Button
            className="btn-link mb-0 underline text-primary"
            title={this.t("Login with your user")}
            onClick={(this.onLogin)}
          />
        </div>
      </>
    ) 
  }
}

const mapStateToProps = state => {
  return {
    user: state.users.current,
    company: state.companies.current,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onRegister: (params) => dispatch(userActions.saveOrUpdate(params, 'register')),
    onGetFromHash: (params) => dispatch(userActions.getFromHash(params)),
    onGetUsers: (params) => dispatch(userActions.getAll(params)),
    onGetCompany: (params) => dispatch(companiesActions.get(params)),
    saveOrUpdate: (params) => dispatch(userActions.saveOrUpdate(params, 'register'))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(Signup));
