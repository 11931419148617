import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { ToastContainer, notify } from '../../libraries/notifications';
import { history } from '../../routes';
import config from '../../config';

import Button from '../../components/commons/Button';
import IconCoins from '../../components/customs/IconCoins';
import IconPoints from '../../components/customs/IconPoints';

import { isEmptyObject, capitalize } from '../../libraries/utils';
import productsAction from '../../context/products/actions';
import messagesActions from '../../context/messages/actions';
import internalsActions from '../../context/internals/actions';
import LayoutResponsiveWSmall from '../../components/layout/LayoutResponsiveWSmall';

class Step3 extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      type: null,
      category: null,
      productItems: {},
      productImg:"",
      editing:false,
      productId:"",
      submitting: false,
    }   
  }

  componentDidMount() {
    console.log('STEP 3', this.props.location);
    if (isEmptyObject(this.props.location.state)) {
      history.push(config.ROUTES.HOME);
    } else {
      const {productItems, type, category, productImg, editing, productId, fromRequest, requesterId, requester, categoryFromRequest } = this.props.location.state;
      this.setState({ type, category, productItems, productImg, editing, productId  });
      if(fromRequest){
        console.log("FROM REQUEST", fromRequest, requesterId, requester, categoryFromRequest)
        this.setState({ fromRequest, requesterId, requester, categoryFromRequest })
        //this.setState({detailsFromRequest})
      }
    }
  }

  sendMail = async (data) => {
    const {user} = this.props.auth
    let subject = null
    let template = null
  
    switch (data.type){
      case "products":
        subject = "Subiste un nuevo producto"
        template = "new_product"
        break;
      case "services":
        subject = "Subiste un nuevo servicio"
        template = "new_service"
        break;
      case "favors":
        subject = "Subiste un nuevo favor"
        template = "new_favor"
        break; 
      default:
        break; 
    }


    let mailData = {
      template,
      locale: "es",
      to: user.email,
      first_name: user.first_name,
      last_name: user.last_name,
      params: {
        subject,
        user_name: `${user.first_name} ${user.last_name}`,
        product_name: data.name,
      }
    }

    if(data.type === "products"){
      mailData.params.product_img = data.json_data.img
    }

    await this.props.onSendMail(mailData);
    const internals = this.props.internals;
    if (internals.error) {
      notify(this.t(internals.error.message));
    }
  }

  sendMailToRequester = async (data, newProduct) => {
    if(this.state.requester?.email){
      const { user } = this.props.auth
      const {requester} = this.state
      // let subject = "Tu pedido recibió una publicación"
      let template = "new_product_to_requester"

      let mailData = {
        template,
        locale: "es",
        to: requester.email,
        first_name: requester.first_name,
        last_name: requester.last_name,
        params: {
          // subject,
          owner_name: `${user.first_name} ${user.last_name}`,
          user_name: `${requester.first_name} ${requester.last_name}`,
          product_name: data.name,
          request_name: this.state.categoryFromRequest,
          product_id: `${newProduct.type}/${newProduct.id}`,
        }
      }

      await this.props.onSendMail(mailData);
      const internals = this.props.internals;
      if (internals.error) {
        notify(this.t(internals.error.message));
      }
    }
  }
  
  goBack = () => {
    console.log("back")
    const { editing, } = this.state
    if(editing){
      history.push(config.ROUTES.USER.INVENTORY)
    } else {
      history.push({pathname: config.ROUTES.OFFERS.DETAILS.replace(":type", this.props.location.state.type ), state: this.props.location.state})
    }
  }

  saveProduct = async (success_route, route_title, editing_title)=> {
    const { type,category,productItems,productImg,productId,editing} = this.state;
    const {name, details, brand, state, coins, points, new_brand} = productItems
    const img = productImg
    let data = {
      id: productId,
      owner: this.props.auth.user.id,
      unique_id: this.props.auth.user.unique_id, //company
      type,
      name,
      details: details.replaceAll('\n', ' '),
      status: config.STATUS.PRODUCTS.AVAILABLE,
      json_data: {
        img,
        category,
        brand: brand !== "especificar marca" ? brand : new_brand,
        state,
        points,
        coins
      },
    }

    if(editing){
      if(data.json_data?.category) delete data.json_data.category
      if(data.status) delete data.status
    }

    await this.props.OnSaveOrUpdate(data);
    console.log('AFTER SAVE');
    const newProduct = this.props.product;
    if (newProduct.error) { 
      this.setState({ submitting: false });
      notify(this.t(newProduct.error.message));
    } else {
      if(this.state.fromRequest){
        if(this.state.requester){
          await this.sendMailToRequester(data, newProduct.item)
        }
        console.log("product:::fromRequest", config.ROUTES.PRODUCTS.DETAIL, this.props.product.item.id, config.ROUTES.PRODUCTS.DETAIL.replace(":id", this.props.product.item.id))
        let notificationData = {  
          type:config.MESSAGES.TYPE.NOTIFICATION,
          source: this.props.auth.user.id,
          target: this.state.requesterId,
          related_to: this.props.product.id,
          parent: "" ,
          subject: this.props.product.type,
          body: `¡Tu pedido de ${this.state.categoryFromRequest} recibió una publicación!`,
          json_data: {
            product: this.props.product,
            redirect_notification_route: config.ROUTES.PRODUCTS.DETAIL.replace(":id", this.props.product.item.id),
          },
        }
        await this.props.onSaveNotification(notificationData);
        console.log('AFTER SAVE NOTIFICATION');
        const notification = this.props.message;
        if (notification.error) {
          notify(this.t(notification.error.message));
        } else {
          console.log("NOTIFICATION", notification.item)
          console.log("NOTIFICATION", notification.item.target.email)

          history.push({
          pathname: success_route.replace(':id', this.props.product.item.id),
          state: {
              title: editing ? editing_title : route_title,
              headline: this.t("Podés verlo en tus ofertas"),
              button:{
                text: "Continuar",
                route: config.ROUTES.USER.INVENTORY,
              },
            }
          });

        }
      } else {
        if(!editing){
          await this.sendMail(data)
        }
        history.push({
          pathname: success_route.replace(':id', this.props.product.item.id),
          state: {
            title: editing ? editing_title : route_title,
            headline: this.t("Podés verlo en tus ofertas"),
            button:{
              text: "Continuar",
              route: config.ROUTES.USER.INVENTORY,
            },
          }
        });
      }
      
    }
  }

  render() {
    const { editing, type, productImg } = this.state

    let title ="";
    let success_route =""
    let route_title =""
    let editing_title =""

    switch (type){
      case "products":
         title= this.t("Confirmación del Producto")
         success_route = config.ROUTES.PRODUCTS.SUCCESS 
         route_title = this.t("Agregaste un nuevo producto")
         editing_title = this.t("Editaste tu producto")
        break;
  
      case "services":
        title= this.t("Confirmación del Servicio")
        success_route = config.ROUTES.SERVICES.SUCCESS 
        route_title = this.t("Agregaste un nuevo servicio")
        editing_title = this.t("Editaste tu servicio")
        break; 
      
      case "favors":
        title= this.t("Confirmación del Favor")
        success_route = config.ROUTES.FAVORS.SUCCESS 
        route_title = this.t("Agregaste un nuevo favor")
        editing_title = this.t("Editaste tu favor")
        break; 
      default:
        break; 
    }

    //title= this.t("Confirmación del Producto")
    const { name, details, brand, state, coins, points, new_brand } = this.state.productItems
    return (
      <LayoutResponsiveWSmall
        main={{ className: ""}}
        header={{ 
          className: "lg:hidden",
          left: { icon: 'arrow_left', action: this.goBack },
        }}
        container={{ className: "px-0"}}
        showGoBackButton={true}
        onGoBackClick={this.goBack}
        /* loading={this.props.locations.loading} */
      >
        <ToastContainer/>
        <section className="bg-primary text-primary-content text-center px-4 pb-4 lg:mt-5">
          {/*<Icon className="h-16 w-16 mx-auto mb-1" name="pin" />*/}
          <h1>{title}</h1>
        </section>
        <section className=" mb-10 lg:mb-0">
          {/*<div className="card white rounded-none p-3 mb-3 mt-4">
            <div className="form-control">
              <label className="cursor-pointer label">
                <span className="label-text">{this.t("Siempre disponible")}</span> 
                <div>
                  <input type="checkbox" checked="checked" className="toggle toggle-primary"/> 
                  <span className="toggle-mark"></span>
                </div>
              </label>
            </div>
          </div>

          <p className="p-3">{this.t("Dias")}</p>
          <div className="card white rounded-none p-5 mb-3">
            <div className="grid grid-cols-7">
            <button className="btn btn-primary btn-outline btn-circle btn-xs">
              L
            </button>
            <button className="btn btn-primary btn-outline btn-circle btn-xs">
              M
            </button>
            <button className="btn btn-primary btn-outline btn-circle btn-xs">
              M
            </button>
            <button className="btn btn-primary btn-outline btn-circle btn-xs">
              J
            </button>
            <button className="btn btn-primary btn-outline btn-circle btn-xs">
              V
            </button>
            <button className="btn btn-primary btn-outline btn-circle btn-xs">
              S
            </button>
            <button className="btn btn-primary btn-outline btn-circle btn-xs">
              D
            </button>
            </div>
          </div>*/}

          {/* <div className="card white rounded-none p-3 mb-3 mt-10">
           <div className="flex justify-between">
             <p> {this.t("Personalizado")}</p>
             <Icon className="h-6 w-6 ml-auto text-base-300" name="cheveron_right" />
           </div>
          </div> */}

          <section className="max-w-md mx-auto"> 
            {
              productImg && <div className="bg-white shadow">
                <div className="container p-4 mt-1">
                  {/* relacion de aspecto en imagen, puede ser aspect-square */}
                  <img className='object-cover' src={productImg} alt="" width="100%" />
                </div>
              </div>
            }

            <section className="container-fluid p-4">
              <div className="flex items-center justify-between">
                <h1 className="">{name}</h1>
              </div>
              {brand   && <p>Marca: {brand !== "especificar marca" ? capitalize(brand) : capitalize(new_brand)}</p>}
              {state   && <p>Estado: {capitalize(state)}</p>}
              {details && <p>Detalles: {capitalize(details)}</p>}
              <div className="flex justify-between items-center">
                <div className="flex items-center my-4">
                  <small className="text-gray-400">{this.t("Ganás Puntos Shary")}</small><IconCoins iconClassName="h-6 w-auto" className="ml-2 mr-8 text-lg" coins={coins}/>
                  <small className="text-gray-400">{this.t("Ganás Puntos de impacto")}</small><IconPoints iconClassName="h-6 w-auto text-primary" className="ml-2 text-lg" points={points}/>
                </div>
              </div>
            </section>
          </section>


          <div className="p-3 lg:w-1/2 lg:items-center lg:mx-auto">
            <Button
              onClick={() => this.setState({ submitting: true }, () => { this.saveProduct (success_route, route_title, editing_title) })}
              className="btn-primary btn-block"
              title={editing === true ? this.t("Guardar cambios"): this.t("Confirmar")}
              disabled={this.state.submitting}
            />

          </div>
        </section>
      
      </LayoutResponsiveWSmall>      
    ) 
  }
}

const mapStateToProps = state => {
  return {
    auth: state.users.auth,    
    product: state.products.current,
    message: state.messages.current,
    internals: state.internals,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    OnSaveOrUpdate: (data) => dispatch(productsAction.saveOrUpdate(data)),
    onSaveNotification: (params) => dispatch(messagesActions.saveOrUpdate(params)),
    onSendMail: (params) => dispatch(internalsActions.sendMail(params)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(Step3));
