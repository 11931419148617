import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import QRCode from 'qrcode.react';
import Swal from 'sweetalert2';

import { ToastContainer, notify } from '../../libraries/notifications';
import { history } from '../../routes';
import config from '../../config';
import { getRewardFromLevel, getObjectWithJsonDataToFormValues, validateBenefitsTransactionsArray, validateBenefitRules, getDateFirstPart, daysOfWeekText, daysOfWeekToString } from '../../libraries/utils';

import Button from '../../components/commons/Button';

import transactionsAction from "../../context/transactions/actions";
import internalsActions from '../../context/internals/actions';
import usersActions from '../../context/users/actions';
import benefitsActions from '../../context/benefits/actions';
import settingActions from "../../context/settings/actions";
import businessesActions from '../../context/businesses/actions';


import MultiLineTextDisplay from '../../components/commons/MultiLineTextDisplay';
import LayoutResponsiveWSmall from '../../components/layout/LayoutResponsiveWSmall';

class Prize extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      loading: false,
      unavailabilityReasons: [],
    }
  }
  
  componentDidMount() {
    const params = this.props.match.params;
    console.log('BENEFIT PAGE', params );
    
    if (params.id){
      this.getData(params.id)
      const state = this.props.location.state;
      console.log("____level", this.props.auth.user.rewards.level)
      if(state?.fromPath){
        this.setState({fromPath: state.fromPath, userLevel: this.props.auth.user.rewards.level})
      }
    } 
  }


  goBack = () => {
    let back = this.state.fromPath ? this.state.fromPath : config.ROUTES.HOME
    let fromPath = this.state.benefit?.type === config.TYPES.PRIZE ? config.ROUTES.MAIN.PRIZES : config.ROUTES.MAIN.BENEFITS
    history.push({pathname: back, state: {from: fromPath}});  
  }

  getData = async (id) => {
    this.setState({loading: true})
    await this.getBenefit(id);
    if (this.state.benefit.type === config.TYPES.BENEFIT){
      history.push(history.location.pathname.replace('prizes', 'benefits'))
    }
    await this.getTransactions()
    this.setState({loading: false})
  }

  getBenefitsSamePack = async (pack) => {
    await this.props.onGetBenefits({pack});
    const { benefits } = this.props;
    if (benefits.error) {
      notify(this.t(benefits.error.message));
      return []
    } else {
      return benefits.items.map(b => b.id)
    }
  }

  checkTransactions = async (rules) => {
    let params = {status:[config.TRANSACTIONS.BENEFIT.STATUS.CONSUMED, config.TRANSACTIONS.BENEFIT.STATUS.RESERVED], target:this.state.benefit.id, type:config.TRANSACTIONS.BENEFIT.TYPE}
    if(this.state.benefit.type === config.TYPES.PRIZE && this.state.benefit.pack){
      params.status = [config.TRANSACTIONS.BENEFIT.STATUS.CONSUMED, config.TRANSACTIONS.BENEFIT.STATUS.RESERVED]
      params.target = await this.getBenefitsSamePack(this.state.benefit.pack)
      console.log("__getTransaction::", params)
    }
    console.log("__getTransaction::", params)
    await this.props.onGetTransactions(params)
    let {transactions} = this.props
    if (transactions.error){
      notify(this.t(transactions.error.message));
      return [transactions.error.message]
    } else {
      let historial = [...transactions.items]
      let user_uses = transactions.items.filter(b => b.target === this.state.benefit.id).length
      
      if(this.state.benefit.type === config.TYPES.PRIZE){
        user_uses = transactions.items.filter(b => b.target === this.state.benefit.id).filter(t => t.status === config.TRANSACTIONS.BENEFIT.STATUS.CONSUMED || t.status === config.TRANSACTIONS.BENEFIT.STATUS.RESERVED)
      }
      this.setState({historial, user_uses})

      if(transactions.items.length){
        let all_transactions = [...transactions.items]
        let my_transactions = []
        //if(this.state.benefit.pack){          
        //  my_transactions = transactions.items.filter(t => t.json_data.benefit.pack === this.state.benefit.pack)
        //} else {
        //}
        my_transactions = transactions.items.filter(t => t.created_by === this.props.auth.user.id)
        
        //"ya tiene un QR generado para otro premio del mismo grupo, deberá cancelarlo antes de poder sacar uno nuevo"
        ///"ya canjeo un premio dentro de este mismo grupo, no puede canjar un nuevo premio"

        if(this.state.benefit.type === config.TYPES.PRIZE){
          let generated_transactions = transactions.items.filter(t => t.status === config.TRANSACTIONS.BENEFIT.STATUS.RESERVED)
          let gtv = validateBenefitsTransactionsArray(generated_transactions, my_transactions.filter(t => t.status === config.TRANSACTIONS.BENEFIT.STATUS.RESERVED), rules, this.state.benefit.pack, this.state.benefit.id)
          if(gtv.includes("Ya agotaste los usos disponibles de este premio o de este grupo de premios") || gtv.includes("El usuario ya agotó los usos disponibles")){
            gtv = ["Ya tiene un QR generado para otro premio del mismo grupo, deberá cancelarlo antes de poder sacar uno nuevo"]
          } else {
            gtv = []
          }
          let consumed_transactions = transactions.items.filter(t => t.status === config.TRANSACTIONS.BENEFIT.STATUS.CONSUMED)
          let ctv = validateBenefitsTransactionsArray(consumed_transactions, my_transactions.filter(t => t.status === config.TRANSACTIONS.BENEFIT.STATUS.CONSUMED), rules, this.state.benefit.pack, this.state.benefit.id)
          if(ctv.includes("Ya agotaste los usos disponibles de este premio o de este grupo de premios")){
            ctv = ["Ya canjeo un premio dentro de este mismo grupo, no puede canjear un nuevo premio"]
          }

          return [...ctv, ...gtv]
        }

        return validateBenefitsTransactionsArray(all_transactions, my_transactions, rules, this.state.benefit.pack)
      }

      return []
    }
  }
  getSetting = async () => {
    const params = {
      "type": "sharyco_settings",
      "owner": this.props.user.unique_id,
      "code": "prize_by_company"
    }
    await this.props.onGetSetting(params);
    console.log(this.props, 'props---', this.state.benefit.owner);
    
    const { settings } = this.props;
    console.log(settings, 'settings---', this.state.benefit.owner);
    const settingsPrizeID = settings?.items.find(setting => setting.owner === this.state.benefit.owner).value
    console.log(settingsPrizeID, 'settingsPrizeID---');
    
    this.setState({settingsPrizeID})

  }

  checkBenefit = async (benefit) => {
    console.log("_______________checkTransactions")
    if(!config.VALIDATE_BENEFTIS){
      this.setState({benefitAvailable: true})
      return
    }
    await this.getSetting()
    let reasons = []
    // console.log(this.state.settingsPrizeID, 'id----------', this.state.benefit.id);
    
    let transactionCheckArray = await this.checkTransactions(benefit.json_data.rules)
    let rulesCheckArray = validateBenefitRules(benefit)
    reasons = [...transactionCheckArray, ...rulesCheckArray]

    //otros check
    if(benefit.type === config.TYPES.PRIZE ){
      if(parseInt(benefit.json_data?.required_level) > parseInt(this.props.auth.user.rewards.level)){
        reasons.push(`No tiene nivel suficiente. Nivel minimo requerido: ${benefit.json_data?.required_level}`)
      }
    } else {
      if(parseInt(this.props.auth.user.rewards.level) === 0){
        reasons.push(`No tiene nivel suficiente. Nivel minimo requerido: 1`)
      }
    }

    if(!benefit.enabled){
      console.log("bncheck", "_______________benefit no enabled check")
      reasons.push(`premio inactivo`)
    }
    
    if(!reasons.length){
      this.setState({benefitAvailable: true})
    } else {
      this.setState({benefitAvailable: false, unavailabilityReasons: reasons})
    }
  }

  getBenefitUser = async (id) => {
    await this.props.onGetUser({ id });
    const { user } = this.props
    if (user.error) {
      notify(this.t(user.error.message));
    } else {
      const userData = getObjectWithJsonDataToFormValues(
        user.item,
        ['id', 'username', 'first_name', 'last_name', 'profile_image', 'email']
      );
      this.setState({ benefitUser: userData})
      console.log("benefitUser", userData)
    }
  }

  getBenefitBusiness = async (id) => {
    await this.props.onGetBusiness(id);
    const { business } = this.props;
    if (business.error) {
      notify(this.t(business.error.message));
    } else {
      if(business.item){
        const benefitBusiness = getObjectWithJsonDataToFormValues(
          business.item,
          ['id', 'name', 'image', 'url', 'address', 'website']
        );
        this.setState({ benefitBusiness });        
      }
      //await this.getInitiativeUser(business.item.owner)
    }    
  }  

  getBenefit = async (id) => {
    await this.props.onGetBenefit(id);
    const { benefit } = this.props;
    if (benefit.error) {
      notify(this.t(benefit.error.message));
    } else {
      const user_current_level_benefit = getRewardFromLevel(this.state.userLevel, benefit.item.json_data)

      this.setState({ benefit: benefit.item, prize: benefit.item.type === config.TYPES.PRIZE, user_current_level_benefit, group_uses: benefit.item.json_data.rules.group_uses });
      await this.checkBenefit(benefit.item)

      if(benefit.item.json_data.business && benefit.item?.type !== config.TYPES.PRIZE){
        await this.getBenefitBusiness(benefit.item.json_data.business.id)
      }
      console.log("benefit",benefit)
    }
  }

  getTransactions = async () => {
    let params = {status:config.TRANSACTIONS.BENEFIT.STATUS.RESERVED, target:this.state.benefit.id, created_by: this.props.auth.user.id, type:config.TRANSACTIONS.BENEFIT.TYPE}
    console.log("getTransaction::", params)

    await this.props.onGetTransactions(params)
    const {transactions} = this.props
    console.log(transactions, '------TRANSACTIONSSS')
    if (transactions.error){
      notify(this.t(transactions.error.message));
    } else {
      if(transactions.items.length){
        this.setState({transaction: transactions.items[0]})
      } else {
        this.setState({transaction: null})
      }
    }
  }

  checkCode = async (code) => {
    //const params = {"json_data":[{"field":"code","value":code}]}
    //await this.props.onGetTransactions(params)
    //let {transactions} = this.props
    //if(transactions.items.length === 0) return true
    //return false
    return true
  }

  saveTransaction = async () => {
    this.setState({loading: true})
    let code = parseInt(Math.random()*10**9).toString().padStart(9,"0")
    //FIXME: chequear por codigo duplicado (poco probable)

    if(!this.checkCode(code)){
      code = parseInt(Math.random()*10**9).toString().padStart(9,"0")
    }
    
    const userData = getObjectWithJsonDataToFormValues(
        this.props.auth.user,
        ['id', 'username', 'first_name', 'last_name', 'profile_image', 'email']
      );

    let data = {
      target:this.state.benefit.id,
      type: config.TRANSACTIONS.BENEFIT.TYPE,
      sub_type: config.TRANSACTIONS.BENEFIT.SUB_TYPE.PRIZE,
      // status:config.TRANSACTIONS.BENEFIT.STATUS.GENERATED,//generated/consumed
      status:config.TRANSACTIONS.BENEFIT.STATUS.RESERVED,
      source: this.state.benefit?.type === config.TYPES.PRIZE ? this.props.auth.user.unique_id : this.state.benefit.json_data?.business?.id,//business id //si es prize company_id, id del owner del beneficio ese
      json_data: {
        user:userData,
        benefit:this.state.benefit,
        //busines,
        code,
        owner:userData,
      },
      owner: this.props.auth.user.unique_id,
    }
    console.log("DATA", data)
    
    await this.props.onSaveTransaction(data);
    console.log('AFTER SAVING TRANSACTION');
    const {transaction} = this.props
    if (transaction.error) {
      notify(this.t(transaction.error.message));
    } else {
      await this.sendMailUser()
      this.setState({loading: false})
      const from = config.ROUTES.PRIZES.DETAIL.replace(':id', this.state.benefit.id );
      const state = {
        title: "QR generado",
        headline: "Presentalo a la hora de usar el premio.",
        button:{
          text: "Continuar",
          route: {
            pathname: from,
            state: {from: this.state.benefit?.type === config.TYPES.PRIZE ? from : config.ROUTES.MAIN.BENEFITS}
          },
        },
      }
      history.push({
        pathname: config.ROUTES.VOLUNTEERING.SUCCESS.replace(':id', this.state.benefit.id),
        state
      })
    }

  }
  saveVoucher = async () => {
    this.setState({ loading: true })
    const userData = getObjectWithJsonDataToFormValues(
      this.props.auth.user,
      ['id', 'username', 'first_name', 'last_name', 'profile_image', 'email']
    );
    let today = new Date()

    let data = {
      // target:this.state.benefit.id,
      type: "credits",
      sub_type: "credits",
      status: "paid",
      // date: today.toISOString(),
      // status:config.TRANSACTIONS.BENEFIT.STATUS.CONSUMED,
      source: this.props.auth.user.unique_id,
      target: this.props.auth.user.id,
      untaxed_amount: this.state.benefit?.json_data.voucher_values, //prize.quantity,
      total: this.state.benefit?.json_data.voucher_values, //prize.quantity,
      reference: this.state.benefit?.id,//challenge_obj.id,
      owner: this.props.auth.user.unique_id,
      json_data: {
        reason: `Premio de Desafío: ${this.state.benefit?.name}`,
        winner: this.props.auth.user.id,
      }
      // json_data: {
      //   user:userData,
      //   benefit:this.state.benefit,
      //   owner:userData,
      // },
    }
    let dataConsumed = {
      target: this.state.benefit.id,
      type: config.TRANSACTIONS.BENEFIT.TYPE,
      date: today.toISOString(),
      sub_type: config.TRANSACTIONS.BENEFIT.SUB_TYPE.PRIZE,
      status: config.TRANSACTIONS.BENEFIT.STATUS.CONSUMED,
      source: this.state.benefit?.type === config.TYPES.PRIZE ? this.props.auth.user.unique_id : this.state.benefit.json_data?.business?.id,//business id //si es prize company_id, id del owner del beneficio ese
      owner: this.props.auth.user.unique_id,
      json_data: {
        user: userData,
        benefit: this.state.benefit,
        owner: userData,
      },
    }
    console.log( data, dataConsumed)

    await this.props.onSaveTransaction(data);
    const { transaction } = this.props
    console.log(transaction, 'AFTER SAVING')
    // now we have to save the new transaction to show the panel data, only to know when the transaction is consumed
    await this.props.onSaveTransaction(dataConsumed)
    console.log(transaction, 'AFTER SAVING THE SECOND TRANSACTION')
    if (transaction.error) {
      notify(this.t(transaction.error.message));
  } else {
    this.setState({loading: false})
    const from = config.ROUTES.PRIZES.DETAIL.replace(':id', this.state.benefit.id );
    const state = {
      title: "Voucher Canjeado",
      headline: "Disfruta de tus créditos.",
      button:{
        text: "Continuar",
        route: {
          pathname: from,
          state: {from: this.state.benefit?.type === config.TYPES.PRIZE ? from : config.ROUTES.MAIN.BENEFITS}
        },
      },
    }
    history.push({
        pathname: config.ROUTES.VOLUNTEERING.SUCCESS.replace(':id', this.state.benefit.id),
        state
      })
  }
  }

  sendMailUser = async () => {
    // let subject = this.state.benefit?.type === config.TYPES.PRIZE ? "Descargaste un premio" : "Descargaste un descuento"
    let mailData = {
      template: this.state.benefit?.type === config.TYPES.PRIZE ? "benefit_prize_created" : "benefit_created",
      locale: "es",
      to:this.props.auth.user.email,
      first_name: this.props.auth.user.first_name,
      last_name:  this.props.auth.user.last_name,
      params: {
          // subject,
          benefit_name: this.state.benefit.name,
          benefit_id: this.state.benefit.id,
          user_name: `${this.props.auth.user.first_name} ${this.props.auth.user.last_name}`,
      }
    }
    
    await this.props.onSendMail(mailData);
    const internals = this.props.internals;
    if (internals.error) {
      notify(this.t(internals.error.message));
    }
  }

  getBenefitValuesArray = (benefit) => {
    if(!benefit?.json_data?.values) return []

    const percentage = benefit.json_data?.percentage
    return Object.keys(benefit.json_data?.values).map(k => {
      return {level: parseInt(k)+1, value: `${benefit.json_data?.values[k].value}${percentage ? "%" : ""}`}  
    }) 
  }

  cancelConsumption = async () => {
    const swalWithStyle = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-primary mr-2',
        cancelButton: 'btn btn-ghost',
      },
      buttonsStyling: false
    })
    const result = await swalWithStyle.fire({
        title: this.t("¿Are you sure?"),
        text: "",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.t("Yes"),
        cancelButtonText: this.t("Cancel"),
      })
      .then((result) => {
        return result
      });
    console.log('ANSWER', result);
    
    if (result && result.isConfirmed) { 
      let data = {
        id: this.state.transaction.id,
        status:"canceled",//config.TRANSACTIONS.BENEFIT.STATUS.CONSUMED,
      }
      await this.props.onSaveTransaction(data);
      const {transaction} = this.props
      if (transaction.error) {
        notify(this.t(transaction.error.message));
      }
      this.getData(this.state.benefit.id)
    } 
    //else if (result && result.isDismissed) {
    //  Swal.fire('', this.t("Se canceló la participacion"), 'error');
    //}
    
  }

  render() {
    const { benefit, benefitBusiness, prize } = this.state;
    const benefitValues = this.getBenefitValuesArray(benefit)
    const group_uses = parseInt(benefit?.json_data?.rules?.group_uses)
    const benefitOrPrize = prize ? "Premio" : "Beneficio"
    const isVoucher = benefit?.json_data?.is_voucher
    console.log( 'voucher', benefit)
    console.log('business', benefitBusiness);
    

    return (
      <LayoutResponsiveWSmall
        main={{ className: ""}}
        header={{ 
          className: "bg-transparent fixed text-primary",
          left: { icon: 'arrow_left', action: this.goBack },
          /* right: { icon: 'heart'} */
        }}
        container={{ className: "px-0"}}
        loading={this.state.loading}
        showGoBackButton={true}
        onGoBackClick={this.goBack}
      > 
        <ToastContainer/>
        {benefit && (
          <>
            <div className="bg-white shadow-lg mt-16 lg:mt-10">
              <div className="max-w-md mx-auto">
                <div className="max-h-72 overflow-hidden flex items-center justify-center">
                  <img src={benefit && benefit.json_data.picture} className="max-h-26 mx-auto" alt="" />
                </div>
                <div className="container p-3">  
                  <h1 className="">{benefit.name}</h1>
                  <div className="flex items-center gap-3 mt-2">
                    {prize && "Premio de tu empresa"}
                    <div className="flex items-center">
                      {benefitBusiness && 
                        <div className="h-8 w-8 p-1 rounded-full flex items-center justify-center mr-2 border border-gray-200">
                          <img className="" src={benefitBusiness.image} alt="" />
                        </div>
                      }
                      {benefitBusiness && `${benefitOrPrize} de ${benefitBusiness.name}`}
                    </div>
                    {this.state.user_current_level_benefit && 
                      <div className="badge badge-primary inline-block">{this.state.user_current_level_benefit}%</div>
                    }
                  </div>
                 
                  {!(this.state.transaction) && !isVoucher && <div className="mt-2">
                    <Button
                      disabled = {this.state.loading || this.state.transaction || !this.state.benefitAvailable}
                      className="btn btn-primary btn-block"
                      title={this.t("Generar QR")}
                      onClick = {this.saveTransaction}
                    />
                  </div>}
                  {
                    isVoucher && <div className="mt-2">
                    <Button
                      disabled = {this.state.loading || this.state.transaction || !this.state.benefitAvailable}
                      className="btn btn-primary btn-block"
                      title={this.t("Canjear Voucher")}
                      onClick = {this.saveVoucher}
                    />
                  </div>
                  }

                  {this.state.transaction && <section className="container-fluid p-4">
                    {/* QR */}
                    <div className="p-4 text-center border-b border-gray-200 mb-5">
                      <h3>{this.t(`Presentá este código para poder solicitar el ${benefitOrPrize.toLowerCase()}`)}</h3>
                      <QRCode 
                        className="p-4 mx-auto"
                        value={this.state.transaction?.json_data?.code ? this.state.transaction.json_data?.code.toString() : ""}
                        size={178}
                      />
                      <p className="uppercase text-gray-400">{this.t("Código")}</p>
                      <h4>{this.state.transaction?.json_data?.code ? this.state.transaction.json_data?.code : ""}</h4>
                      <Button
                        className="btn-error btn-sm mt-4"
                        title={this.t("Cancelar QR")}
                        onClick = {this.cancelConsumption}
                      />
                    </div>
                  </section>}
                  
                  {!this.state.benefitAvailable && !this.state.transaction && 
                    <p className="text-xs mt-3 text-center text-gray-400">
                      {benefitOrPrize} no disponible
                      {/*(this.state.unavailabilityReasons.length !== 0) && `: ${this.state.unavailabilityReasons.join("/")}` */}
                      {(this.state.unavailabilityReasons && (this.state.unavailabilityReasons.length !== 0)) && `: ${this.state.unavailabilityReasons[0]}` }
                    </p>
                  }                    
                  {
                    (group_uses !== 0 && group_uses !== "0" && !this.state.unavailabilityReasons.includes("premio inactivo")) &&
                    <p className="text-xs mt-3 text-center text-gray-400">
                      {group_uses - this.state.user_uses?.length} disponibles
                    </p>
                  }
                </div>
              </div>
            </div>
            <section className="p-4 max-w-md mx-auto mb-10 lg:mb-0">
              <h4 className="mb-1" >{this.t("Descripción")}</h4>
              <MultiLineTextDisplay className="mb-5" text={benefit.detail}/>
              
              {benefit.json_data && benefit.json_data.instructions && (
                <>
                  <h4 className="mb-1" >{this.t(`Dinámica para usar el ${benefitOrPrize}`)}</h4>
                  <MultiLineTextDisplay className="mb-5" text={benefit.json_data.instructions}/>                  
                </>
              )}               
              {benefit.json_data && benefit.json_data.extra_info && (
                <>
                  <h4 className="mb-1">{this.t("Aclaraciones")}</h4>
                  <MultiLineTextDisplay className="mb-5" text={benefit.json_data.extra_info}/>                  
                </>
              )}
              {benefitValues.length ? (
                <>
                  <h4>{this.t("Descuentos por nivel")}</h4>
                  <div className="grid gap-2 grid-cols-3 mt-2 mb-4">
                    {benefitValues.map((b, index) => {
                      return (
                        <div key={`lvl${index}`} className={"card white p-2 text-center " + (this.state.userLevel === index + 1 ? "border border-primary" : "")}>
                          <p className="mb-1">{this.t("Nivel")} {b.level}</p> 
                          <span className="badge badge-primary">{b.value}</span>
                        </div>
                      )
                    })}
                  </div>
                </>
              ) : null}
              {benefit.json_data && benefit.json_data.url && (
                <>
                  <h4 className="mb-1">{this.t("Sitio Web")}</h4>
                  <MultiLineTextDisplay className="mb-5" text={benefit.json_data?.url}/>                  
                </>
              )}
              {benefit.json_data && benefit.json_data.rules && (
                <>
                  <h4 className="mb-1">{this.t("Período de validez")}</h4>
                  {(benefit.json_data?.rules?.start_date && benefit.json_data?.rules?.end_date) && <p>Desde el {getDateFirstPart(benefit.json_data.rules.start_date)} hasta el {getDateFirstPart(benefit.json_data.rules.end_date)}</p>}
                  {(benefit.json_data?.rules?.start_date && !benefit.json_data?.rules?.end_date) && <p>Desde el {getDateFirstPart(benefit.json_data.rules.start_date)}</p>}
                  {(!benefit.json_data?.rules?.start_date && benefit.json_data?.rules?.end_date) && <p>Hasta el {getDateFirstPart(benefit.json_data.rules.end_date)}</p>}
                  {(!benefit.json_data?.rules?.start_date && !benefit.json_data?.rules?.end_date) && <p>Disponible cualquier fecha</p>}
                  {benefit.json_data?.rules?.days_of_week ? <p>{daysOfWeekToString(benefit.json_data.rules.days_of_week)}</p> : <p>Disponible todos los días de la semana</p>}                                
                </>
              )}
              {!prize && benefitBusiness && benefitBusiness.url && (
                <>
                  <h4 className="mt-4">{this.t("Más información")}</h4>
                  <p className="mb-4"><a className="link text-primary" target="_blank" href={benefitBusiness.url}> {benefitBusiness.url}</a></p>
                </>
              )}
            </section>
          </>
        )}
      </LayoutResponsiveWSmall>
    ) 
  }
}


const mapStateToProps = state => {
  return {
    auth: state.users.auth, 
    benefit: state.benefits.current,
    benefits: state.benefits.list,
    message: state.messages.current,
    internals: state.internals,
    user: state.users.current,
    transactions: state.transactions.list,
    transaction: state.transactions.current,
    business: state.businesses.current,
    settings: state.settings.list,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSaveTransaction: (params) => dispatch(transactionsAction.saveOrUpdate(params)),
    onGetTransactions: (params) => dispatch(transactionsAction.getAll(params)),
    onSendMail: (params) => dispatch(internalsActions.sendMail(params)),
    onGetUser: (params) => dispatch(usersActions.get(params)),
    onGetBenefit: (params) => dispatch(benefitsActions.get(params)),
    onGetBenefits: (params) => dispatch(benefitsActions.getAll(params)),
    onGetBusiness: (params) => dispatch(businessesActions.get(params)),
    onGetSetting:(params)=>dispatch(settingActions.getAll(params))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(Prize));



