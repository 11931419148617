import React from 'react';
import { useTranslation } from 'react-i18next';
import { history } from '../../routes';
import Button from '../commons/Button';
import IconCredits from './IconCredits';
import { getObjectWithJsonDataToFormValues, getRewardFromLevel } from '../../libraries/utils';
import BusinessNameImage from './BusinessNameImage';

export default function BenefitListCard({ route, benefit, userLevel, ...rest }) {
  const { t, i18n } = useTranslation();
  const { url, name, picture, values, credits} = getObjectWithJsonDataToFormValues(
    benefit,
    ['id', 'name', 'picture', 'values', 'credits']
  );

  const user_current_level_benefit = getRewardFromLevel(userLevel, {values})
  
  return (
    <div className={"card white justify-between overflow-hidden " + (rest.className ? rest.className : "")}>
      <div>
        <div className="h-32 overflow-hidden flex justify-center items-center">
          
            {(user_current_level_benefit && 
              <div className="badge badge-primary absolute top-2 right-2">
                <p className="font-black text-lg">{user_current_level_benefit}%</p>
              </div>
            )}
          
          <img className="h-full" alt="" src={`${picture}?t=${Math.random(5)}`} />
        </div>
        <div className="p-2 w-full">
          <h5>{name}</h5>
          {rest.business && <BusinessNameImage business={rest.business} />}
        </div>
        <div className="px-2 pb-2 flex">
          <IconCredits iconClassName="h-4" negative={true} credits={credits} />
        </div>
      </div>
      <div className="px-2 pb-2">
        <Button
          className="btn-primary btn-sm btn-block"
          title={t("Ver más")}
          onClick={() => history.push(route)}
        />
      </div>
    </div>
  )
}