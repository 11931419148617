import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import config from '../../config';
/* import { history } from '../../routes';
import { getOwner } from '../../libraries/utils'; */

import ChallegesCard from '../../components/customs/ChallengesCard';
import Loader from '../../components/commons/Loader';
import Icon from '../../libraries/icons';


import actionChallenges from '../../context/challenges/actions'
import userActions from '../../context/users/actions'

import Instructive from '../../components/commons/Instructive';

import { ToastContainer, notify } from '../../libraries/notifications';
import LayoutWithSidebar from '../../components/layout/LayoutWithSidebar';
import BottomNav from '../../components/commons/BottomNav';
import { getOwner } from '../../libraries/utils';
import LayoutResponsive from '../../components/layout/LayoutResponsive';

class Challenges extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      challenges:[],
      filters: {
        type: null
      },
      categories: [],
    }
  }
  
  async componentDidMount() {
    this.setState({loading: true})
    await this.getCategories();
    await this.mapChallenges()
    this.setState({loading: false})
    // console.log("this.props", this.props)
    // if(this.props.participants?.participants) {
    //   this.setState({ participants: this.props.participants.participants,})
    // }
  }
  // componentDidUpdate(prevProps, prevState) {
  //   if (prevProps.challenges !== this.props.challenges) {
  //     // this.setState({ participants: this.props.participants.participants,})
  //   }
  // }
  
  // TODO:  ahora se debe traer a todos los participantes de todos los challenges
  async getParticipants(id) {
    await this.props.onGetAllParticipants(id)
    // this.setState({ participants: this.props.participants,})
  }

  checkIfJoined = async () => {
    const participants = this.props.participants
    
    const user = this.props.auth.user
    const participant = participants.find(p => p.participant?.id === user.id)
    return participant ? true : false
  }

  getReferentUsers = async (referentID) => {
    await this.props.onGetUsers();
    this.setState({
      referentUsers: [...this.props.users.items],
    });
  }

  mapChallenges = async () => {
    const { challenges } = this.state
    const updatedChallenges = await Promise.all(challenges.map(async challenge => {
      await this.getParticipants(challenge.id)

      const joined = await this.checkIfJoined()
      return {
        ...challenge,
        joined
      }
    }))

    const today = new Date()
    await updatedChallenges.sort((a,b) => {
      if(new Date(a.end_date) < today && new Date(b.end_date) > today) return 1
      if(new Date(a.end_date) > today && new Date(b.end_date) < today) return -1
      if(a.joined && !b.joined) return -1
      if(!a.joined && b.joined) return 1
      return 0
    })

    await this.props.onGetUser({id: this.props.auth.user.id});

    const invitedBy = this.props.user.item?.invited_by;

    const visibleChallenges = updatedChallenges.filter(ch => {
      if(ch.visibility === 'referents') return invitedBy === 'referent';
      else return true;
    })

    this.setState({ challenges: visibleChallenges })
    await this.props.clearCurrent()
  }

  getChallenges = async () => {
    // this.setState({loading: true})
    const { filters } = this.state
    let params = {
      owner: this.props.auth.user.unique_id
    }

    if ((filters.type && filters.type !== "")){
      this.setState({ challenges: [] });
      params = { type: filters.type }
    }

    // await this.props.onGetAllChallenges({...params, enabled: 1});
    await this.props.onGetAllChallenges({...params, enabled: 1})
    const { challenges } = this.props;
    if (challenges.error) {
      notify(this.t(challenges.error.message));
    } else {
      this.setState({ challenges: challenges.items });
    }    
    // this.setState({loading: false})
  }


  getCategories = async (next) => {
    // await this.props.onGetAllCategories({ type: config.TYPES.CATEGORIES.ACTIONS, order_by: 'order_index', order_direction: 'ASC' });
    // const { categories } = this.props;
    // if (categories.error) { 
    //   notify(this.t(categories.error.message));
    // } else {
    //   this.setState({ categories: categories.items, filters: { type: categories.items[0] ? categories.items[0].id : null } });
    //   await this.getActions();
    // }
    const categories = [

    ]
    this.setState({ categories: categories, filters: { type: categories[0] ? categories[0].id : null } });
      await this.getChallenges();
  }

  filterActions = (type) => {
    this.setState({ filters: { type }}, () => this.getChallenges())
  }

  render() {
    const { challenges, filters, categories } = this.state;

    return (
      <LayoutResponsive
        main={{ className: "pb-14"}}
        header={{ 
          logo: true,
          //content: <NavButtonBar options={buttonBarUpper} active={topNav} onClick={this.onNavBarClick} />
        }}
 
        container={{ className: "px-0"}}
      >
      <ToastContainer/>
      <div className="w-full mx-auto px-3 mb-12 lg:mb-0">
        
        {/* <div className="p-4 mb-4 flex flex-row items-start">
          <Icon className="w-8 mt-2 mr-2" name="information_outline" />
          <h4> Desafíos</h4>
        </div> */}
        {/* <div className="scrolling-wrapper mb-4">
          <Icon className="text-gray-300 -left-1 top-16 -mt-1 w-5 h-12 z-10" name="cheveron_left" />
          <Icon className="text-gray-300 -right-1 top-16 -mt-1 w-5 h-12 z-10" name="cheveron_right" />
          {categories.map((category,index) => {
            return(
              <div
                key={index}
                className={"card white py-2 px-4 mb-4 w-22 mr-4 border-2 border-transparent " + (category.id===filters.type ? " border-primary text-primary" : " text-gray-400") + ""}
                onClick={() => this.filterActions(category.id)}
              >
                <p>{this.t(category.name)}</p>
              </div>
            );
          })}
        </div> */}
        <div className=''/>
        <Instructive
          slides={[
            {
              title:"Desafíos",
              body:"Participá en retos sostenibles y ganá premios mientras ayudás al planeta y a tu comunidad.",
              img: "challenge",
            }
          ]}
          instructive={"show_challenges"}
        > 
        {this.state.loading && (<Loader/>)}
        {!this.state.loading && (
          <div className="grid gap-4">
            { !challenges.length && 
              <div className='flex flex-col items-center gap-5 mt-5 text-neutral'>
                <Icon className="h-16 w-16" name="sad" />
                <p className="">{this.t("No hay desafíos disponibles")}</p>
              </div>
            }
            { challenges && challenges.map((challenge) => {
                return (
                  <ChallegesCard 
                    key={challenge.id}
                    data={challenge}
                    route={config.ROUTES.CHALLENGES.INFO.replace(':id', challenge.id)}
                  />
                )
              })
            }
          </div>
        )}


          
        </Instructive>
        
      </div> 
      </LayoutResponsive>  
  
    ) 
  }
}

const mapStateToProps = state => {
  return {
    auth: state.users.auth,
    challenges: state.challenges.list,
    participants: state.challenges.current.participants?.participants,
    user: state.users.current
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onGetAllChallenges: (params) => dispatch(actionChallenges.getAll(params)),
    onGetAllParticipants: (params) => dispatch(actionChallenges.getParticipants(params)),
    clearCurrent: () => dispatch(actionChallenges.clearCurrent()),
    onGetUser: params => dispatch(userActions.get(params)),
    
    // onGetAllCategories: (params) => dispatch(actionCategories.getAll(params)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(Challenges));



